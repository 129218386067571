/**
 * Sustainability Reporting > Allocation
 * Get all data for the main page
 */

 import { getToken } from "../../utils";
 import { BASE_URL } from "../../endpoints";

 interface Params {
   allocation?: string;
   taxYearId?: number;
 }

 const get = async (params: Params = {}) => {
   const suffix = `?allocation_mode=${params.allocation}&tax_year_id=${params.taxYearId}`;

   const token = getToken();
   if (token) {
     try {
       const response = await fetch(
         `${BASE_URL}/api/card_sustainability${suffix}`,
         {
           method: "GET",
           headers: {
             "Content-Type": "application/json",
             Authorization: token,
           },
         }
       );

       if (response.status === 200) {
         const data = await response.json();
         return data;
       } else {
         return null;
       }
     } catch {
       return null; // network error
     }
   } else {
     return null; // No token
   }
 };

 export default get;

