import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

export default async (params: any) => {
  const token = getToken();

  let valid = false;
  let data = {};
  let limit = {};
  let createResult

  if (token) {
    try {
      let response = await fetch(`${BASE_URL}/api/sold_volume_components`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      createResult = await response.json();
      valid = createResult && createResult.success;
      let soldVol = createResult.sold_volume_components;

      data = soldVol;
      if (soldVol.nafta && soldVol.etanol && soldVol.fame && soldVol.hvo_hro) {
        data = {
          ...soldVol,
          nafta: parseFloat(soldVol.nafta),
          etanol: parseFloat(soldVol.etanol),
          fame: parseFloat(soldVol.fame),
          hvo_hro: parseFloat(soldVol.hvo_hro),
        };
      }
      limit = createResult.sold_volume_limit;
    } catch (except) {
      valid = false; // network error
    }
  }

  if (!valid) {
    if (createResult && createResult.errors) {
      return {
        valid: false,
        message: createResult.errors,
      };
    }
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    data,
    limit,
  };
};
