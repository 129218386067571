import { ErrorPayload } from "../../../common/payloads/ErrorActionPayload";
import { Action, handleActions } from "redux-actions";
import fetchFiles from "../../../api/sustainability/reports/getPageData";

// State Interface
export interface PagedataState {
  success: boolean;
  confirmation_status: boolean;
  collective_fulfillment: boolean;
  requirement_states: any;
  sustainable_batches: any[];
  reported_volumes: any;
  joint_fulfillments: any;
  annual_calculations: any;
  pool_status: any;
  available_pool_biotemplates: any[];
}

export interface ReportsState {
  isFetching: boolean;
  data: any;
  error?: any;
}

// Initial state
const initialState = {
  isFetching: false,
  data: {
    success: false,
    confirmation_status: false,
    collective_fulfillment: false,
    requirement_states: {},
    sustainable_batches: [],
    reported_volumes: {},
    joint_fulfillments: {},
    annual_calculations: {},
    pool_status: {},
    available_pool_biotemplates: [],
  },
  error: undefined,
} as ReportsState;

// Actions
const REPORTS_PAGEDATA_REQUEST = "sustainability/reports/PAGEDATA_REQUEST";
const REPORTS_PAGEDATA_ERROR = "sustainability/reports/PAGEDATA_ERROR";
const REPORTS_PAGEDATA_SUCCESS = "sustainability/reports/PAGEDATA_SUCCESS";

// Action creators
function requestFiles() {
  return {
    type: REPORTS_PAGEDATA_REQUEST,
  };
}

function errorFiles(error: any) {
  return {
    type: REPORTS_PAGEDATA_ERROR,
    payload: { error },
  };
}

function receiveFiles(data: any) {
  return {
    type: REPORTS_PAGEDATA_SUCCESS,
    payload: data,
  };
}

export function getPageData(taxYear: any) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.sustainability.reports) {
      dispatch(requestFiles());
      try {
        const data = await fetchFiles({ taxYear });
        dispatch(receiveFiles(data));
      } catch (error) {
        dispatch(errorFiles(error));
      }
    }
    return Promise.resolve;
  };
}

// Reducer Definition
const ReportsReducer = handleActions<ReportsState>(
  {
    [REPORTS_PAGEDATA_REQUEST]: handlePagedataRequest,
    [REPORTS_PAGEDATA_ERROR]: handlePagedataError,
    [REPORTS_PAGEDATA_SUCCESS]: handlePagedataSuccess,
  } as any,
  initialState
);

// Reducer Description
function handlePagedataRequest(state: ReportsState) {
  return {
    ...state,
    isFetching: true,
  };
}

function handlePagedataError(
  state: ReportsState,
  action: Action<ErrorPayload>
) {
  return {
    ...state,
    isFetching: false,
    error: action.payload.error,
  };
}

function handlePagedataSuccess(state: ReportsState, action: Action<any>) {
  return {
    ...state,
    isFetching: false,
    data: action.payload,
  };
}

export default ReportsReducer;
