import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CustomersTable from "./CustomersTable";
import CustomerRequirementView from "../CustomersView/CustomerRequirement";
// import GenerateCustomerReportView from "./CustomerRequirement/GenerateCustomerReportView";

import getAllCustomers from "../../../../api/card/customers/getAllCustomers";

import useModal from "../../../../components/modal/useModal";
import { FormattedMessage } from "react-intl";
import ModalDownloadReport from "./ModalDownloadReport";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(3),
    },
  })
);

interface Props {
  modal_show_download_report: boolean;
  setShowModalDownloadReport: (show: boolean) => void;
  selected_customer: any;
  setSelectedCustomer: (customer: any) => void;
  setSelectedYear: (year: any) => void;
  getCustomerPDFFiles: (id: number, data: any) => any;
  CustomerPDFdata: any;
  taxPeriods: any;
  selectedYear: any;
  clearErrorfromLastcall: any;
}

const CustomersView: React.FC<Props> = ({
  modal_show_download_report,
  setShowModalDownloadReport,
  selected_customer,
  setSelectedCustomer,
  setSelectedYear,
  getCustomerPDFFiles,
  CustomerPDFdata,
  taxPeriods,
  selectedYear,
  clearErrorfromLastcall,
}) => {
  const classes = useStyles();
  // const history = useHistory();
  const match = useRouteMatch();

  const [customerList, setCustomerList] = useState<any[]>([]);
  const { openModalId, toggle } = useModal();

  const fetchAllCustomers = async () => {
    const results = await getAllCustomers();
    if (Array.isArray(results)) {
      setCustomerList(results);
    }
  };

  useEffect(() => {
    fetchAllCustomers();
  }, []);

  const openModal = (id: string, selectedCustomer?: any) => {
    if (selectedCustomer) { setSelectedCustomer(selectedCustomer); }
    toggle(id);
  };

  const closeModal = () => {
    toggle();
  };

  if (match) {
    return (
      <div>
        <Switch>
          <Route exact path={`${match.path}`}>
            <div>
              <Typography variant="h2" className={classes.title}>
                <FormattedMessage id="customersView.index.heading" />
              </Typography>
              <CustomersTable
                customerList={customerList}
                setSelectedCustomer={setSelectedCustomer}
                addCustomer={() => openModal("addCustomer")}
                editCustomer={(customer: any) => {
                  openModal("editCustomer", customer);
                }}
                deleteCustomer={(customer: any) => {
                  openModal("deleteCustomer", customer);
                }}
              />
            </div>
          </Route>
          {selected_customer && (
            <>
              <Route exact path={`${match.path}/:id`}>
                <CustomerRequirementView
                  selected_customer={selected_customer}
                  setShowModalDownloadReport={setShowModalDownloadReport}
                  setSelectedYear={setSelectedYear}
                  taxPeriods={taxPeriods}
                  selectedYear={selectedYear}
                />
              </Route>

            </>
          )}
          <Redirect to={`${match.path}`} />
        </Switch>

        <ModalDownloadReport
          open={modal_show_download_report}
          onClose={() => setShowModalDownloadReport(false)}
          selected_customer={selected_customer}
          taxPeriods={taxPeriods}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          getCustomerPDFFiles={getCustomerPDFFiles}
          CustomerPDFdata={CustomerPDFdata}
          clearErrorfromLastcall={clearErrorfromLastcall}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default CustomersView;
