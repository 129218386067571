/**
 * Download sustainability report
 * For: sustainability reporting > reports > download report button
 */

import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

export type Tfiletype =
  | "report_json"
  | "report_excel"
  | "subreport_excel"
  | "audit_excel";

export type Tmandate_type =
    | ""
    | "roaduse"
    | "offroad"
    | "marine";

interface IGetDownloadReport {
  type: Tfiletype;
  mandate_type: Tmandate_type;
  taxYear?: number;
}

const fileurlmappings = {
  report_json: "export_json_report",
  report_excel: "export_sustainability_excel_report",
  subreport_excel: "export_sustain_quantities_excel_report",
  audit_excel: "export_audit_excel_report",
};

const mandatemappings = {
  roaduse: "roaduse",
  offroad: "offroad",
  marine: "marine"
};
const getDownloadReport = async ({ type, mandate_type = '', taxYear }: IGetDownloadReport) => {
  const token = getToken();
  const urlSuffix = fileurlmappings[type];
  const mandate = mandatemappings[mandate_type]
  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/sustainability_reports/${urlSuffix}?tax_year=${taxYear}&mandate=${mandate}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.blob();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token
  }
};

export default getDownloadReport;
