import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as moment from "moment";
import Typography from "@material-ui/core/Typography";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ButtonRounded from "../../../../components/ButtonRounded";
import ToggleButton from "../../../../components/ToggleButton";

import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Filters, {
  IFilterParameters,
} from "../../../../components/FilterPanel/Filters";
import filterSetup from "./storage_filter_properties";
import SmartTable from "../../../../components/SmartTable/index";
import KeyMetrics from "./KeyMetrics";
import storageProperties, { aggregateProperties } from "./storage_properties";

import getStorages from "../../../../api/card/storage/get";
import deleteStorage from "../../../../api/card/storage/delete";
import addStorage from "../../../../api/card/storage/create";
import editStorage from "../../../../api/card/storage/edit";

import useModal from "../../../../components/modal/useModal";
import ModalDelete from "../../../../components/modal/ModalDelete";
import { IAppVariables } from "../../../../types/ApiInterfaces";
import ModalAddStorage from "../../../../components/modal/ModalAddCardStorage";
import { FormattedMessage } from "react-intl";

const ExpansionPanel = withStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "&.MuiExpansionPanel-root:before": { backgroundColor: "transparent" },
    "&.Mui-expanded:last-child": {
      marginBottom: theme.spacing(2),
    },
  },
}))(MuiExpansionPanel);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      padding: theme.spacing(3),
      margin: theme.spacing(3, 0),
    },
    h2: {
      [theme.breakpoints.down("md")]: {
        fontSize: "32px",
      },
    },
    title: {
      marginBottom: theme.spacing(3),
    },
    btn: {
      padding: theme.spacing(1, 2),
    },
    iconExpand: {
      borderRadius: "100%",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      transform: "scale(0.9)",
      ".MuiExpansionPanelSummary-expandIcon.Mui-expanded &": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.grey[50],
      },
    },
  })
);

const isInInterval = (val: any, interval: any) => {
  const strValue = val.toString().replace(/,/g, ".").replace(/ /g, "");
  if (Array.isArray(interval)) {
    const absVal = Math.abs(parseFloat(strValue));
    return !(absVal < interval[0]) && !(absVal > interval[1]);
  }
  return true;
};

const isAfter = (str: string, date2: any) => {
  let date1 = moment.default(str, "YYYY-MM-DD");
  return moment.default(date1).diff(date2, "days") >= 0;
};

const isBefore = (str: string, date2: any) => {
  let date1 = moment.default(str, "YYYY-MM-DD");
  return moment.default(date1).diff(date2, "days") <= 0;
};

interface Props {
  isSimulation: boolean;
  app_variables: IAppVariables;
  toggleSimulation: any;
}

const StorageView: React.FC<Props> = ({
  isSimulation,
  toggleSimulation,
}) => {
  const classes = useStyles();
  const [list, setList] = useState<IFilterParameters[]>([]);
  const [filteredList, setFilteredList] = useState<IFilterParameters[]>([]);
  const [filter, setFilter] = useState<IFilterParameters>({});

  const { openModalId, toggle } = useModal();
  const [selectedEntry, setSelectedEntry] = useState<any>(null);

  const openModal = (id: string, selectedEntry?: any) => {
    if (selectedEntry) { setSelectedEntry(selectedEntry); }
    toggle(id);
  };

  const closeModal = () => {
    getStorageList();
    toggle();
  };

  const filterList = (
    key: string,
    value: string | moment.Moment | number | number[]
  ) => {
    let new_filter = { ...filter, [key]: value };
    if (value === null || value === "") { delete new_filter[key]; }
    setFilter(new_filter);
    const filterKeys = Object.keys(new_filter);
    const filteredList = list.filter((item: any) => {
      // validate all filter criteria

      return filterKeys.every(id => {
        if (new_filter[id] === "") { return true; }
        if (
          id === "volume" ||
          id === "equivalent_volume" ||
          id === "reduction_in_climate_emission"
        ) {
          return isInInterval(item[id], new_filter[id]);
        } else if (id === "period_from") {
          return isAfter(item.storage_date, new_filter[id]);
        } else if (id === "period_to") {
          return isBefore(item.storage_date, new_filter[id]);
 }
        else if (id === "certification_id") {
          return (
            item.certification_id &&
            item.certification_id
              .toString()
              .toLowerCase()
              .includes(new_filter.certification_id.toString().toLowerCase())
          );
        } else if (id === "storage_terminal") {
          return (
            item.storage_terminal &&
            item.storage_terminal
              .toString()
              .toLowerCase()
              .includes(new_filter.storage_terminal.toString().toLowerCase())
          );
        } else if (id === "red_version") {
          return (
              item.red_version &&
              item.red_version.toString()
                  .toLowerCase() === new_filter.red_version.toString().toLowerCase()
          );
        } else if (item[id] && !item[id].toString().includes(new_filter[id])) {
          return false;
        }
        return true;
      });
    });
    setFilteredList(filteredList);
  };

  const clearFilter = () => {
    setFilteredList(list);
    setFilter({});
  };

  const getStorageList = async () => {
    const res = await getStorages();

    if (res && res.valid) {
      setList(res.data);
      setFilteredList(res.data);
    }
  };

  useEffect(() => {
    getStorageList();
  }, []);

  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.title}
      >
        <Grid item>
          <Typography variant="h2" className={classes.h2}>
            <FormattedMessage id="cardView.index.heading" />
          </Typography>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={1}>

            <Grid item>
              <ButtonRounded
                variant="contained"
                color="primary"
                onClick={() => openModal("create")}
                className={classes.btn}
              >
                <FormattedMessage id="cardView.index.addBioTrans" />
              </ButtonRounded>
            </Grid>
            <Grid item>
              <ToggleButton
                labels={["Reporting", "Simulation"]}
                defaultLabelIndex={isSimulation ? 1 : 0}
                size="small"
                bgWhite
                handleChange={toggleSimulation}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Filters
        setup={filterSetup}
        filterValues={{ ...filter }}
        clearFilter={clearFilter}
        onChange={filterList}
      />
      <KeyMetrics data={filteredList} />
      <Paper className={classes.paper}>
        <Typography variant="h6" style={{ marginBottom: 16 }}>
          <FormattedMessage id="cardView.storage.availableSusCriteria" />
        </Typography>
        <SmartTable
          headCells={storageProperties}
          smartProps={{
            initFilteredHead: {},
            selectEntry: setSelectedEntry,
            deleteEntry: () => openModal("delete", selectedEntry),
            editEntry: () => openModal("edit", selectedEntry),
            disabledActions:
              selectedEntry && selectedEntry.reservation_outgoing,
          }}
          rows={list.length === 0 ? null : filteredList}
          aggregate={{
            ...aggregateProperties,
            removeZeroBalanced: true,
          }}
        />
      </Paper>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className={classes.iconExpand} />}
        >
          <Typography variant="h6" style={{ marginTop: 16, marginBottom: 16 }}>
            <FormattedMessage id="cardView.storage.usedSusCriteria" />
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <SmartTable
            headCells={storageProperties}
            smartProps={{
              initFilteredHead: {},
              selectEntry: setSelectedEntry,
              deleteEntry: () => openModal("delete", selectedEntry),
              editEntry: () => openModal("edit", selectedEntry),
              disabledActions:
                selectedEntry && selectedEntry.reservation_outgoing,
            }}
            rows={list.length === 0 ? null : filteredList}
            aggregate={{
              ...aggregateProperties,
              showZeroBalanced: true,
            }}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>

      {selectedEntry && (
        <>
          <ModalDelete
            name={"Biofuel Transaction"}
            id={selectedEntry.id}
            Delete={deleteStorage}
            open={openModalId === "delete"}
            onClose={closeModal}
          />
          <ModalAddStorage
            open={openModalId === "edit"}
            onClose={closeModal}
            onConfirm={editStorage}
            mode="edit"
            bioTransactions={selectedEntry}
          />
        </>
      )}

      <ModalAddStorage
        open={openModalId === "create"}
        onClose={closeModal}
        onConfirm={addStorage}
        mode="add"
      />
    </div>
  );
};

export default StorageView;
