import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "./components/StepLabel";
import StepIcon from "./components/StepIcon";
import StepButton from "@material-ui/core/StepButton";
import StepConnector from "./components/StepConnector";
import CustomerRequirements from "./components/CustomerRequirements";
import LoadingView from "./components/LoadingView";
import SuccessView from "./components/SuccessView";
import MainCardTables from "./components/MainCardTables";
import StatusModeType from "./components/StatusModeType";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusModeType: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    stepper: {
      backgroundColor: "inherit",
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: theme.spacing(4),
      width: "calc(100% + 54px)",
      marginLeft: "-30px",
    },
  })
);

interface Props {
  periodName: string;
  isSimulation: boolean;
  isJoint: boolean;
  activeStep: number;
  isFetching: boolean;
  steps: any;
  allocateUpdateCustomerReq: any;
  changeStep: any;
}

const MultiStepView: React.FC<Props> = ({
  periodName,
  isSimulation,
  isJoint,
  activeStep,
  isFetching,
  steps,
  allocateUpdateCustomerReq,
  changeStep,
}) => {
  const classes = useStyles();

  return (
    <>
      <StatusModeType
        className={classes.statusModeType}
        mode={isSimulation ? "Simulation" : "Production"}
        period={periodName}
      />
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        connector={<StepConnector />}
        className={classes.stepper}
      >
        {Object.keys(steps).map((rank, index) => {
          return (
            <Step key={steps[rank].name}>
              <StepButton
                onClick={() => changeStep(index)}
                disabled={steps[rank].isDisabled}
              >
                <StepLabel
                  StepIconComponent={props => (
                    <StepIcon
                      disabled={steps[rank].isDisabled}
                      status={steps[rank].isDirty ? steps[rank].status : null}
                      {...props}
                    ></StepIcon>
                  )}
                >
                  {steps[rank].name}
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === 6 ? (
        isFetching ? (
          <LoadingView text={"Allocation process is finalizing"} />
        ) : (
          <SuccessView text={"Allocation process is successfull"} />
        )
      ) : isFetching ? (
        <LoadingView text={"Data is getting prepared"} />
      ) : activeStep === 5 ? (
        <CustomerRequirements
          stepData={steps[activeStep]}
          allocateUpdateCustomerReq={allocateUpdateCustomerReq}
          handleNext={() => changeStep(activeStep + 1)}
          handleBack={() => changeStep(activeStep - 1)}
        />
      ) : (
        <MainCardTables
          step={activeStep}
          handleNext={() => changeStep(activeStep + 1)}
          handleBack={() => changeStep(activeStep - 1)}
        />
      )}
    </>
  );
};

export default MultiStepView;
