import { getToken } from "../utils";
import { BASE_URL } from "../endpoints";

export async function getSettingAccesses() {
  const token = getToken();

  let valid = false;
  let settingsResult;

  let data = {
    role: true,
    excise_duty_rates: true,
    storage_terminals: true,
    government_requirements: true,
    tax_years: true,
    masterdata: true,
    tax_classes: true,
    sales_products: true,
    users: true,
    app_variables: true,
    energy_contents: true,
    ghg_rates: true,
    raw_materials: true,
  };

  if (token) {
    try {
      const response = await fetch(`${BASE_URL}/api/settings`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token || "",
        },
      });
      settingsResult = await response.json();
      valid =
        settingsResult &&
        settingsResult.success &&
        settingsResult.settings &&
        settingsResult.settings[0];

      if (settingsResult.settings[0]) {
        data = {
          role: settingsResult.settings[0].role,
          excise_duty_rates: !settingsResult.settings[0].excise_duty_rates,
          storage_terminals: !settingsResult.settings[0].storage_terminals,
          government_requirements: !settingsResult.settings[0].government_requirements,
          tax_years: !settingsResult.settings[0].tax_years,
          masterdata: !settingsResult.settings[0].masterdata,
          tax_classes: !settingsResult.settings[0].tax_classes,
          sales_products: !settingsResult.settings[0].sales_products,
          users: !settingsResult.settings[0].users,
          app_variables: !settingsResult.settings[0].app_variables,
          energy_contents: !settingsResult.settings[0].energy_contents,
          ghg_rates: !settingsResult.settings[0].ghg_rates,
          raw_materials: !settingsResult.settings[0].raw_materials,
        };
      }
    } catch (except) {
      valid = false; // network error
    }
  } else {
    return null; // No token
  }

  if (!valid) {
    if (settingsResult && settingsResult.errors) {
      return {
        valid: false,
        message: settingsResult.errors,
      };
    }
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    ...data,
  };
}
