export const doughnutData = {
  labels: ["BT ET", "BT DT", "Ikke BK", "BD ET", "BD DT"],
  datasets: [
    {
      data: [19, 19, 40, 19, 21],
      backgroundColor: ["#7FC4CD", "#00899C", "#FEAE46", "#80C09D", "#28823C"],
      borderWidth: 0,
    },
  ],
};

export const barData = (canvas: any) => {
  const ctx = canvas.getContext("2d");
  let gradientGreen = ctx.createLinearGradient(0, 0, 0, 200);
  gradientGreen.addColorStop(0, "rgba(1,130,59, 0.25)");
  gradientGreen.addColorStop(1, "rgba(1,130,59, 1)");
  let gradientBlue = ctx.createLinearGradient(0, 0, 0, 200);
  gradientBlue.addColorStop(0, "rgba(0,137,156,0.25)");
  gradientBlue.addColorStop(1, "rgba(0,137,156,1)");

  return {
    labels: [
      "Bio i diesel (ET)",
      "Bio i diesel (DT)",
      "Bio i bensin (ET)",
      "Bio i bensin (DT)",
    ],
    datasets: [
      {
        label: "Lager",
        barPercentage: 0.8,
        categoryPercentage: 0.4,
        backgroundColor: [
          gradientGreen,
          gradientGreen,
          gradientBlue,
          gradientBlue,
        ],
        borderWidth: 0,
        data: [16000000, 7000000, 6000000, 8000000],
      },
      {
        label: "Omsatt",
        barPercentage: 0.8,
        categoryPercentage: 0.4,
        backgroundColor: [
          "rgba(1,130,59, 0.25)",
          "rgba(1,130,59, 0.25)",
          "rgba(0,137,156,0.25)",
          "rgba(0,137,156,0.25)",
        ],
        borderColor: ["#28823C", "#28823C", "#00899C", "#00899C"],
        borderWidth: 2,
        data: [14000000, 3400000, 5600000, 18000000],
      },
    ],
  };
};

export const lineData = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "1",
      fill: true,
      lineTension: 0.3,
      backgroundColor: "rgba(1,130,59, 0.7)",
      borderColor: "transparent",
      pointHoverRadius: 0,
      pointRadius: 0,
      pointHitRadius: 20,
      data: [
        0,
        60554456,
        41554456,
        25554456,
        40554456,
        35554456,
        59554456,
        60554456,
        21554456,
        46554456,
        20554456,
        13554456,
      ],
    },
    {
      label: "2",
      fill: true,
      lineTension: 0.3,
      backgroundColor: "rgba(0,137,156,0.85)",
      borderColor: "transparent",
      pointHoverRadius: 0,
      pointRadius: 0,
      pointHitRadius: 20,
      data: [
        0,
        59000000,
        80000000,
        81000000,
        56000000,
        55000000,
        40000000,
        65000000,
        59000000,
        40000000,
        23000000,
        56000000,
      ],
    },
    {
      label: "3",
      fill: true,
      lineTension: 0.3,
      backgroundColor: "rgba(255,172,18, 1)",
      borderColor: "transparent",
      pointHoverRadius: 0,
      pointRadius: 0,
      pointHitRadius: 20,
      data: [
        0,
        95000001,
        40000001,
        65002001,
        59000001,
        80000001,
        81000001,
        56000001,
        55000001,
        40000001,
        65000001,
        59000001,
      ],
    },
  ],
};
