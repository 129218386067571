import React, {Props, useEffect, useState} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import ButtonRounded from "../../../components/ButtonRounded";
import Card from "@material-ui/core/Card";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        container: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr", // Two columns with equal width
            alignItems: "center",
            gap: theme.spacing(2), // Add some gap between columns
        },
        h2: {
            [theme.breakpoints.down("md")]: {
                fontSize: "32px",
            },
        },
        btn: {
            minWidth: "200px",
            "&:not(:first-child)": {
                marginLeft: "20px",
                [theme.breakpoints.down("md")]: {
                    marginLeft: "10px",
                },
            },
            padding: "10px",
        },
        buttonContainer: {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: theme.spacing(2),
        },
        buttonMargin: {
            marginBottom: theme.spacing(1), // Adjust the spacing as needed
        },

        toggleButtonMargin: {
            marginBottom: theme.spacing(2), // Adjust the bottom margin as needed
        },
        toggleCardContainer: {
            marginTop: theme.spacing(3),
        },
        toggleCardContent: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
        toggleCardTitle: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        card: {
            margin: "25px",
        },
        cardCommon: {
            marginTop: theme.spacing(3),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        cardContent: {
            padding: theme.spacing(3.75),
            paddingTop: theme.spacing(4),
            "&:last-child": {
                paddingBottom: theme.spacing(3.75),
            },
        },
        cardTitle: {
            marginBottom: theme.spacing(2.5),
        },
        cardSubTitle: {
            fontSize: theme.typography.h5.fontSize,
            marginBottom: theme.spacing(2.5),
            marginTop: theme.spacing(2),
        },
        cardContainer: {
            display: "flex",
            alignItems: "stretch", // Ensure both cards have the same height
            marginBottom: theme.spacing(1),
            justifyContent: "space-between",
        },
        cardAllocation: {
            flex: "1", // Takes 2/3 of the available space
        },

        // Style for the second card with 1/3 space
        cardReport: {
            flex: "2", // Takes 1/3 of the available space
        },
        tableTitle: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
        },
        outputTableTopLabel: {
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(2),
        },
        table: {
            "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
                backgroundColor: theme.palette.grey[50],
            },
            "& .MuiTableCell-root": {
                border: "none",
                paddingTop: 0,
                paddingBottom: 0,
                height: theme.spacing(4),
            },
            "& .MuiTableCell-head": {
                color: "inherit",
                fontSize: "11px",
                fontWeight: 700,
            },
        },
        tableContainer: {
            maxHeight: "450px",
            overflowY: "auto", // "scroll",
            "& .MuiOutlinedInput-input": {
                ...theme.typography.caption,
                fontWeight: 500,
                color: theme.palette.primary.main,
            },
        },
        iconBtn: {
            padding: 0,
            marginLeft: theme.spacing(2),
        },
        companyName: {
            fontWeight: 500,
            color: theme.palette.primary.main,
        },
        paginationContainer: {
            display: "flex",
            justifyContent: "flex-end",
            marginTop: theme.spacing(3),
        },
        addButton: {
            minWidth: "200px",
        },
        circularProgress: {
            margin: "80px",
            width: "48px",
            height: "48px",
        },
        soldVolumesTotal: {
            "& .MuiTableCell-root": {
                fontWeight: 700,
                paddingTop: theme.spacing(1.5),
                paddingBottom: theme.spacing(1.5),
                paddingRight: theme.spacing(3.8),
            },
        },
    })
);

const MarineVoumeContainer: React.FC<Props<any>> = () => {
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        // Your useEffect logic here
    }, []);

    return (
        <div>
            <div className={classes.container}>
                <div>
                    <h1>Marine volumes</h1>
                </div>
                <div className={classes.buttonContainer}>
                    <ButtonRounded className={classes.buttonMargin}
                                   fullWidth
                                   variant="contained"
                                   color="secondary"
                                   onClick={() => history.goBack()}
                    >
                        Cancel
                    </ButtonRounded>
                    <ButtonRounded className={classes.buttonMargin}
                                   fullWidth
                                   variant="contained"
                                   color="primary"
                        // onClick={() => handleSave()} // Add your click handler
                    >
                        Save
                    </ButtonRounded>
                </div>
            </div>

            <div>
                <h3>Preliminary mandate fulfillment</h3>
                <Card className={classes.card}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Mandate Description</TableCell>
                                <TableCell>Mandate Level</TableCell>
                                <TableCell>Required Volume</TableCell>
                                <TableCell>Relevant Volume</TableCell>
                                <TableCell>Estimated Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>General mandate</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>No madate required to fill</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
            </div>

            <div>
                <h3>Estimated surplus volume</h3>
                <Card className={classes.card}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Surplus Description</TableCell>
                                <TableCell>Required by customer</TableCell>
                                <TableCell>Est. Additional surplus</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>Bio component in diesel</TableCell>
                                <TableCell style={{width: "20%"}}>100000</TableCell>
                                <TableCell style={{width: "20%"}}>365000</TableCell>
                                <TableCell style={{width: "20%"}}>365000</TableCell>
                            </TableRow>
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell>Total surplus</TableCell>
                                <TableCell>100000</TableCell>
                                <TableCell>365000</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </Card>
            </div>

            <div>
                <h3>Dyed diesel Volumes(marines)</h3>
                <Card className={classes.card}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Product Component</TableCell>
                                <TableCell>Excise duty volume</TableCell>
                                <TableCell>Sold Volume</TableCell>
                                <TableCell>Bio above mandate</TableCell>
                                <TableCell>Bio to be used in fulf.</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>Diesel</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>n/a</TableCell>
                                <TableCell style={{width: "20%"}}>n/a</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>Bio component in Diesel</TableCell>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}></TableCell>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}></TableCell>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}></TableCell>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>Etanol</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>n/a</TableCell>
                                <TableCell style={{width: "20%"}}>n/a</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>FAME</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>n/a</TableCell>
                                <TableCell style={{width: "20%"}}>n/a</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>Nafta</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>n/a</TableCell>
                                <TableCell style={{width: "20%"}}>n/a</TableCell>
                            </TableRow>
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell>Total surplus</TableCell>
                                <TableCell></TableCell>
                                <TableCell>0</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </Card>
            </div>
        </div>
    );
};

export default MarineVoumeContainer;
