import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ButtonRounded from "../../../../components/ButtonRounded";
import ModalInfo from "../../../../components/modal/ModalInfo";
import useModal from "../../../../components/modal/useModal";
import ModalContainer from "../../../../components/ModalContainer";
import parseData from "../../../../components/texts/parseData";
import { postBiotemplateVolumeTransfer } from "../../../../api/sustainability/storage/outgoing-transaction/postBiotemplateReservations";

const tableHeadings = [
  { name: "Batch ID", size: "15%", id: "serialNumber" },
  { name: "POS ID", size: "15%", id: "pos_id" },
  { name: "POS Date", size: "15%", id: "pos_date" },
  { name: "Trade ID", size: "15%", id: "trade_id" },
  { name: "Shipment (nomination) ID", size: "15%", id: "shipment_sale_nomination" },
  { name: "Vessel", size: "15%", id: "vessel" },
  { name: "Storage Terminal Out", size: "15%", id: "storage_terminal_out" },
  { name: "Storage Terminal In", size: "15%", id: "storage_terminal_in" },
  { name: "Transfer Date", size: "15%", id: "b_l_date" },
  { name: "B/L Date", size: "15%", id: "b_l_date" },
  { name: "Product", size: "15%", id: "biofuel_type" },
  { name: "Quantity", size: "15%", id: "quantity" },
  { name: "UoM", size: "15%", id: "uom" },
  { name: "Feedstock Origin", size: "15%", id: "raw_material" },
  { name: "Country of Origin of feedstock", size: "8%", id: "raw_material_origin" },
  { name: "Certificate", size: "15%", id: "certificate" },
  { name: "Site Start", size: "15%", id: "site_start" },
  { name: "Eec", size: "15%", id: "eec" },
  { name: "El", size: "15%", id: "el" },
  { name: "Ep", size: "15%", id: "ep" },
  { name: "Etd", size: "15%", id: "etd" },
  { name: "Eu", size: "15%", id: "eu" },
  { name: "Esca", size: "15%", id: "esca" },
  { name: "Eccs", size: "15%", id: "eccs" },
  { name: "Eccr", size: "15%", id: "eccr" },
  { name: "Eee", size: "15%", id: "eee" },
  { name: "Etot", size: "15%", id: "etot" },
  { name: "GHG Savings (%)", size: "15%", id: "ghg_savings" },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContent: {
      padding: theme.spacing(2, 3),
    },
    red: {
      color: "red"
    },
    titleBar: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    inputSearch: {
      "& .MuiInput-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: "2px 8px",
      },
    },
    tableEmpltyRow: {
      "& .MuiTableCell-root": {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
      },
    },
    iconDelete: {
      color: theme.palette.error.main,
    },
    linkDownload: {
      color: "inherit",
      paddingTop: theme.spacing(0.5),
    },
    modalContent: {},
    container: {
      padding: theme.spacing(2),
    },
    btnContainer: {
      padding: theme.spacing(2, 0),
      marginBottom: theme.spacing(2),
    },
    btnCancel: {},
    tableContainer: {
      overflowY: "auto",
      "& .MuiOutlinedInput-input": {
        ...theme.typography.caption,
      },
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        textAlign: "center",
        ...theme.typography.caption,
      },
      "& .MuiTableCell-head": {
        ...theme.typography.caption,
        fontWeight: 500,
        textAlign: "center",
      },
    },
    root: {
      width: "100%",
    },
    content: {
      display: "flex",
    },
    noData: {
      width: "100%",
      height: "300px",
      border: `1px solid ${theme.palette.grey[200]}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    noDataText: {
      color: theme.palette.grey[300],
    },
    sideGradient: {
      top: "0px",
      width: "34px",
      marginLeft: "-24px",
      background:
        "linear-gradient(90deg, rgb(255, 255, 255, 1) 30%, rgb(255, 255, 255, 0))",
      zIndex: 100,
    },
    sideButtons: {
      top: "0px",
      width: "80px",
      boxShadow: "-5px -5px 5px 0 rgba(30, 30, 30, 0.05)",
      color: theme.palette.primary.main,
      backgroundColor: "#fdefd9",
      textAlign: "center",
      zIndex: 1,
    },
    sideButtonHead: { height: "75px", width: "70px" },
    sideButton: { height: "35px", width: "70px" },
    menu: {
      height: "300px",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(1, 2),
      overflowY: "scroll",
    },
    menuTitle: {
      marginBottom: theme.spacing(2),
    },
    menuLabel: { color: theme.palette.primary.main },
    menuLabelSelected: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    tableWrapperSmart: {
      marginLeft: "-28px",
      paddingLeft: "19px",
      marginRight: "-2px",
      width: "100%",
      overflowX: "scroll",
    },
    tableWrapper: {
      width: "100%",
    },
    columnTitle: {
      fontSize: "12px",
      fontWeight: "bold",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    textField: {
      margin: 0,
    },
    textFieldColored: {
      margin: 0,
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
      "& .MuiOutlinedInput-root:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline":
      {
        borderColor: theme.palette.primary.main,
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
    },
    toggleIcon: {
      borderRadius: "100%",
      margin: -5,
      padding: 0,
      marginLeft: 0.5,
      transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    select: {
      width: "150px",
      [theme.breakpoints.down("md")]: {
        width: "100px",
      },
    },
    fixedWidth: {
      width: theme.spacing(22),
    },
    creatableSelect: {
      ...theme.typography.body1,

      "& .react-select__control": {
        padding: 0,
        borderColor: theme.palette.grey[300],
        boxShadow: "none",
        minHeight: "35px",
        "&:hover": {
          borderColor: theme.palette.grey[300],
        },
      },
      "& .react-select__placeholder": {
        color: theme.palette.grey[300],
      },
      "& .react-select__value-container": {
        padding: "0px 8px",
      },
      "& .react-select__single-value": {
        color: theme.palette.primary.main,
      },
      "& .react-select__indicator-separator": {
        backgroundColor: "unset",
      },
      "& .react-select__indicators": {
        color: theme.palette.primary.main,
      },
      "& .react-select__option": {
        "&:active": {
          backgroundColor: "rgba(0, 0, 0, 0.14)",
        },
      },
      "& .react-select__option--is-focused": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "& .react-select__option--is-selected": {
        backgroundColor: "rgba(0, 0, 0, 0.14)",
        color: theme.palette.text.primary,
      },
    },
  })
);

interface ModalDeliveryTableProps {
  open: boolean;
  onClose: () => void;
  handleSubmit: () => void;
  title: string;
  data: any;
}

const ModalVolumeTransferTable: React.FC<any> = ({
  open,
  onClose,
  title,
  handleSubmit,
  data
}) => {
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [infoModal, setInfoModal] = useState({ title: "", description: "" });
  const [showModalMapped, setShowModalMapped] = useState<boolean>(false);
  const [fileData, setFileData] = useState<any[]>([]);
  const [errorData, setErrorData] = useState<any[]>([]);
  const [unMappedData, setUnMappedData] = useState<any[]>([]);
  const classes = useStyles();
  const { openModalId, toggle } = useModal();
  const history = useHistory();

  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      root: { padding: theme.spacing(1) },
      head: {
        whiteSpace: "break-spaces",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.grey[300]}`,
        fontWeight: 500,
      },
      body: {
        whiteSpace: "nowrap",
        fontSize: 12,
        border: `1px solid ${theme.palette.grey[300]}`,
      },
    })
  )(TableCell);

  const handleFileDataSubmit = async (values: any) => {
    handleSubmit(values);
  };

  const closeModalMapped = () => {
    setShowModalMapped(false);
  };

  const handleSave = async () => {
    const res = await postBiotemplateVolumeTransfer({
      volume: data[0].volume,
      biofuel_type: data[0].biofuel_type,
      storage_terminal_out: data[0].storage_terminal_out,
      storage_terminal_in: data[0].storage_terminal_in,
      transfer_date: data[0].transfer_date,
      status: data[0].status,
      comment: data[0].comment,
      shipment_id: data[0].shipment_id,
      vessel: data[0].vessel,
      b_l_date: data[0].b_l_date,
      biotemplates: data[0].biotemplates,
    });

    if (res && res.success) {
      handleSubmit();
      openModal("info", `Transaction successfully saved`, "");
      onClose();
      history.goBack();
    } else {
      setIsError(true);
      if (res && res.message) {
        openModal(
          "info",
          `Transaction failed`,
          `${res.message}`
        );
      } else {
        openModal(
          "info",
          `Transaction failed`,
          "Some Error occured. Transaction failed to save"
        );
      }
    }
  };

  const openModal = (id: string, text: string, message: string) => {
    if (id === "info") {
      setInfoModal({ title: text, description: message });
    }
    toggle(id);
  };

  const closeModal = () => {
    toggle();
  };
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={title}
      maxWidth="xl"
    >
      <div className={classes.modalContent}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} className={classes.btnContainer}>
              <Grid item xs={4}>
                <ButtonRounded
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={handleSave}
                  disabled={isError}
                >
                  Save
                </ButtonRounded>
              </Grid>
            </Grid>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  {tableHeadings.map((headCell, index) =>
                    <StyledTableCell
                      key={headCell.id + "headCell" + index}
                      style={{
                        width: headCell.size,
                        minWidth: "150px",
                      }}
                    >
                      <Typography className={classes.columnTitle}>
                        {headCell.name}
                      </Typography>

                    </StyledTableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(data[0].biotemplates) && data[0].biotemplates.length > 0 &&
                  data[0].biotemplates.map((entry: any, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{entry.serialNumber}</TableCell>
                        <TableCell>{"TBD"}</TableCell>
                        <TableCell>{moment(entry.pos_date).format("DD-MM-YYYY")}</TableCell>
                        <TableCell>{entry.trade_id}</TableCell>
                        <TableCell>{data[0].shipment_id}</TableCell>
                        <TableCell>{data[0].vessel}</TableCell>
                        <TableCell>{data[0].storage_terminal_out}</TableCell>
                        <TableCell>{data[0].storage_terminal_in}</TableCell>
                        <TableCell>{moment(data[0].transfer_date).format("DD-MM-YYYY")}</TableCell>
                        <TableCell>{moment(data[0].b_l_date).format("DD-MM-YYYY")}</TableCell>
                        <TableCell>{entry.biofuel_type}</TableCell>
                        <TableCell>{parseData(entry.quantity / 1000, 3)}</TableCell>
                        <TableCell>{"m3"}</TableCell>
                        <TableCell>{entry.raw_material}</TableCell>
                        <TableCell>{entry.raw_material_origin}</TableCell>
                        <TableCell>{entry.certificate}</TableCell>
                        <TableCell>{entry.site_start}</TableCell>
                        <TableCell>{parseData((parseFloat(entry.eec).toFixed(2)), 2)}</TableCell>
                        <TableCell>{parseData((parseFloat(entry.el).toFixed(2)), 2)}</TableCell>
                        <TableCell>{parseData((parseFloat(entry.ep).toFixed(2)), 2)}</TableCell>
                        <TableCell>{parseData((parseFloat(entry.etd).toFixed(2)), 2)}</TableCell>
                        <TableCell>{parseData((parseFloat(entry.eu).toFixed(2)), 2)}</TableCell>
                        <TableCell>{parseData((parseFloat(entry.esca).toFixed(2)), 2)}</TableCell>
                        <TableCell>{parseData((parseFloat(entry.eccs).toFixed(2)), 2)}</TableCell>
                        <TableCell>{parseData((parseFloat(entry.eccr).toFixed(2)), 2)}</TableCell>
                        <TableCell>{parseData((parseFloat(entry.eee).toFixed(2)), 2)}</TableCell>
                        <TableCell>{parseData((parseFloat(entry.etot).toFixed(2)), 2)}</TableCell>
                        <TableCell>{parseData((parseFloat(entry.reduction_in_climate_emission).toFixed(2)), 2)}</TableCell>
                      </TableRow>
                    );
                  })
                }
                <TableRow>
                  <TableCell colSpan={10}></TableCell>
                  <StyledTableCell>
                    <Typography className={classes.columnTitle}>
                      {"Total Volume"}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Typography className={classes.columnTitle}>
                      {parseData(data[0].volume / 1000, 3) + ` m3`}
                    </Typography>
                  </StyledTableCell>

                  <TableCell colSpan={17}></TableCell>
                </TableRow>

              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </div>
      <ModalInfo
        text={infoModal.title}
        message={infoModal.description}
        open={openModalId === "info"}
        onClose={closeModal}
      />
    </ModalContainer >
  );
};

export default ModalVolumeTransferTable;
