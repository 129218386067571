/**
 *  Forgot password form
 */

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import InputAdorment from "@material-ui/core/InputAdornment";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { FormattedMessage } from "react-intl";
import ForgotUsername from "./ForgotUsername";

import authPromptResetPassword from "../../api/authPromptResetPassword";

const useStyle = makeStyles((theme: Theme) => ({
  backToLogin: {
    textTransform: "none",
    position: "absolute",
    top: theme.spacing(6),
    left: theme.spacing(6),
    fontSize: "16px",
  },
  forgotTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(5),
  },
  forgotMessage: {
    textAlign: "center",
    marginBottom: theme.spacing(5),
  },
  forgotPasswordBtn: {
    height: "40px",
    fontSize: "12px",
  },
}));

interface ForgotPasswordProps {
  goBackToLogin: () => void;
  goToEmailSent: () => void;
}

const ForgotPasswordForm: React.FC<ForgotPasswordProps> = ({
  goBackToLogin,
  goToEmailSent,
}) => {
  const classes = useStyle();

  const onSubmit = async (values: any) => {
    let resetPassAnswer = await authPromptResetPassword(values.username);
    if (!resetPassAnswer.valid) {
      return {
        username: resetPassAnswer.message,
      };
    }

    goToEmailSent();
  };

  const validate = (values: any) => {
    const errors: { [k: string]: any } = {};
    if (!values.username || !(values.username.length > 4)) {
      errors.username = !values.username ? (
        <FormattedMessage id={"loginView.errorEmpty"} />
      ) : (
        <FormattedMessage id={"loginView.errorFill"} />
      );
    }

    return errors;
  };

  return (
    <>
      <Button
        color="primary"
        className={classes.backToLogin}
        onClick={goBackToLogin}
      >
        <ArrowBackIcon fontSize="small" />
        &nbsp; <FormattedMessage id={"loginView.backToLogin"} />
      </Button>
      <Form onSubmit={onSubmit} validate={validate}>
        {({
          handleSubmit,
          submitting,
          hasValidationErrors,
          errors,
          active,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Typography variant="h4" className={classes.forgotTitle}>
              <FormattedMessage id={"loginView.forgotPasswordTitle"} />
            </Typography>
            <Typography variant="body1" className={classes.forgotMessage}>
              <FormattedMessage id={"loginView.forgotPasswordDescription"} />
            </Typography>
            <FormattedMessage id={"loginView.username"}>
              {username => (
                <Field
                  component={TextField}
                  name="username"
                  placeholder={username}
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdorment position="start">
                        <PersonOutlinedIcon
                          color={active === "username" ? "action" : "disabled"}
                        />
                      </InputAdorment>
                    ),
                  }}
                />
              )}
            </FormattedMessage>
            <ForgotUsername />
            <Button
              className={classes.forgotPasswordBtn}
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={submitting || hasValidationErrors}
            >
              <FormattedMessage id={"loginView.resetPassword"} />
            </Button>
          </form>
        )}
      </Form>
    </>
  );
};

export default ForgotPasswordForm;
