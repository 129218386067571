import React from "react";
import { Document, Page, View, Text, StyleSheet } from "@react-pdf/renderer";
import { page, colors, typography } from "../config/theme";
import InfoBox from "../components/InfoBox";
import InfoText from "../components/InfoText";
import Description from "./Description";
import Table from "../components/Table";
import Header from "../components/Header";
import Footer from "../components/Footer";
import parseData from "../../../../components/texts/parseData";
import translation from "./translation";

const styles = StyleSheet.create({
  page: {
    paddingTop: 25,
    paddingHorizontal: 30,
    paddingBottom: 70,
  },
  root: {},
  midSection: {
    flexDirection: "row",
    marginBottom: 15,
  },
  infoContainer: {
    flex: 1,
    marginRight: 30,
  },
  paraContainer: {
    flex: 2,
    paddingRight: 20,
  },
  bottomSection: {
    flexDirection: "row",
  },
  tableContainer: {
    flex: 1,
  },
  total: { paddingRight: 10 },
  infoContainer2: {
    width: 230,
  },
});

interface Props {
  language: "EN" | "NO";
  data: any;
}

const CustomerReport: React.FC<Props> = ({ language, data }) => {
  const t = translation[language];

  const getTotal = (rows: any[]) => {
    let sum = 0;
    Array.isArray(rows) &&
      rows.map(row => (sum = parseFloat(row.quantity) + sum));
    return parseData(sum, 3);
  };

  return (
    <Document>
      <Page size={page.size} orientation="landscape" style={styles.page}>
        <Header
          title={t.header_title}
          subtitle={t.header_subtitle}
          logo="/assets/preem-logo.png"
        />
        <View style={styles.root}>
          <View style={styles.midSection}>
            <View style={styles.infoContainer}>
              <InfoBox title={t.infoBox1_title}>
                <InfoText
                  title={t.infoBox1_customer}
                  value={data.customer_name}
                  colored
                  spaceBottom
                />
                <InfoText
                  title={t.infoBox1_product}
                  value={data.biofuel_type}
                  colored
                />
                <InfoText
                  title={t.infoBox1_quantity}
                  value={parseData(data.volume, 3) + " " + t.infoBox1_m3}
                  colored
                />
                <InfoText
                  title={t.infoBox1_deliveryPeriod}
                  value={data.delivery_period}
                  colored
                  spaceBottom
                />

                <InfoText
                  title={t.infoBox1_issued}
                  value={data.report_issued_at}
                  colored
                />
                <InfoText
                  title={t.infoBox1_noteRef}
                  value={data.note_ref}
                  colored
                  spaceBottom
                />
              </InfoBox>
            </View>
            <View style={styles.paraContainer}>
              <Description
                language={language}
                description={{
                  name: data.name,
                  position: data.position,
                  date: data.date,
                }}
              />
            </View>
          </View>

          <View style={styles.bottomSection}>
            <View style={styles.tableContainer}>
              <Table
                smallTextSize
                columns={[
                  { name: "#", id: "index", rightAlign: true },
                  { name: t.table_product, id: "biofuel_type" },
                  {
                    name: t.table_volume,
                    id: "quantity",
                    rightAlign: true,
                    m3: true,
                    decimals: 3,
                  },
                  {
                    name: t.table_rawMaterial,
                    id: "raw_material",

                    large: true,
                  },
                  {
                    name: t.table_country,
                    id: "raw_material_origin",
                    large: true,
                  },
                  {
                    name: t.table_doubleCount,
                    id: "double_counted_norway",
                    center: true,
                    thin: true,
                  },
                  {
                    name: t.table_ghgReduction,
                    id: "reduction_in_climate_emission",
                    center: true,
                    percentage: true,
                  },
                  {
                    name: t.table_docClimaEmission,
                    id: "documentation_emission_of_climate",
                    large: true,
                  },
                  {
                    name: t.table_docLandCriteria,
                    id: "documentation_area_criteria",
                    large: true,
                  },
                  {
                    name: t.table_docMassBalance,
                    id: "documentation_mass_balance",
                    large: true,
                  },
                ]}
                rows={data.allocations}
                footer={
                  data.allocations.length > 0
                    ? {
                        name: t.table_total,
                        value: getTotal(data.allocations) + " " + t.infoBox1_m3,
                      }
                    : {
                        name: t.table_noData,
                        value: "",
                      }
                }
              />
            </View>
          </View>
        </View>

        <Footer language={language} />
      </Page>
    </Document>
  );
};

export default CustomerReport;
