/**
 * Get users list
 * only accessible to admins
 */

import { getToken } from "../utils";
import { BASE_URL } from "../endpoints";

export default (setting: string) => {
  return async () => {
    const token = getToken();
    let valid = false;
    let getResult;

    if (token) {
      try {
        const response = await fetch(`${BASE_URL}/api/${setting}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        getResult = await response.json();
        valid = getResult && getResult.success && getResult[setting];
      } catch (except) {
        valid = false; // network error
      }
    } else {
      return null; // No token
    }

    if (!valid) {
      if (getResult && getResult.errors) {
        return {
          valid: false,
          message: getResult.errors,
        };
      }
      return {
        valid: false,
        message: "Something unexpected happened",
      };
    }

    return {
      valid: true,
      data: getResult[setting],
      info_text_flag: getResult.info_text_flag,
    };
  };
};
