import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ButtonRounded from "../../../components/ButtonRounded";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { FormattedMessage } from "react-intl";

import useModal from "../../../components/modal/useModal";
import ModalDelete from "../../../components/modal/ModalDelete";

import ModalEditAppVariable from "../../../components/modal/ModalEditAppVariable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backBtn: {
      textTransform: "none",
      marginBottom: theme.spacing(2),
    },
    titleBar: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    iconDelete: {
      color: theme.palette.error.main,
    },
  })
);

interface Props {
  data: any;
  getAppVariables: any;
  editAppVariables: any;
}

const SettingView: React.FC<Props> = ({ data, editAppVariables }) => {
  const { openModalId, toggle } = useModal();
  const [selectedEntry, setSelectedEntry] = useState<any>(null);

  const history = useHistory();
  const classes = useStyles();

  const openModal = (id: string, selected?: any) => {
    if (selected) { setSelectedEntry({ ...selected }); }
    toggle(id);
  };

  const closeModal = () => {
    // fetchData();
    // setSelectedSetting(null);
    toggle();
  };

  return (
    <div>
      <Button
        color="primary"
        className={classes.backBtn}
        onClick={() => history.goBack()}
      >
        <Icon color="primary">arrow_back</Icon>
        <Typography variant="h6">
          &nbsp; <FormattedMessage id="settingsView.back" />
        </Typography>
      </Button>

      <Card>
        <CardContent>
          <Typography className={classes.titleBar} variant="h5">
            {"App Variables"}
          </Typography>

          <Grid container spacing={4} alignItems="center">
            {data &&
              Object.values(data).map((row: any) => (
                <Grid key={row.id + "Filter"} item xs={12} md={6} lg={4}>
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    item
                  >
                    <Typography variant="body1">{row.name}</Typography>
                    <div>
                      <IconButton
                        color="primary"
                        onClick={() => openModal("edit", row)}
                        disabled={!Array.isArray(row.values)}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </Grid>
                  <Select
                    fullWidth
                    displayEmpty
                    name={row.id}
                    value={""}
                    input={<OutlinedInput labelWidth={0} />}
                  >
                    <MenuItem value="">
                      <em>
                        <FormattedMessage id="exciseDutyView.transactionsView.filters.select" />
                      </em>
                    </MenuItem>
                    {Array.isArray(row.values) &&
                      row.values.map((value: any, index: any) => (
                        <MenuItem key={row.id + index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    {!Array.isArray(row.values) &&
                      Object.keys(row.values).map((value: any, index: any) => (
                        <MenuItem key={row.id + index} value={value}>
                          {value + " - " + row.values[value] + "%"}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              ))}
          </Grid>
        </CardContent>
      </Card>

      {selectedEntry && (
        <>
          <ModalEditAppVariable
            open={openModalId === "edit"}
            onSave={editAppVariables}
            initVariable={selectedEntry}
            onClose={closeModal}
          />
        </>
      )}
    </div>
  );
};

export default SettingView;
