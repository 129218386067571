import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

const getAppVariables = async () => {
  const token = getToken();

  let valid = false;
  let appVariablesResult

  if (token) {
    try {
      const response = await fetch(`${BASE_URL}/api/metadata/app_variables`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      appVariablesResult = await response.json();
      valid =
        appVariablesResult &&
        appVariablesResult.success &&
        appVariablesResult.app_variables;
    } catch (except) {
      valid = false;
    }
  }

  if (!valid) {
    if (appVariablesResult && appVariablesResult.errors) {
      return {
        valid: false,
        message: appVariablesResult.errors,
      };
    }
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    data: appVariablesResult.app_variables,
  };
};

export default getAppVariables;
