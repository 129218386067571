/**
 * Get all customerRequirements list
 */

import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

const getCustomerSoldProducts = async (customer_id: number) => {
  const token = getToken();
  let valid = false;
  let getResult;

  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/card_customers/${customer_id}/card_customer_sold_reports`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      getResult = await response.json();
      valid = getResult && getResult.success && getResult.card_customer_sold_reports;
    } catch (except) {
      valid = false; // network error
    }
  } else {
    return null; // No token or not admin
  }

  if (!valid) {
    if (getResult && getResult.errors) {
      return {
        valid: false,
        message: getResult.errors,
      };
    }
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  const data = Array.isArray(getResult.card_customer_sold_reports)
    ? getResult.card_customer_sold_reports
    : [];

  return {
    valid: true,
    data,
  };
};

export default getCustomerSoldProducts;
