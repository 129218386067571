/**
 * Customers table
 */

import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Link, useRouteMatch } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
// import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
// import ButtonRounded from "../../../../components/ButtonRounded";
import { FormattedMessage } from "react-intl";
import { setSearchTxt, getSearchTxt, clearSearchTxt } from "../../../../api/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(3.75),
      paddingRight: theme.spacing(3.75),
    },
    titleBar: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    inputSearch: {
      "& .MuiInput-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: "2px 8px",
        fontSize: "14px",
      },
    },
    table: {
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: "none",
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontSize: "inherit",
        fontWeight: "bold",
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(0),
      },
    },
    tableRow: {
      "& .MuiTableCell-root": {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
      },
    },
    iconDelete: {
      color: theme.palette.error.main,
    },
    customerLink: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
    searchButtonSet: {},
    addButton: {
      minWidth: "200px",
      lineHeight: "16px",
      marginLeft: "20px",
    },
  })
);

interface ICustomersTableProps {
  customerList: any;
  addCustomer: any;
  editCustomer: any;
  deleteCustomer: any;
  setSelectedCustomer: any;
}

const CustomersTable: React.FC<ICustomersTableProps> = ({
  customerList,
  addCustomer,
  editCustomer,
  deleteCustomer,
  setSelectedCustomer,
}) => {

  const [searchValue, SetSearchValue] = useState<string>("");

  const classes = useStyles();
  const match = useRouteMatch();

  const filteredCustomerList =
    searchValue.trim().length > 0
      ? customerList.filter(
          (c: any) =>
            String(c.name)
              .toLowerCase()
              .indexOf(searchValue.trim().toLowerCase()) > -1
        )
      : [...customerList];

  const searchDataChange = (e: any) => {
        SetSearchValue(e.target.value);
        setSearchTxt("susCustomer", e.target.value);
    };

  const clearSearch = () => {
        clearSearchTxt("susCustomer");
        SetSearchValue("");
    };
  useEffect(() => {
        const searchTxt = getSearchTxt("susCustomer");
        if (searchTxt) {
            SetSearchValue(searchTxt);
        } else {
            SetSearchValue("");
        }
    }, []);

  if (match) {
    return (
      <div>
        <Card>
          <CardContent className={classes.root}>
            {/* Top Search Bar */}
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={classes.titleBar}
            >
              <Grid item>
                <Typography variant="h5">
                  <FormattedMessage id="customersView.customerTable.heading" />
                </Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  className={classes.searchButtonSet}
                >
                  <TextField
                    placeholder="Search"
                    className={classes.inputSearch}
                    onChange={searchDataChange}
                    value={searchValue}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            { searchValue && <HighlightOffIcon onClick={clearSearch} />}
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <ButtonRounded
                    color="primary"
                    variant="contained"
                    onClick={addCustomer}
                    className={classes.addButton}
                  >
                    <FormattedMessage id="customersView.customerTable.addNew" />
                  </ButtonRounded> */}
                </Grid>
              </Grid>
            </Grid>

            {/* Files Table */}
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="customersView.customerTable.customerName" />
                  </TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(filteredCustomerList) &&
                  filteredCustomerList.length > 0 &&
                  filteredCustomerList.map((customer, idx) => (
                    <TableRow key={idx} className={classes.tableRow}>
                      <TableCell>
                        {/* Where user select customer and update redux customer_selected_user */}
                        <Link
                          className={classes.customerLink}
                          onClick={() => setSelectedCustomer(customer)}
                          to={`${match.url}/${customer.id}`}
                        >
                          {customer.name}
                        </Link>
                      </TableCell>

                      <TableCell>
                        {/* <Grid container justify="flex-end" alignItems="center">
                          <Grid item>
                            <IconButton
                              color="primary"
                              onClick={() => editCustomer(customer)}
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              className={classes.iconDelete}
                              onClick={() => deleteCustomer(customer)}
                            >
                              <DeleteOutlinedIcon fontSize="small" />
                            </IconButton>
                          </Grid>
                        </Grid> */}
                      </TableCell>
                    </TableRow>
                  ))}
                {Array.isArray(filteredCustomerList) &&
                  filteredCustomerList.length === 0 && (
                    <TableRow className={classes.tableRow}>
                      <TableCell>
                        <FormattedMessage id="customersView.customerTable.noCustomers" />
                      </TableCell>
                      <TableCell> </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    );
  } else {
    return null;
  }
};

export default CustomersTable;
