import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ButtonRounded from "../../../../components/ButtonRounded";
import RequiredAsterisk from "../../../../components/texts/RequiredAsterisk";
import ModalContainer from "../../../../components/ModalContainer";
import { postBiotemplateVolumeTransfer } from "../../../../api/sustainability/storage/outgoing-transaction/postBiotemplateReservations";
import KeyboardDatePicker from "../../../../components/inputs/KeyboardDatePicker";
import arrayMutators from "final-form-arrays";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import CreatableSelect from "react-select/creatable";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import getAppVariables from "../../../../api/settings/appVariables/get";
import ModalVolumeTransferPreview from "./ModalVolumeTransferPreview";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    btnContainer: {
      marginTop: theme.spacing(3),
    },
    marginBottom: {
      "margin-bottom": theme.spacing(2),
    },
    btnLeft: {
      marginRight: theme.spacing(3),
    },
    noteText: {
      color: theme.palette.error.main,
      fontWeight: 500,
    },
    commentTitle: {
      fontWeight: 500,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    errorMsg: {
      marginTop: theme.spacing(2),
    },
    fixedWidth: {
      width: theme.spacing(22),
    },
    creatableSelect: {
      ...theme.typography.body1,

      "& .react-select__control": {
        padding: 0,
        borderColor: theme.palette.grey[300],
        boxShadow: "none",
        minHeight: "35px",
        "&:hover": {
          borderColor: theme.palette.grey[300],
        },
      },
      "& .react-select__placeholder": {
        color: theme.palette.grey[300],
      },
      "& .react-select__value-container": {
        padding: "0px 8px",
      },
      "& .react-select__single-value": {
        color: theme.palette.primary.main,
      },
      "& .react-select__indicator-separator": {
        backgroundColor: "unset",
      },
      "& .react-select__indicators": {
        color: theme.palette.primary.main,
      },
      "& .react-select__option": {
        "&:active": {
          backgroundColor: "rgba(0, 0, 0, 0.14)",
        },
      },
      "& .react-select__option--is-focused": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "& .react-select__option--is-selected": {
        backgroundColor: "rgba(0, 0, 0, 0.14)",
        color: theme.palette.text.primary,
      },
    },
  })
);

interface ModalSaveVolumeTransferProps {
  open: boolean;
  onClose: () => void;
  data: any;
  generalData: {
    transfer_volume: any;
    biofuel_type: any;
    storage_terminal_in: any;
    storage_terminal_out: any;
    transfer_date: any;
    status: any;
  };
}

const ModalSaveVolumeTransfer: React.FC<ModalSaveVolumeTransferProps> = ({
  open,
  onClose,
  data,
  generalData,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [formData, setFormData] = useState<any[]>([]);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const classes = useStyles();
  const history = useHistory();

  const hasData: boolean = Array.isArray(data) && data.length > 0;

  const hasErrors = (error: any) => {
    if (error) {
      if (error.general_data && Array.isArray(error.general_data)) {
        return error.general_data.length > 0 ? true : false;
      }
    }
    return false;
  };

  const handleDataSubmit = async (values: any) => {
    const newFormData: any[] = [];
    newFormData.push({
      volume: generalData.transfer_volume,
      biofuel_type: generalData.biofuel_type,
      storage_terminal_in: generalData.storage_terminal_in,
      storage_terminal_out: generalData.storage_terminal_out,
      transfer_date: generalData.transfer_date,
      status: generalData.status,
      comment: values.general_data[0].comment,
      shipment_id: values.general_data[0].shipment_id,
      vessel: values.general_data[0].vessel,
      b_l_date: moment(values.general_data[0].b_l_date).format("YYYY-MM-DD"),
      biotemplates: data,
    });
    setFormData(newFormData);
    setShowSaveModal(true);
  };

  const closeSaveModal = () => {
    setShowSaveModal(false);
  };

  useEffect(() => {
    formData.push(
      {
        shipment_id: "",
        vessel: "",
        b_l_date: new Date(),
        comment: "",
      }
    );
  }, [generalData]);

  const required = (value: any) => {
    if (value && value !== "") {
      setIsError(false);
      return undefined;
    } else {
      setIsError(true);
      return "* Value Required";
    }
  };
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title="Save volume transfer"
      maxWidth="xs"
    >
      <div className={classes.modalContent}>
        <Typography variant="body1" paragraph className={classes.noteText}>
          Note: Saving this volume transfer will reset your mandate allocation (reporting and simulation)
        </Typography>
        <Typography variant="body1" paragraph>
          To save the volume transfer, specify data in the boxes below and describe the transfer in the comment section.
        </Typography>
        <Form
          onSubmit={handleDataSubmit}
          mutators={{ ...arrayMutators }}
          initialValues={{
            general_data: formData,
          }}
        >
          {({
            handleSubmit,
            submitting,
            pristine,
            errors,
            form: {
              mutators: { push },
            },
          }) => (
            <form onSubmit={handleSubmit}>
              <FieldArray name="general_data" >
                {({ fields }) =>
                  fields.map((name, index) => {
                    return (
                      <div>
                        <Typography variant="body1" className={classes.commentTitle}>
                          B/L Date <RequiredAsterisk />
                        </Typography>
                        <Field name={`${name}.b_l_date`} validate={required}>
                          {({ input, meta }) => (
                            <KeyboardDatePicker
                              {...input}
                              inputVariant="outlined"
                              format="YYYY-MM-DD"
                              fullWidth
                              placeholder={moment().format("YYYY-MM-DD")}
                              value={input.value ? input.value : null}
                              onChange={(value: any) => {
                                input.onChange(moment(new Date(value)).format("YYYY-MM-DD"));
                              }}
                            />
                          )}
                        </Field>
                        <Typography variant="body1" className={classes.commentTitle}>
                          Shipment ID <RequiredAsterisk />
                        </Typography>
                        <Field name={`${name}.shipment_id`} validate={required}>
                          {props => (
                            <FormattedMessage id="Shipment ID">
                              {fmsg => (
                                <TextField
                                  {...props.input}
                                  variant="outlined"
                                  placeholder={fmsg as string}
                                  fullWidth
                                />
                              )}
                            </FormattedMessage>
                          )}
                        </Field>
                        <Typography variant="body1" className={classes.commentTitle}>
                          Vessel <RequiredAsterisk />
                        </Typography>
                        <Field name={`${name}.vessel`} validate={required}>
                          {props => (
                            <FormattedMessage id="Vessel">
                              {fmsg => (
                                <TextField
                                  {...props.input}
                                  variant="outlined"
                                  placeholder={fmsg as string}
                                  fullWidth
                                />
                              )}
                            </FormattedMessage>
                          )}
                        </Field>
                        <Typography variant="body1" className={classes.commentTitle}>
                          Comment <RequiredAsterisk />
                        </Typography>
                        <Field name={`${name}.comment`} validate={required}>
                          {props => (
                            <FormattedMessage id="Comment">
                              {fmsg => (
                                <TextField
                                  {...props.input}
                                  variant="outlined"
                                  multiline
                                  placeholder={fmsg as string}
                                  fullWidth
                                  rows={4}
                                />
                              )}
                            </FormattedMessage>
                          )}
                        </Field>
                      </div>
                    );
                  }
                  )}
              </FieldArray>
              <Grid container spacing={2} className={classes.btnContainer}>
                <Grid item xs={6}>
                  <ButtonRounded
                    fullWidth
                    variant="contained"
                    onClick={onClose}
                    color="secondary"
                  >
                    {<FormattedMessage id="settingsView.modal.cancel" />}
                  </ButtonRounded>
                </Grid>
                <Grid item xs={6}>
                  <ButtonRounded
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={submitting || pristine || hasErrors(errors)}
                  >
                    {<FormattedMessage id="settingsView.modal.save" />}
                  </ButtonRounded>
                </Grid>
              </Grid>
            </form>
          )}
        </Form>

        {isError && (
          <Typography
            align="center"
            variant="body2"
            color="error"
            className={classes.errorMsg}>
            Please Filled all required field.
          </Typography>
        )}
        {!hasData && (
          <Typography
            align="center"
            variant="body2"
            color="error"
            className={classes.errorMsg}
          >
            Allocated quantity in the table can not be blank.
          </Typography>
        )}
      </div>

      {<ModalVolumeTransferPreview
        data={formData}
        title={"Volume Transfer Preview Table"}
        handleSubmit={() => { onClose(); }}
        open={showSaveModal}
        onClose={closeSaveModal}
      />}

    </ModalContainer>
  );
};

export default ModalSaveVolumeTransfer;
