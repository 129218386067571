import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../../reducers/reducer";
import ReportsView from "../../views/DashboardView/CardView/ReportsView";
import { toggleSimulation } from "../../reducers/card/main";
import { getPageData } from "../../reducers/card/reports";
import { setSelectedYear } from "../../reducers/exciseDuty/taxPeriods";
interface Props {
  activePeriod: any;
  isSimulation: boolean;
  isJoint: boolean;
  selectedYear: number;
  setSelectedYear: any;
  toggleSimulation: any;
  data: any;
  getPageData: any;
  isFetching: boolean;
  taxPeriods: any[];
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      activePeriod: state.card.main.activePeriod,
      isSimulation: state.card.main.isSimulation,
      isJoint: state.card.main.isJoint,
      data: state.card.reports.data,
      isFetching: state.card.reports.isFetching,
      taxPeriods: state.exciseDuty.taxPeriods.data,
      selectedYear: state.exciseDuty.taxPeriods.selectedYear,
    }),
    (dispatch: any) => ({
      dispatch,
      toggleSimulation: () => dispatch(toggleSimulation()),
      getPageData: (taxYear: any) => dispatch(getPageData(taxYear)),
      setSelectedYear: (year: number) => dispatch(setSelectedYear(year)),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {
      // this.props.getPageData(this.props.selectedYear);
    },
  })
)(ReportsView);
