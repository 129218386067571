import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableContainer: {
            overflowY: "scroll",
            padding: "0 24px 50px",
            maxHeight: "200px",
        },
        table: {
            border: `1px solid ${theme.palette.grey[300]}`,
            // "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
            //   backgroundColor: theme.palette.grey[50],
            // },
            "& .MuiTableCell-root": {
                border: `1px solid ${theme.palette.grey[300]}`,
                padding: theme.spacing(1),
                ...theme.typography.body2,
            },
            "& .MuiTableCell-head": {
                color: "inherit",
                fontWeight: 500,
            },
        },
        bold: {
            fontWeight: "bold",
        },
        green: {
            backgroundColor: "#cce6d8",
        },
        darkGreen: {
            backgroundColor: "#8fcc60",
        },
        red: {
            backgroundColor: "#ffdad1",
        },
        yellow: {
            backgroundColor: "#ffd588",
        },
        white: {
            backgroundColor: "#ffffff",
        },
    })
);

const tableHeadings = [
    "Fulfillment status",
    "Requirement",
    "Required volume",
    "Joint fulfillment",
    "Allocated (LE)",
    "Allocated (L15)",
    "Surplus from off-road",
    "Remaining volume",
];

// const tableRows = new Array(10).fill(tableRow);

interface Props {
    requirement_quantities: any;
}

interface OverViewParamProps {
    etanol: number;
    etanol_advanced: number;
    fame: number;
    fame_advanced: number;
    hvo_hro: number;
    hvo_hro_advanced: number;
    nafta: number;
    nafta_advanced: number;
    biogasoline: number;
    biogasoline_advanced: number;
    general_mandate: number;
    gasoline_sub_mandate: number;
    advanced_sub_mandate: number;
    above_mandate_req_by_cust: number;
    above_mandate_after_fulfill: number;
}
interface OverViewParamProps {
    etanol: number;
    etanol_advanced: number;
    fame: number;
    fame_advanced: number;
    hvo_hro: number;
    hvo_hro_advanced: number;
    nafta: number;
    nafta_advanced: number;
    biogasoline: number;
    biogasoline_advanced: number;
    general_mandate: number;
    gasoline_sub_mandate: number;
    advanced_sub_mandate: number;
    above_mandate_req_by_cust: number;
    above_mandate_after_fulfill: number;
}
interface OverViewReqVolParamProps {
    general_mandate: number;
    general_req_vol: number;
    general_rel_vol: number;
    advanced_sub_mandate: number;
    advanced_sub_req_vol: number;
    advanced_sub_rel_vol: number;
    gasoline_sub_mandate: number;
    gasoline_req_vol: number;
    gasoline_rel_vol: number;
    above_mandate_req_by_cust: number;
    etanol: number;
    etanol_advanced: number;
    nafta: number;
    nafta_advanced: number;
    bio_gasoline: number;
    bio_gasoline_advanced: number;
    fame: number;
    fame_advanced: number;
    hvo_hro: number;
    hvo_hro_advanced: number;
    above_mandate_after_fulfill: number;
}

interface OverViewProps {
    req_vol: OverViewReqVolParamProps;
    joint_fulfill: OverViewParamProps;
    allocated_LE: OverViewParamProps;
    allocated_15: OverViewParamProps;
    surplus_from_offroad: OverViewParamProps;
    remaining_vol: OverViewParamProps;
}

interface OverviewTableProps {
    step: number;
    data: OverViewProps;
}

const OverviewTable: React.FC<OverviewTableProps> = ({
                                                         step,
                                                         data
                                                     }) => {
    const classes = useStyles();

    /*  const parseVal = (value: number) => {
      let parsed = value;
      if (parsed) {
        if (value > -1 && value < 0) parsed *= -1;
        return parseData(parsed, 2);
      } else return parsed;
    }; */

    const getStatus = (remaining: any) => {
        if (remaining > 0) { return "Not Complete"; }
        if (remaining < 0) { return "Overallocated"; }
        return "Complete";
    };

    // @ts-ignore
    const getResult = (reqVol: any, allocatedLE: any, remainingVol: any) => {
        return ((parseFloat(reqVol.toString()) + parseFloat(allocatedLE.toString()) === 0) || reqVol.toString() === "?")
            ? "Not relevant"
            : parseFloat(remainingVol.toString()) > 0
                ? "NOT fulfilled"
                : parseFloat(remainingVol.toString()) < 0
                    ? "Over-allocated"
                    : "Mandate fulfilled";
    };
    const setColor = (reqVol: any, allocatedLE: any, remainingVol: any) => {
        return  ((parseFloat(reqVol.toString()) + parseFloat(allocatedLE.toString()) === 0) || reqVol.toString() === "?")
            ? classes.red
            : parseFloat(remainingVol.toString()) > 0
                ? classes.red
                : parseFloat(remainingVol.toString()) < 0
                    ? classes.yellow
                    : classes.darkGreen;
    };

    const setFuelColor = (reqVol: any, allocatedLE: any, remainingVol: any) => {
        return parseFloat(reqVol.toString()) + parseFloat(allocatedLE.toString()) === 0
            ? classes.white
            : parseFloat(remainingVol.toString()) > 0
                ? classes.red
                : parseFloat(remainingVol.toString()) < 0
                    ? classes.yellow
                    : classes.green;
    };
    // @ts-ignore
    const getFuelTypeResult = (reqVol: any, allocatedLE: any, remainingVol: any) => {
        return parseFloat(reqVol.toString()) + parseFloat(allocatedLE.toString()) === 0
            ? "Not relevant"
            : parseFloat(remainingVol.toString()) > 0
                ? "Sold vol. to be allocated"
                : parseFloat(remainingVol.toString()) < 0
                    ? "Sold vol. over-allocated"
                    : "Completed";
    };

    // @ts-ignore
    return (
        <div className={classes.tableContainer}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        {tableHeadings.map((heading, hidx) => (
                            <TableCell key={hidx}>{heading}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {step > 0 && (
                        <TableRow
                            style={{
                                border: step === 1 ? "4px solid" : "1px solid",
                            }}
                            className={setColor(
                                data.req_vol.gasoline_sub_mandate,
                                data.allocated_LE.gasoline_sub_mandate,
                                data.remaining_vol.gasoline_sub_mandate
                            )}
                        >
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "white",
                            }}>{getResult(
                                data.req_vol.gasoline_sub_mandate,
                                data.allocated_LE.gasoline_sub_mandate,
                                data.remaining_vol.gasoline_sub_mandate
                            )}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "white",
                            }}>Gasoline sub-mandate ({data.req_vol.gasoline_sub_mandate} %)</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "white",
                            }}>{data.req_vol.gasoline_req_vol}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "white",
                            }}>{data.joint_fulfill.gasoline_sub_mandate}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "white",
                            }}>{data.allocated_LE.gasoline_sub_mandate}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "white",
                            }}>{data.allocated_15.gasoline_sub_mandate}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "white",
                            }}>{data.surplus_from_offroad.gasoline_sub_mandate}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 1 ? "bold" : "normal",
                                color: step === 1 ? "black" : "white",
                            }}>{data.remaining_vol.gasoline_sub_mandate}</TableCell>
                        </TableRow>
                    )}
                    {step > 1 && (
                        <TableRow
                            style={{
                                border: step === 2 ? "4px solid" : "1px solid",
                            }}
                            className={setColor(
                                data.req_vol.advanced_sub_req_vol,
                                data.allocated_LE.advanced_sub_mandate,
                                data.remaining_vol.advanced_sub_mandate
                            )}
                        >
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "white",
                            }}>{getResult(
                                data.req_vol.advanced_sub_req_vol,
                                data.allocated_LE.advanced_sub_mandate,
                                data.remaining_vol.advanced_sub_mandate
                            )}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "white",
                            }}>Advanced sub-mandate ({data.req_vol.advanced_sub_mandate} %)</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "white",
                            }}>{data.req_vol.advanced_sub_req_vol}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "white",
                            }}>{data.joint_fulfill.advanced_sub_mandate}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "white",
                            }}>{data.allocated_LE.advanced_sub_mandate}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "white",
                            }}>{data.allocated_15.advanced_sub_mandate}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "white",
                            }}>{data.surplus_from_offroad.advanced_sub_mandate}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 2 ? "bold" : "normal",
                                color: step === 2 ? "black" : "white",
                            }}>{data.remaining_vol.advanced_sub_mandate}</TableCell>
                        </TableRow>
                    )}
                    {step > 2 && (
                        <TableRow
                            style={{
                                border: step === 3 ? "4px solid" : "1px solid",
                            }}
                            className={setColor(
                                data.req_vol.general_req_vol,
                                data.allocated_LE.general_mandate,
                                data.remaining_vol.general_mandate
                            )}
                        >
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "white",
                            }}>{getResult(
                                data.req_vol.general_req_vol,
                                data.allocated_LE.general_mandate,
                                data.remaining_vol.general_mandate
                            )}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "white",
                            }}>General mandate ({data.req_vol.general_mandate}%)</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "white",
                            }}>{data.req_vol.general_req_vol}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "white",
                            }}>{data.joint_fulfill.general_mandate}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "white",
                            }}>{data.allocated_LE.general_mandate}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "white",
                            }}>{data.allocated_15.general_mandate}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "white",
                            }}>{data.surplus_from_offroad.general_mandate}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 3 ? "bold" : "normal",
                                color: step === 3 ? "black" : "white",
                            }}>{data.remaining_vol.general_mandate}</TableCell>
                        </TableRow>
                    )}
                    {step > 3 && (
                        <TableRow
                            style={{
                                border: step === 4 ? "4px solid" : "1px solid",
                            }}
                            className={setColor(
                                data.req_vol.above_mandate_req_by_cust,
                                data.allocated_LE.above_mandate_req_by_cust,
                                data.remaining_vol.above_mandate_req_by_cust
                            )}
                        >
                            <TableCell style={{
                                fontWeight: step === 4 ? "bold" : "normal",
                                color: step === 4 ? "black" : "white",
                            }}>{getResult(
                                data.req_vol.above_mandate_req_by_cust,
                                data.allocated_LE.above_mandate_req_by_cust,
                                data.remaining_vol.above_mandate_req_by_cust
                            )}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 4 ? "bold" : "normal",
                                color: step === 4 ? "black" : "white",
                            }}>Above the mandate (required by customers)</TableCell>
                            <TableCell style={{
                                fontWeight: step === 4 ? "bold" : "normal",
                                color: step === 4 ? "black" : "white",
                            }}>{data.req_vol.above_mandate_req_by_cust}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 4 ? "bold" : "normal",
                                color: step === 4 ? "black" : "white",
                            }}>{data.joint_fulfill.above_mandate_req_by_cust}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 4 ? "bold" : "normal",
                                color: step === 4 ? "black" : "white",
                            }}>{data.allocated_LE.above_mandate_req_by_cust}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 4 ? "bold" : "normal",
                                color: step === 4 ? "black" : "white",
                            }}>{data.allocated_15.above_mandate_req_by_cust}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 4 ? "bold" : "normal",
                                color: step === 4 ? "black" : "white",
                            }}>{data.surplus_from_offroad.above_mandate_req_by_cust}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 4 ? "bold" : "normal",
                                color: step === 4 ? "black" : "white",
                            }}>{data.remaining_vol.above_mandate_req_by_cust}</TableCell>
                        </TableRow>
                    )}
                    {step > 4 && (
                        <TableRow
                            style={{
                                border: step === 5 ? "4px solid" : "1px solid",
                            }}
                            className={setColor(
                                data.req_vol.above_mandate_after_fulfill,
                                data.allocated_LE.above_mandate_after_fulfill,
                                data.remaining_vol.above_mandate_after_fulfill
                            )}
                        >
                            <TableCell style={{
                                fontWeight: step === 5 ? "bold" : "normal",
                                color: step === 5 ? "black" : "white",
                            }}>{getResult(
                                data.req_vol.above_mandate_after_fulfill,
                                data.allocated_LE.above_mandate_after_fulfill,
                                data.remaining_vol.above_mandate_after_fulfill
                            )}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 5 ? "bold" : "normal",
                                color: step === 5 ? "black" : "white",
                            }}>Above the mandate (after mandate fulfillment)</TableCell>
                            <TableCell style={{
                                fontWeight: step === 5 ? "bold" : "normal",
                                color: step === 5 ? "black" : "white",
                            }}>{data.req_vol.above_mandate_after_fulfill}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 5 ? "bold" : "normal",
                                color: step === 5 ? "black" : "white",
                            }}>{data.joint_fulfill.above_mandate_after_fulfill}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 5 ? "bold" : "normal",
                                color: step === 5 ? "black" : "white",
                            }}>{data.allocated_LE.above_mandate_after_fulfill}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 5 ? "bold" : "normal",
                                color: step === 5 ? "black" : "white",
                            }}>{data.allocated_15.above_mandate_after_fulfill}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 5 ? "bold" : "normal",
                                color: step === 5 ? "black" : "white",
                            }}>{data.surplus_from_offroad.above_mandate_after_fulfill}</TableCell>
                            <TableCell style={{
                                fontWeight: step === 5 ? "bold" : "normal",
                                color: step === 5 ? "black" : "white",
                            }}>{data.remaining_vol.above_mandate_after_fulfill}</TableCell>
                        </TableRow>
                    )}
                    {step !== 2 && (
                        <TableRow
                            className={setFuelColor(
                                data.req_vol.etanol,
                                data.allocated_LE.etanol,
                                data.remaining_vol.etanol
                            )}
                        >
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.etanol,
                                    data.allocated_LE.etanol,
                                    data.remaining_vol.etanol
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{getFuelTypeResult(
                                data.req_vol.etanol,
                                data.allocated_LE.etanol,
                                data.remaining_vol.etanol
                            )}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.etanol,
                                    data.allocated_LE.etanol,
                                    data.remaining_vol.etanol
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>Etanol</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.etanol,
                                    data.allocated_LE.etanol,
                                    data.remaining_vol.etanol
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.req_vol.etanol}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.etanol,
                                    data.allocated_LE.etanol,
                                    data.remaining_vol.etanol
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.joint_fulfill.etanol}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.etanol,
                                    data.allocated_LE.etanol,
                                    data.remaining_vol.etanol
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.allocated_LE.etanol}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.etanol,
                                    data.allocated_LE.etanol,
                                    data.remaining_vol.etanol
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.allocated_15.etanol}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.etanol,
                                    data.allocated_LE.etanol,
                                    data.remaining_vol.etanol
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.surplus_from_offroad.etanol}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.etanol,
                                    data.allocated_LE.etanol,
                                    data.remaining_vol.etanol
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.remaining_vol.etanol}</TableCell>
                        </TableRow>
                    )}
                    <TableRow
                        className={setFuelColor(
                            data.req_vol.etanol_advanced,
                            data.allocated_LE.etanol_advanced,
                            data.remaining_vol.etanol_advanced
                        )}
                    >
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.etanol_advanced,
                                data.allocated_LE.etanol_advanced,
                                data.remaining_vol.etanol_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{getFuelTypeResult(
                            data.req_vol.etanol_advanced,
                            data.allocated_LE.etanol_advanced,
                            data.remaining_vol.etanol_advanced
                        )}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.etanol_advanced,
                                data.allocated_LE.etanol_advanced,
                                data.remaining_vol.etanol_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>Etanol (advanced)</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.etanol_advanced,
                                data.allocated_LE.etanol_advanced,
                                data.remaining_vol.etanol_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.req_vol.etanol_advanced}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.etanol_advanced,
                                data.allocated_LE.etanol_advanced,
                                data.remaining_vol.etanol_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.joint_fulfill.etanol_advanced}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.etanol_advanced,
                                data.allocated_LE.etanol_advanced,
                                data.remaining_vol.etanol_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.allocated_LE.etanol_advanced}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.etanol_advanced,
                                data.allocated_LE.etanol_advanced,
                                data.remaining_vol.etanol_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.allocated_15.etanol_advanced}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.etanol_advanced,
                                data.allocated_LE.etanol_advanced,
                                data.remaining_vol.etanol_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.surplus_from_offroad.etanol_advanced}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.etanol_advanced,
                                data.allocated_LE.etanol_advanced,
                                data.remaining_vol.etanol_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.remaining_vol.etanol_advanced}</TableCell>
                    </TableRow>
                    {step !== 2 && (
                        <TableRow
                            className={setFuelColor(
                                data.req_vol.nafta,
                                data.allocated_LE.nafta,
                                data.remaining_vol.nafta
                            )}
                        >
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.nafta,
                                    data.allocated_LE.nafta,
                                    data.remaining_vol.nafta
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{getFuelTypeResult(
                                data.req_vol.nafta,
                                data.allocated_LE.nafta,
                                data.remaining_vol.nafta
                            )}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.nafta,
                                    data.allocated_LE.nafta,
                                    data.remaining_vol.nafta
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>Nafta</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.nafta,
                                    data.allocated_LE.nafta,
                                    data.remaining_vol.nafta
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.req_vol.nafta}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.nafta,
                                    data.allocated_LE.nafta,
                                    data.remaining_vol.nafta
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.joint_fulfill.nafta}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.nafta,
                                    data.allocated_LE.nafta,
                                    data.remaining_vol.nafta
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.allocated_LE.nafta}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.nafta,
                                    data.allocated_LE.nafta,
                                    data.remaining_vol.nafta
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.allocated_15.nafta}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.nafta,
                                    data.allocated_LE.nafta,
                                    data.remaining_vol.nafta
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.surplus_from_offroad.nafta}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.nafta,
                                    data.allocated_LE.nafta,
                                    data.remaining_vol.nafta
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.remaining_vol.nafta}</TableCell>
                        </TableRow>
                    )}
                    <TableRow
                        className={setFuelColor(
                            data.req_vol.nafta_advanced,
                            data.allocated_LE.nafta_advanced,
                            data.remaining_vol.nafta_advanced
                        )}
                    >
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.nafta_advanced,
                                data.allocated_LE.nafta_advanced,
                                data.remaining_vol.nafta_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{getFuelTypeResult(
                            data.req_vol.nafta_advanced,
                            data.allocated_LE.nafta_advanced,
                            data.remaining_vol.nafta_advanced
                        )}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.nafta_advanced,
                                data.allocated_LE.nafta_advanced,
                                data.remaining_vol.nafta_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>Nafta (advanced)</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.nafta_advanced,
                                data.allocated_LE.nafta_advanced,
                                data.remaining_vol.nafta_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.req_vol.nafta_advanced}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.nafta_advanced,
                                data.allocated_LE.nafta_advanced,
                                data.remaining_vol.nafta_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.joint_fulfill.nafta_advanced}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.nafta_advanced,
                                data.allocated_LE.nafta_advanced,
                                data.remaining_vol.nafta_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.allocated_LE.nafta_advanced}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.nafta_advanced,
                                data.allocated_LE.nafta_advanced,
                                data.remaining_vol.nafta_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.allocated_15.nafta_advanced}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.nafta_advanced,
                                data.allocated_LE.nafta_advanced,
                                data.remaining_vol.nafta_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.surplus_from_offroad.nafta_advanced}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.nafta_advanced,
                                data.allocated_LE.nafta_advanced,
                                data.remaining_vol.nafta_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.remaining_vol.nafta_advanced}</TableCell>
                    </TableRow>
                    {step !== 2 && (
                        <TableRow
                            className={setFuelColor(
                                data.req_vol.bio_gasoline,
                                data.allocated_LE.biogasoline,
                                data.remaining_vol.biogasoline
                            )}
                        >
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.bio_gasoline,
                                    data.allocated_LE.biogasoline,
                                    data.remaining_vol.biogasoline
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{getFuelTypeResult(
                                data.req_vol.bio_gasoline,
                                data.allocated_LE.biogasoline,
                                data.remaining_vol.biogasoline
                            )}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.bio_gasoline,
                                    data.allocated_LE.biogasoline,
                                    data.remaining_vol.biogasoline
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>Bio gasoline</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.bio_gasoline,
                                    data.allocated_LE.biogasoline,
                                    data.remaining_vol.biogasoline
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.req_vol.bio_gasoline}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.bio_gasoline,
                                    data.allocated_LE.biogasoline,
                                    data.remaining_vol.biogasoline
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.joint_fulfill.biogasoline}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.bio_gasoline,
                                    data.allocated_LE.biogasoline,
                                    data.remaining_vol.biogasoline
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.allocated_LE.biogasoline}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.bio_gasoline,
                                    data.allocated_LE.biogasoline,
                                    data.remaining_vol.biogasoline
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.allocated_15.biogasoline}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.bio_gasoline,
                                    data.allocated_LE.biogasoline,
                                    data.remaining_vol.biogasoline
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.surplus_from_offroad.biogasoline}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.bio_gasoline,
                                    data.allocated_LE.biogasoline,
                                    data.remaining_vol.biogasoline
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.remaining_vol.biogasoline}</TableCell>
                        </TableRow>
                    )}
                    <TableRow
                        className={setFuelColor(
                            data.req_vol.bio_gasoline_advanced,
                            data.allocated_LE.biogasoline_advanced,
                            data.remaining_vol.biogasoline_advanced
                        )}
                    >
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.bio_gasoline_advanced,
                                data.allocated_LE.biogasoline_advanced,
                                data.remaining_vol.biogasoline_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{getFuelTypeResult(
                            data.req_vol.bio_gasoline_advanced,
                            data.allocated_LE.biogasoline_advanced,
                            data.remaining_vol.biogasoline_advanced
                        )}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.bio_gasoline_advanced,
                                data.allocated_LE.biogasoline_advanced,
                                data.remaining_vol.biogasoline_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>Bio gasoline (advanced)</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.bio_gasoline_advanced,
                                data.allocated_LE.biogasoline_advanced,
                                data.remaining_vol.biogasoline_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.req_vol.bio_gasoline_advanced}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.bio_gasoline_advanced,
                                data.allocated_LE.biogasoline_advanced,
                                data.remaining_vol.biogasoline_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.joint_fulfill.biogasoline_advanced}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.bio_gasoline_advanced,
                                data.allocated_LE.biogasoline_advanced,
                                data.remaining_vol.biogasoline_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.allocated_LE.biogasoline_advanced}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.bio_gasoline_advanced,
                                data.allocated_LE.biogasoline_advanced,
                                data.remaining_vol.biogasoline_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.allocated_15.biogasoline_advanced}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.bio_gasoline_advanced,
                                data.allocated_LE.biogasoline_advanced,
                                data.remaining_vol.biogasoline_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.surplus_from_offroad.biogasoline_advanced}</TableCell>
                        <TableCell style={{
                            color: getFuelTypeResult(
                                data.req_vol.bio_gasoline_advanced,
                                data.allocated_LE.biogasoline_advanced,
                                data.remaining_vol.biogasoline_advanced
                            ) !== "Not relevant" ? "black" : "grey",
                        }}>{data.remaining_vol.biogasoline_advanced}</TableCell>
                    </TableRow>
                    {step !== 1 && step !== 2 && (
                        <TableRow
                            className={setFuelColor(
                                data.req_vol.fame,
                                data.allocated_LE.fame,
                                data.remaining_vol.fame
                            )}
                        >
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.fame,
                                    data.allocated_LE.fame,
                                    data.remaining_vol.fame
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{getFuelTypeResult(
                                data.req_vol.fame,
                                data.allocated_LE.fame,
                                data.remaining_vol.fame
                            )}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.fame,
                                    data.allocated_LE.fame,
                                    data.remaining_vol.fame
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>FAME</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.fame,
                                    data.allocated_LE.fame,
                                    data.remaining_vol.fame
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.req_vol.fame}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.fame,
                                    data.allocated_LE.fame,
                                    data.remaining_vol.fame
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.joint_fulfill.fame}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.fame,
                                    data.allocated_LE.fame,
                                    data.remaining_vol.fame
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.allocated_LE.fame}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.fame,
                                    data.allocated_LE.fame,
                                    data.remaining_vol.fame
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.allocated_15.fame}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.fame,
                                    data.allocated_LE.fame,
                                    data.remaining_vol.fame
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.surplus_from_offroad.fame}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.fame,
                                    data.allocated_LE.fame,
                                    data.remaining_vol.fame
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.remaining_vol.fame}</TableCell>
                        </TableRow>
                    )}
                    {step !== 1 && (
                        <TableRow
                            className={setFuelColor(
                                data.req_vol.fame_advanced,
                                data.allocated_LE.fame_advanced,
                                data.remaining_vol.fame_advanced
                            )}
                        >
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.fame_advanced,
                                    data.allocated_LE.fame_advanced,
                                    data.remaining_vol.fame_advanced
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{getFuelTypeResult(
                                data.req_vol.fame_advanced,
                                data.allocated_LE.fame_advanced,
                                data.remaining_vol.fame_advanced
                            )}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.fame_advanced,
                                    data.allocated_LE.fame_advanced,
                                    data.remaining_vol.fame_advanced
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>FAME (advanced)</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.fame_advanced,
                                    data.allocated_LE.fame_advanced,
                                    data.remaining_vol.fame_advanced
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.req_vol.fame_advanced}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.fame_advanced,
                                    data.allocated_LE.fame_advanced,
                                    data.remaining_vol.fame_advanced
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.joint_fulfill.fame_advanced}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.fame_advanced,
                                    data.allocated_LE.fame_advanced,
                                    data.remaining_vol.fame_advanced
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.allocated_LE.fame_advanced}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.fame_advanced,
                                    data.allocated_LE.fame_advanced,
                                    data.remaining_vol.fame_advanced
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.allocated_15.fame_advanced}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.fame_advanced,
                                    data.allocated_LE.fame_advanced,
                                    data.remaining_vol.fame_advanced
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.surplus_from_offroad.fame_advanced}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.fame_advanced,
                                    data.allocated_LE.fame_advanced,
                                    data.remaining_vol.fame_advanced
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.remaining_vol.fame_advanced}</TableCell>
                        </TableRow>
                    )}
                    {step !== 1 && step !== 2 && (
                        <TableRow
                            className={setFuelColor(
                                data.req_vol.hvo_hro,
                                data.allocated_LE.hvo_hro,
                                data.remaining_vol.hvo_hro
                            )}
                        >
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.hvo_hro,
                                    data.allocated_LE.hvo_hro,
                                    data.remaining_vol.hvo_hro
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{getFuelTypeResult(
                                data.req_vol.hvo_hro,
                                data.allocated_LE.hvo_hro,
                                data.remaining_vol.hvo_hro
                            )}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.hvo_hro,
                                    data.allocated_LE.hvo_hro,
                                    data.remaining_vol.hvo_hro
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>HVO/HRO</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.hvo_hro,
                                    data.allocated_LE.hvo_hro,
                                    data.remaining_vol.hvo_hro
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.req_vol.hvo_hro}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.hvo_hro,
                                    data.allocated_LE.hvo_hro,
                                    data.remaining_vol.hvo_hro
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.joint_fulfill.hvo_hro}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.hvo_hro,
                                    data.allocated_LE.hvo_hro,
                                    data.remaining_vol.hvo_hro
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.allocated_LE.hvo_hro}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.hvo_hro,
                                    data.allocated_LE.hvo_hro,
                                    data.remaining_vol.hvo_hro
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.allocated_15.hvo_hro}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.hvo_hro,
                                    data.allocated_LE.hvo_hro,
                                    data.remaining_vol.hvo_hro
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.surplus_from_offroad.hvo_hro}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.hvo_hro,
                                    data.allocated_LE.hvo_hro,
                                    data.remaining_vol.hvo_hro
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.remaining_vol.hvo_hro}</TableCell>
                        </TableRow>
                    )}
                    {step !== 1 && (
                        <TableRow
                            className={setFuelColor(
                                data.req_vol.hvo_hro_advanced,
                                data.allocated_LE.hvo_hro_advanced,
                                data.remaining_vol.hvo_hro_advanced
                            )}
                        >
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.hvo_hro_advanced,
                                    data.allocated_LE.hvo_hro_advanced,
                                    data.remaining_vol.hvo_hro_advanced
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{getFuelTypeResult(
                                data.req_vol.hvo_hro_advanced,
                                data.allocated_LE.hvo_hro_advanced,
                                data.remaining_vol.hvo_hro_advanced
                            )}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.hvo_hro_advanced,
                                    data.allocated_LE.hvo_hro_advanced,
                                    data.remaining_vol.hvo_hro_advanced
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>HVO/HRO (advanced)</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.hvo_hro_advanced,
                                    data.allocated_LE.hvo_hro_advanced,
                                    data.remaining_vol.hvo_hro_advanced
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.req_vol.hvo_hro_advanced}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.hvo_hro_advanced,
                                    data.allocated_LE.hvo_hro_advanced,
                                    data.remaining_vol.hvo_hro_advanced
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.joint_fulfill.hvo_hro_advanced}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.hvo_hro_advanced,
                                    data.allocated_LE.hvo_hro_advanced,
                                    data.remaining_vol.hvo_hro_advanced
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.allocated_LE.hvo_hro_advanced}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.hvo_hro_advanced,
                                    data.allocated_LE.hvo_hro_advanced,
                                    data.remaining_vol.hvo_hro_advanced
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.allocated_15.hvo_hro_advanced}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.hvo_hro_advanced,
                                    data.allocated_LE.hvo_hro_advanced,
                                    data.remaining_vol.hvo_hro_advanced
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.surplus_from_offroad.hvo_hro_advanced}</TableCell>
                            <TableCell style={{
                                color: getFuelTypeResult(
                                    data.req_vol.hvo_hro_advanced,
                                    data.allocated_LE.hvo_hro_advanced,
                                    data.remaining_vol.hvo_hro_advanced
                                ) !== "Not relevant" ? "black" : "grey",
                            }}>{data.remaining_vol.hvo_hro_advanced}</TableCell>
                        </TableRow>
                    )}
                    {step < 1 && (
                        <TableRow
                            style={{
                                borderTop: step === 0 ? "4px solid" : "1px solid",
                                color: "grey !important",
                            }}
                            className={setColor(
                                data.req_vol.gasoline_req_vol,
                                data.allocated_LE.gasoline_sub_mandate,
                                data.remaining_vol.gasoline_sub_mandate
                            )}
                        >
                            <TableCell style={{ color: "grey", }}>{getResult(
                                data.req_vol.gasoline_req_vol,
                                data.allocated_LE.gasoline_sub_mandate,
                                data.remaining_vol.gasoline_sub_mandate
                            )}</TableCell>
                            <TableCell style={{ color: "grey", }}>Gasoline sub-mandate ({data.req_vol.gasoline_sub_mandate} %)</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.req_vol.gasoline_req_vol}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.joint_fulfill.gasoline_sub_mandate}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.allocated_LE.gasoline_sub_mandate}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.allocated_15.gasoline_sub_mandate}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.surplus_from_offroad.gasoline_sub_mandate}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.remaining_vol.gasoline_sub_mandate}</TableCell>
                        </TableRow>
                    )}
                    {step < 2 && (
                        <TableRow
                            style={{
                                borderTop: step === 1 ? "4px solid" : "1px solid",
                                color: "grey !important",
                            }}
                            className={setColor(
                                data.req_vol.advanced_sub_req_vol,
                                data.allocated_LE.advanced_sub_mandate,
                                data.remaining_vol.advanced_sub_mandate
                            )}
                        >
                            <TableCell style={{ color: "grey", }}>{getResult(
                                data.req_vol.advanced_sub_req_vol,
                                data.allocated_LE.advanced_sub_mandate,
                                data.remaining_vol.advanced_sub_mandate
                            )}</TableCell>
                            <TableCell style={{ color: "grey", }}>Advanced sub-mandate ({data.req_vol.advanced_sub_mandate} %)</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.req_vol.advanced_sub_req_vol}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.joint_fulfill.advanced_sub_mandate}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.allocated_LE.advanced_sub_mandate}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.allocated_15.advanced_sub_mandate}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.surplus_from_offroad.advanced_sub_mandate}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.remaining_vol.advanced_sub_mandate}</TableCell>
                        </TableRow>
                    )}
                    {step < 3 && (
                        <TableRow
                            style={{
                                borderTop: step === 2 ? "4px solid" : "1px solid",
                                color: "grey",
                            }}
                            className={setColor(
                                data.req_vol.general_req_vol,
                                data.allocated_LE.general_mandate,
                                data.remaining_vol.general_mandate
                            )}
                        >
                            <TableCell style={{ color: "grey", }}>{getResult(
                                data.req_vol.general_req_vol,
                                data.allocated_LE.general_mandate,
                                data.remaining_vol.general_mandate
                            )}</TableCell>
                            <TableCell style={{ color: "grey", }}>General mandate ({data.req_vol.general_mandate} %)</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.req_vol.general_req_vol}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.joint_fulfill.general_mandate}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.allocated_LE.general_mandate}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.allocated_15.general_mandate}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.surplus_from_offroad.general_mandate}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.remaining_vol.general_mandate}</TableCell>
                        </TableRow>
                    )}
                    {step < 4 && (
                        <TableRow
                            style={{
                                borderTop: step === 3 ? "4px solid" : "1px solid",
                                color: "grey !important",
                            }}
                            className={setColor(
                                data.req_vol.above_mandate_req_by_cust,
                                data.allocated_LE.above_mandate_req_by_cust,
                                data.remaining_vol.above_mandate_req_by_cust
                            )}
                        >
                            <TableCell style={{ color: "grey", }}>{getResult(
                                data.req_vol.above_mandate_req_by_cust,
                                data.allocated_LE.above_mandate_req_by_cust,
                                data.remaining_vol.above_mandate_req_by_cust
                            )}</TableCell>
                            <TableCell style={{ color: "grey", }}>Above the mandate (required by customers)</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.req_vol.above_mandate_req_by_cust}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.joint_fulfill.above_mandate_req_by_cust}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.allocated_LE.above_mandate_req_by_cust}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.allocated_15.above_mandate_req_by_cust}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.surplus_from_offroad.above_mandate_req_by_cust}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.remaining_vol.above_mandate_req_by_cust}</TableCell>
                        </TableRow>
                    )}
                    {step < 5 && (
                        <TableRow
                            style={{
                                borderTop: step === 4 ? "4px solid" : "1px solid",
                                color: "grey !important",
                            }}
                            className={setColor(
                                data.req_vol.above_mandate_after_fulfill,
                                data.allocated_LE.above_mandate_after_fulfill,
                                data.remaining_vol.above_mandate_after_fulfill
                            )}
                        >
                            <TableCell style={{ color: "grey", }}>{getResult(
                                data.req_vol.above_mandate_after_fulfill,
                                data.allocated_LE.above_mandate_after_fulfill,
                                data.remaining_vol.above_mandate_after_fulfill
                            )}</TableCell>
                            <TableCell style={{ color: "grey", }}>Above the mandate (after mandate fulfillment)</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.req_vol.above_mandate_after_fulfill}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.joint_fulfill.above_mandate_after_fulfill}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.allocated_LE.above_mandate_after_fulfill}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.allocated_15.above_mandate_after_fulfill}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.surplus_from_offroad.above_mandate_after_fulfill}</TableCell>
                            <TableCell style={{ color: "grey", }}>{data.remaining_vol.above_mandate_after_fulfill}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
};

export default OverviewTable;
