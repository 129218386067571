/**
 * Get files list
 */

import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

const getFileList = async (type: "biotemplate" | "customer_sale") => {
  const token = getToken();
  if (token) {
    try {
      const API_NAME =
        type === "biotemplate"
          ? "card_biotemplate_groups"
          : "v1/card_customer_sales_groups";
      const response = await fetch(`${BASE_URL}/api/${API_NAME}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        if (type === "biotemplate") { return data.card_biotemplate_groups; }
        else { return data.card_customer_sales_groups; }
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export default getFileList;
