/**
 * Upload a biotemplate file to sustainability reporting
 */

import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

interface IUploadFile {
  file: File;
  year: number;
  type: "biotemplate" | "customer_sale";
}

const uploadFile = async ({
  file,
  year,
  type,
}: IUploadFile): Promise<{ success: boolean, msg: String, data: any[] } | null> => {
  const token = getToken();
  if (token) {
    const fd = new FormData();
    fd.append("xlxs_file", file);
    fd.append("tax_year", String(year));

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const API_NAME =
        type === "biotemplate"
          ? "card_biotemplate_groups"
          : "v1/card_customer_sales_groups";
      const res = await axios.post(`${BASE_URL}/api/${API_NAME}`, fd, config);
      if (res.status === 200 && res.data.success) {
        if (res.data.data && Array.isArray(res.data.data)) {
          return { success: res.data.success, msg: "", data: res.data };
        } else {
          return { success: res.data.success, msg: "Your data field is Empty", data: [] };
        }
      } else {
        return { success: res.data.success, msg: res.data.error, data: res.data };
      }
    } catch {
      return { success: false, msg: "Your Action is failed. Please try Again", data: [] };
    }
  } else {
    return null; // No token
  }
};

export default uploadFile;
