import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import { Form, Field, useForm } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import ButtonRounded from "../../../../components/ButtonRounded";
import registerFonts from "../../PDFView/config/registerFonts";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorContainer: {
      color: "red",
      textAlign: "center",
      margin: "0 0 10px 0",
    },
    tableContainer: {
      maxHeight: "450px",
      overflowY: "auto", // "scroll",
      "& .MuiOutlinedInput-input": {
        ...theme.typography.caption,
        fontWeight: 500,
        color: theme.palette.primary.main,
      },
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        ...theme.typography.caption,
      },
      "& .MuiTableCell-head": {
        ...theme.typography.caption,
        fontWeight: "bold",
      },
    },

    emptyCell: {
      backgroundColor: theme.palette.common.white,
      height: "350px",
      textAlign: "center",
    },
    btnChooseProduct: {
      minWidth: "170px",
    },
  })
);

const tableHeadings = [
  "Biofuel Type",
  "Origin",
  "Biocomponent Type",
  "Raw Material",
  "Advanced",
  "GHG Reduction",
  "Storage Terminal",
  "Available Quantity (L)",
  "Allocated Quantity (L)",
];

interface Props {
  data: any[];
  volume: any;
  disabledChooseProducts: boolean;
  handleChooseProducts: () => void;
  onSubmit: (values: any) => void;
  canSubmit: boolean;
  setCanSubmit: (flag: boolean) => void;
}

const EditableTable: React.FC<Props> = ({
  data,
  volume,
  disabledChooseProducts,
  handleChooseProducts,
  onSubmit,
  canSubmit,
  setCanSubmit
}) => {

  const [totalAllocated, setTtotalAllocated] = useState<any>(null);
  const [formData, setFormData] = useState<any>(null);
  const classes = useStyles();

  const initialValues =
    Array.isArray(data) && data.length > 0
      ? data.map(d => ({
        id: d.id,
        biofuel_type: d.biofuel_type,
        raw_material_origin: d.raw_material_origin,
        fuel_type: d.fuel_type,
        raw_material: d.raw_material,
        double_counted_norway: d.double_counted_norway,
        reduction_in_climate_emission: d.reduction_in_climate_emission,
        storage_terminal: d.storage_terminal,
        available_quantity: d.available_quantity,
        allocated_quantity: null,
        pos_date: d.pos_date,
        shipment_sale_nomination: d.shipment_sale_nomination,
        b_l_date: d.b_l_date,
        vessel: d.vessel,
        certificate: d.certificate,
        site_start: d.site_start,
        eec: d.eec,
        el: d.el,
        ep: d.ep,
        etd: d.etd,
        eu: d.eu,
        esca: d.esca,
        eccs: d.eccs,
        eccr: d.eccr,
        eee: d.eee,
        etot: d.etot,
      }))
      : null;

  const onFormChange = (formState: any) => {
    const data: any = formState.values;
    let total = 0;
    if (data && Array.isArray(data.biotemplates)) {
      const filteredValues = data.biotemplates.filter(
        (formData: any) => formData.allocated_quantity
      );
      if (filteredValues.length > 0) {
        for (let i = 0; i <
          filteredValues.length; i += 1) {
          total += parseFloat(filteredValues[i].allocated_quantity);
        }
      }
    }

    setTtotalAllocated(total);

    // @ts-ignore
    data.biotemplates.map(obj => {
      if (!obj.hasOwnProperty("allocated_quantity")) {
        obj.allocated_quantity = null;
      }
      return obj;
    });

    if (volume == totalAllocated) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
    setFormData(data.biotemplates);
  };

  const validateNumber = (value: any) =>
    isNaN(value) ? "Must be a number" : undefined;

  useEffect(() => {
    setFormData(initialValues);
  }, [data]);
  return (
    <div className={classes.tableContainer}>
      {totalAllocated == 0 && (
        <div className={classes.errorContainer}>
          <Typography variant="body2">
            Please allocate volume.
          </Typography>
        </div>
      )}
      {totalAllocated > 0 && volume > totalAllocated && (
        <div className={classes.errorContainer}>
          <Typography variant="body2">
            Total Volume remaining to be allocated: {volume - totalAllocated}
          </Typography>
        </div>
      )}
      {volume < totalAllocated && (
        <div className={classes.errorContainer}>
          <Typography variant="body2">
            Total Volume over-allocated: {volume - totalAllocated}
          </Typography>
        </div>
      )}
      <Form
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={{
          biotemplates: formData,
        }}
      >
        {({ handleSubmit, form }) => {
          // eslint-disable-next-line
          useEffect(() => {
            const data: any = form.getState().values;
            if (data && Array.isArray(data.biotemplates)) {
              const unsubscribe = form.subscribe(onFormChange, { values: true });
              return unsubscribe;
            }
          }, [form]);

          return (
            <form onSubmit={handleSubmit} id="storage_outgoing_table_form">
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {tableHeadings.map((heading, hidx) => (
                      <TableCell key={hidx}>{heading}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {Array.isArray(formData) && formData.length > 0 && (
                    <FieldArray name="biotemplates">
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <TableRow key={index}>
                            <Field name={`${name}.biofuel_type`}>
                              {({ input: { value } }) => (
                                <TableCell>{value}</TableCell>
                              )}
                            </Field>
                            <Field name={`${name}.raw_material_origin`}>
                              {({ input: { value } }) => (
                                <TableCell>{value}</TableCell>
                              )}
                            </Field>
                            <Field name={`${name}.fuel_type`}>
                              {({ input: { value } }) => (
                                <TableCell>{value}</TableCell>
                              )}
                            </Field>
                            <Field name={`${name}.raw_material`}>
                              {({ input: { value } }) => (
                                <TableCell>{value}</TableCell>
                              )}
                            </Field>
                            <Field name={`${name}.double_counted_norway`}>
                              {({ input: { value } }) => (
                                <TableCell>{value}</TableCell>
                              )}
                            </Field>
                            <Field name={`${name}.reduction_in_climate_emission`}>
                              {({ input: { value } }) => (
                                <TableCell>{Number(value).toFixed(2)}%</TableCell>
                              )}
                            </Field>
                            <Field name={`${name}.storage_terminal`}>
                              {({ input: { value } }) => (
                                <TableCell>{value}</TableCell>
                              )}
                            </Field>
                            <Field name={`${name}.available_quantity`}>
                              {({ input: { value: aqvalue } }) => (
                                <Field
                                  name={`${name}.allocated_quantity`}
                                  validate={validateNumber}
                                >
                                  {({ input, meta }) => (
                                    <Field name={`${name}.double_counted_norway`}>
                                      {({ input: { value: dcvalue } }) => (
                                        <>
                                          <TableCell>
                                            {isNaN(input.value)
                                              ? aqvalue
                                              : Number(aqvalue) -
                                              Number(input.value)}
                                          </TableCell>
                                          <TableCell>
                                            <TextField
                                              {...input}
                                              variant="outlined"
                                              placeholder="00,00"
                                              fullWidth
                                              error={Boolean(
                                                (input.value &&
                                                  meta.touched &&
                                                  meta.error)
                                              )}
                                            />
                                            {input.value &&
                                              meta.touched &&
                                              meta.error && (
                                                <Typography
                                                  variant="caption"
                                                  color="error"
                                                >
                                                  Must be a number
                                                </Typography>
                                              )}
                                          </TableCell>
                                        </>
                                      )}
                                    </Field>
                                  )}
                                </Field>
                              )}
                            </Field>
                          </TableRow>
                        ))
                      }
                    </FieldArray>
                  )}

                  {Array.isArray(formData) && formData.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={9} className={classes.emptyCell}>
                        <Typography variant="body2">No Products</Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  {!formData && (
                    <TableRow>
                      <TableCell colSpan={9} className={classes.emptyCell}>
                        <ButtonRounded
                          variant="outlined"
                          color="primary"
                          className={classes.btnChooseProduct}
                          onClick={handleChooseProducts}
                          disabled={disabledChooseProducts}
                        >
                          Choose Products
                        </ButtonRounded>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default EditableTable;
