import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import DropZoneCard from "../../../../components/DropZoneCard";
import FilesTable, { IFile } from "../../../../components/FilesTable";
// need to change to new card apis
import uploadFile from "../../../../api/card/files/uploadFile";
import deleteFile from "../../../../api/card/files/deleteFile";
import useModal from "../../../../components/modal/useModal";
import ModalInfo from "../../../../components/modal/ModalInfo";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        fontSize: "14px",
      },
    },
    filelistContainer: {
      marginTop: theme.spacing(2),
    },
    select: {
      width: "150px",
      margin: theme.spacing(3, 0),
      marginRight: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        width: "100px",
      },
    },
  })
);

interface Props {
  files: any;
  taxPeriods: any[];
  selectedYear: number;
  setSelectedYear: any;
  getFiles: any;
}

const FilesView: React.FC<Props> = ({
  files,
  taxPeriods,
  selectedYear,
  setSelectedYear,
  getFiles,
}) => {
  const [filterList, setFilterList] = useState<any[]>([]);
  const [infoModal, setInfoModal] = useState({ title: "", description: "" });

  const { openModalId, toggle } = useModal();
  const classes = useStyles();

  const openModal = (id: string, text: string, message: string) => {
    if (id === "info") {
      setInfoModal({ title: text, description: message });
    }
    toggle(id);
  };

  const closeModal = () => {
    toggle();
  };

  const handleUploadFile = async (
    files: File[],
    type: "biotemplate" | "customer_sale"
  ) => {
    const res = await uploadFile({
      file: files[0],
      year: selectedYear,
      type,
    });

    if (res && res.success) {
      openModal("info", `File uploaded successfully`, "");
      getFiles();
    } else {
      let warning = new String();
      if (res) {
        warning = res.msg;
      }
      openModal(
        "info",
        `File uploaded failed`,
        warning.toString()
      );
    }
  };

  const handleDeleteFile = async (file: IFile) => {
    const res = await deleteFile(file.id, file.type);

    if (res && res.success) {
      openModal("info", `File deleted successfully`, "");
      getFiles();
    } else {
      openModal("info", `File deleted failed`, "Network related problem");
    }
  };

  const filterFileList = (year: number, files: any[]) => {
    if (files && Array.isArray(files)) {
      const newFilteredList = files.filter(
        (item: any) => parseInt(item.tax_year) === year
      );
      setFilterList(newFilteredList);
    }
  };

  useEffect(() => {
    filterFileList(selectedYear, files);
  }, [selectedYear, files]);

  return (
    <div className={classes.root}>
      <Typography variant="h2">
        <FormattedMessage id="exciseDutyView.filesView.heading" />
      </Typography>
      <Select
        fullWidth
        className={classes.select}
        input={<OutlinedInput labelWidth={0} />}
        value={selectedYear}
        onChange={e => setSelectedYear(e.target.value)}
      >
        {taxPeriods
          ? taxPeriods.map((year: any, index: number) => (
              <MenuItem
                value={Object.keys(year)[0]}
                key={
                  "Sus.View_Files_Year_" + Object.keys(year)[0] + "-" + index
                }
              >
                {Object.keys(year)[0]}
              </MenuItem>
            ))
          : null}
      </Select>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <DropZoneCard
            title={
              <FormattedMessage id="sustainabilityView.filesView.newCardBiotemplateFile" />
            }
            handleFiles={files => handleUploadFile(files, "biotemplate")}
          />
        </Grid>
        <Grid item xs={4}>
          <DropZoneCard
            title={
              <FormattedMessage id="sustainabilityView.filesView.uploadCardCustomerSaleFile" />
            }
            handleFiles={files => handleUploadFile(files, "customer_sale")}
          />
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <div className={classes.filelistContainer}>
        <FilesTable fileList={filterList} handleDeleteFile={handleDeleteFile} />
      </div>
      <ModalInfo
        text={infoModal.title}
        message={infoModal.description}
        open={openModalId === "info"}
        onClose={closeModal}
      />
    </div>
  );
};

export default FilesView;
