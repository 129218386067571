import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { Form, Field } from "react-final-form";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { FORM_ERROR } from "final-form";
import * as yup from "yup";
import makeValidate from "../../form/makeValidate";
import ButtonRounded from "../../ButtonRounded";
import ModalContainer from "../../ModalContainer";
import KeyboardDatePicker from "../../inputs/KeyboardDatePicker";
import SmartSelect from "../../../components/SmartSelect";
// import { IModalAddEditProps } from "./Interfaces";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import parseData from "../../../components/texts/parseData";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {
  BIOFUEL_TYPE,
  RAW_MATERIAL,
  RAW_MATERIAL_ORIGIN,
} from "../../../api/settings/bkvMappings/get";
import postBkvUnMapData from "../../../api/settings/bkvMappings/postBkvUnMapData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTop: {
      paddingTop: theme.spacing(2),
    },
    sectionDivider: {
      paddingTop: theme.spacing(5),
      marginBottom: theme.spacing(0),
    },
    btnContainer: {
      paddingTop: theme.spacing(6),
    },
    red: {
      color: theme.palette.error.main,
    },
    fullWidth: {
      width: "100%",
    },
  })
);

const EditModal: React.FC<any> = ({
  title,
  open,
  onClose,
  fetchData,
  values,
  type,
}) => {
  const [biofuelTypeData, setBiofuelTypeData] = useState<any>([]);
  const [rawMaterialData, setRawMaterialData] = useState<any>([]);
  const [rawMaterialOriginData, setRawMaterialOriginData] = useState<any>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const classes = useStyles();

  const onSubmit = async (values: any) => {
    setIsError(false);
    const res = await postBkvUnMapData({
      values,
    });
    fetchData();
    if (res && res.success) {
      onClose();
    } else {
      setIsError(true);
    }
  };
  const setData = (values: any, type: number) => {
    const biofuelType: any[] = [];
    const rawMaterial: any[] = [];
    const rawMaterialOrigin: any[] = [];

    if (type == BIOFUEL_TYPE) {
      biofuelType.push(values);
      setBiofuelTypeData(biofuelType);
    } else if (type == RAW_MATERIAL) {
      rawMaterial.push(values);
      setRawMaterialData(rawMaterial);
    } else if (type == RAW_MATERIAL_ORIGIN) {
      rawMaterialOrigin.push(values);
      setRawMaterialOriginData(rawMaterialOrigin);
    }
  };

  useEffect(() => {
    setIsError(false);
    setData(values, type);
  }, [values, type]);
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={<FormattedMessage id="settingsView.modalAddEdit.title.edit" />}
      subtitle={title}
    >
      <div>
        <div>
          <Form
            onSubmit={onSubmit}
            mutators={{ ...arrayMutators }}
            initialValues={{
              biofuel_type: biofuelTypeData,
              raw_material: rawMaterialData,
              raw_material_origin: rawMaterialOriginData,
            }}
          >
            {({
              handleSubmit,
              submitting,
              form: {
                mutators: { push },
              },
            }) => (
              <form onSubmit={handleSubmit}>
                <FieldArray name="biofuel_type">
                  {({ fields }) =>
                    fields.map((name, index) => {
                      return (
                        <Grid
                          container
                          spacing={2}
                          className={classes.btnContainer}
                          key={index}
                        >
                          <Grid item xs={12}>
                            <Field name={`${name}.value`}>
                              {props => (
                                <FormattedMessage id="Value">
                                  {fmsg => (
                                    <TextField
                                      {...props.input}
                                      variant="outlined"
                                      placeholder={fmsg as string}
                                      className={classes.fullWidth}
                                    />
                                  )}
                                </FormattedMessage>
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field name={`${name}.translation`}>
                              {props => (
                                <SmartSelect
                                  {...props.input}
                                  displayEmpty
                                  type="biofuel_type"
                                  className={classes.fullWidth}
                                  emptyMenuItem={
                                    <FormattedMessage id="Biofuel Type" />
                                  }
                                />
                              )}
                            </Field>
                            {/* <input
                              type="hidden"
                              name={`${name}.translation_type`}
                            />
                            <input
                              type="hidden"
                              name={`${name}.is_favourite`}
                              value={favourite === "Favourite" ? 1 : 0}
                            /> */}
                          </Grid>
                        </Grid>
                      );
                    })
                  }
                </FieldArray>
                <FieldArray name="raw_material">
                  {({ fields }) =>
                    fields.map((name, index) => {
                      return (
                        <Grid
                          container
                          spacing={2}
                          className={classes.btnContainer}
                          key={index}
                        >
                          <Grid item xs={12}>
                            <Field name={`${name}.value`}>
                              {props => (
                                <FormattedMessage id="Value">
                                  {fmsg => (
                                    <TextField
                                      {...props.input}
                                      variant="outlined"
                                      placeholder={fmsg as string}
                                      className={classes.fullWidth}
                                    />
                                  )}
                                </FormattedMessage>
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field name={`${name}.translation`}>
                              {props => (
                                <SmartSelect
                                  {...props.input}
                                  displayEmpty
                                  type="raw_material"
                                  className={classes.fullWidth}
                                  emptyMenuItem={
                                    <FormattedMessage id="Raw Material" />
                                  }
                                />
                              )}
                            </Field>
                            {/* <input
                              type="hidden"
                              name={`${name}.translation_type`}
                              value={404}
                            />
                            <input
                              type="hidden"
                              name={`${name}.is_favourite`}
                              value={favourite === "Favourite" ? 1 : 0}
                            /> */}
                          </Grid>
                        </Grid>
                      );
                    })
                  }
                </FieldArray>
                <FieldArray name="raw_material_origin">
                  {({ fields }) =>
                    fields.map((name, index) => {
                      return (
                        <Grid
                          container
                          spacing={2}
                          className={classes.btnContainer}
                          key={index}
                        >
                          <Grid item xs={12}>
                            <Field name={`${name}.value`}>
                              {props => (
                                <FormattedMessage id="Value">
                                  {fmsg => (
                                    <TextField
                                      {...props.input}
                                      variant="outlined"
                                      placeholder={fmsg as string}
                                      className={classes.fullWidth}
                                    />
                                  )}
                                </FormattedMessage>
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field name={`${name}.translation`}>
                              {props => (
                                <SmartSelect
                                  {...props.input}
                                  displayEmpty
                                  type="origin"
                                  className={classes.fullWidth}
                                  emptyMenuItem={
                                    <FormattedMessage id="Raw Material Origin" />
                                  }
                                />
                              )}
                            </Field>
                            {/* <input
                              type="hidden"
                              name={`${name}.translation_type`}
                              value={type}
                            />
                            <input
                              type="hidden"
                              name={`${name}.is_favourite`}
                              value={favourite === "Favourite" ? 1 : 0}
                            /> */}
                          </Grid>
                        </Grid>
                      );
                    })
                  }
                </FieldArray>
                <Grid container spacing={2} className={classes.btnContainer}>
                  <Grid item xs={6}>
                    <ButtonRounded
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={onClose}
                    >
                      {<FormattedMessage id="settingsView.modal.cancel" />}
                    </ButtonRounded>
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonRounded
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                    >
                      {<FormattedMessage id="settingsView.modal.save" />}
                    </ButtonRounded>
                  </Grid>
                </Grid>
              </form>
            )}
          </Form>
        </div>
      </div>
    </ModalContainer>
  );
};

export default EditModal;
