import { withStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const ButtonSquare = withStyles((theme: Theme) => ({
    root: {
        borderRadius: theme.spacing(1),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),

    },
    contained: {
        boxShadow: "none",
    },
}))(Button);

export default ButtonSquare;