import React from "react";
import { FormattedMessage } from "react-intl";

export default [
  {
    name: <FormattedMessage id="settingsView.index.rawMaterials.name" />,
    id: "raw_material_name",
    type: "text",
  },
  {
    name: <FormattedMessage id="settingsView.index.rawMaterials.active" />,
    id: "active",
    type: "boolean",
    params: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
    boolean_value: ["Yes", "No"],
  },
  {
    name: <FormattedMessage id="settingsView.index.rawMaterials.advancedType" />,
    id: "advanced_type",
    type: "select",
    params: "advanced_type",
  },
  {
    name: <FormattedMessage id="settingsView.index.rawMaterials.advancedTaxYears" />,
    id: "advanced_tax_years",
    type: "multi-select",
    params: "tax_years",
    optionnal: "true",
  },
  {
    name: <FormattedMessage id="settingsView.index.rawMaterials.type" />,
    id: "raw_material_type",
    type: "select",
    params: "raw_material_types",
    optionnal: "true",
  },
  {
    name: <FormattedMessage id="settingsView.index.rawMaterials.code" />,
    id: "code",
    type: "text",
  },
];
