import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ButtonRounded from "../../../../../../components/ButtonRounded";
import {useHistory, useRouteMatch} from "react-router-dom";
import { Typography } from "@material-ui/core";
import resetAllocation from "../../../../../../api/sustainability/allocation/roadUse/stepData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "60%",
    },
    padding: {
      padding: theme.spacing(4),
    },
    icon: {
      width: "48px",
      height: "48px",
    },
  })
);

interface Props {
  text: string;
}

const SuccessView: React.FC<Props> = ({ text }) => {
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();
    const goToReports = () => {
        history.push(`/sustainability/reportoverview/`);
    };
    const handleStartAllocation = async () => {
        // @ts-ignore
        await resetAllocation();
        console.log("before push")
        history.push(`/sustainability/mandateoverview/`);
        console.log("after push")
    }
    return (
        <div className={classes.root}>
            <CheckCircleOutlineIcon className={classes.icon} color="primary" />
            <Typography className={classes.padding}>{text}</Typography>
            <div style={{ display: 'flex', gap: '10px' }}>
                <ButtonRounded variant="contained" color="primary" onClick={goToReports}>
                    Report Overview
                </ButtonRounded>
                <ButtonRounded variant="contained" color="primary" onClick={handleStartAllocation}>
                    Restart Allocation
                </ButtonRounded>
            </div>
        </div>
    );
};

export default SuccessView;
