import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import Box from "@material-ui/core/Box";
import ButtonRounded from "../ButtonRounded";
import ModalContainer from "../ModalContainer";

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTop: {
      paddingTop: theme.spacing(2),
    },
    btnContainer: {
      paddingTop: theme.spacing(6),
    },
    iconDelete: {
      color: theme.palette.error.main,
    },
    marginBottom: {
      "margin-bottom": theme.spacing(2),
    },
  })
);

export interface IModalAddEditProps {
  open: boolean;
  onSave: any;
  initVariable: any;
  onClose: () => void;
}

const ModalEditAppVariable: React.FC<IModalAddEditProps> = ({
  open,
  onSave,
  initVariable,
  onClose,
}) => {
  const classes = useStyles();
  const [list, setList] = useState<string[]>([]);

  const editList = (index: number, value: string) => {
    const newList = [...list];
    newList[index] = value;
    setList(newList);
  };

  const addInList = () => {
    const newList = [...list];
    newList.push("");
    setList(newList);
  };

  const removeFromList = (index: number) => {
    const newList = [...list];
    newList.splice(index, 1);
    setList(newList);
  };

  const listIsValid = () =>
    list.filter(value => {
      return value === "";
    }).length !== 0;

  const onSubmit = async () => {
    let res = null;

    // if Edit Mode: give selected setting id to onSave parameters
    if (initVariable) {
      const newVariable = { ...initVariable };
      newVariable.values = list;
      res = await onSave(newVariable);
    } else { res = await onSave(list); } // Create Mode

    if (res && res.success) {
      onClose();
    } else {
      return res.error;
    }
  };

  useEffect(() => {
    setList(initVariable.values);
  }, [initVariable]);

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={
        list ? (
          <FormattedMessage id="settingsView.modalAddEdit.title.edit" />
        ) : (
          <FormattedMessage id="settingsView.modalAddEdit.title.addNew" />
        )
      }
      subtitle={"App Variable"}
    >
      {Array.isArray(list) &&
        list.map((param: string, index: number) => (
          <Box display="flex" className={classes.marginBottom}>
            <TextField
              key={"input" + index}
              placeholder={"New Element"}
              value={param}
              variant="outlined"
              onChange={e => editList(index, e.target.value)}
              fullWidth
            />
            <IconButton
              className={classes.iconDelete}
              onClick={() => removeFromList(index)}
            >
              <DeleteOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>
        ))}
      <ButtonRounded
        fullWidth
        variant="outlined"
        color="primary"
        onClick={addInList}
        disabled={listIsValid()}
      >
        {"Add a value"}
      </ButtonRounded>
      <Grid container spacing={2} className={classes.btnContainer}>
        <Grid item xs={6}>
          <ButtonRounded
            fullWidth
            variant="contained"
            color="secondary"
            onClick={onClose}
          >
            {<FormattedMessage id="settingsView.modal.cancel" />}
          </ButtonRounded>
        </Grid>
        <Grid item xs={6}>
          <ButtonRounded
            fullWidth
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={listIsValid()}
          >
            {<FormattedMessage id="settingsView.modal.save" />}
          </ButtonRounded>
        </Grid>
      </Grid>
    </ModalContainer>
  );
};

export default ModalEditAppVariable;
