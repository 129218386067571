import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import SmartSelect from "../../components/SmartSelect";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import FiltersTitle from "./FiltersTitle";
import KeyboardDatePicker from "../inputs/KeyboardDatePicker";
import Slider from "../inputs/Slider";
import { isString } from "util";

import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      "& .MuiOutlinedInput-input": {
        fontSize: "14px",
      },
    },
    topSection: {
      margin: theme.spacing(1),
      justifyContent: "space-between",
    },
    singleColumn: {
      width: "20%",
      padding: theme.spacing(1),
    },
    doubleColumn: {
      width: "40%",
      padding: theme.spacing(1),
    },
    greyText: {
      color: theme.palette.action.disabled,
    },
    expandFilterButton: {
      justifyContent: "space-evenly",
      width: "122px",
      height: "25px",
      padding: theme.spacing(0),
      marginLeft: theme.spacing(1.9),
    },
    clearButton: {
      display: "flex",
      justifyContent: "space-evenly",
      width: "102px",
      height: "25px",
      padding: theme.spacing(0),
    },
    dateIconContainer: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      padding: theme.spacing(0.25),
      color: theme.palette.primary.main,
    },
    tableTitle: {
      fontSize: "13px",
      lineHeight: "2",
    },
  })
);

// Interfaces
export interface IFilterParameters {
  [key: string]: any;
}

interface IFilterProps {
  setup: any;
  filterValues: any;
  clearFilter: () => void;
  onChange: (key: string, value: any) => void;
}

// Filters Function
const Filters: React.FC<IFilterProps> = ({
  setup,
  filterValues,
  clearFilter,
  onChange,
}) => {
  const [showMoreFilters, setShowMoreFilters] = useState<boolean>(true);
  const classes = useStyles();

  const toggleFilters = () => setShowMoreFilters(!showMoreFilters);

  const getSelect = (
    title: string | React.ReactElement,
    name: string,
    params: string
  ) => {
    return (
      <Box key={name + "Filter"} className={classes.singleColumn}>
        <Typography variant="body2" className={classes.tableTitle}>
          {title}
        </Typography>
        <SmartSelect
          fullWidth
          displayEmpty
          type={params}
          className={
            !filterValues[name] || filterValues[name] === ""
              ? classes.greyText
              : undefined
          }
          name={name}
          value={filterValues[name] ? filterValues[name] : ""}
          onChange={(e: any) =>
            onChange(name, isString(e.target.value) ? e.target.value : "")
          }
        ></SmartSelect>
      </Box>
    );
  };

  const getTextField = (
    title: string | React.ReactElement,
    name: string,
    placeholder: string
  ) => {
    return (
      <Box key={name + "Filter"} className={classes.singleColumn}>
        <Typography variant="body2" className={classes.tableTitle}>
          {title}
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          name={name}
          value={filterValues[name] || ""}
          placeholder={placeholder}
          onChange={e =>
            onChange(name, isString(e.target.value) ? e.target.value : "")
          }
        />
      </Box>
    );
  };

  const getSlider = (
    title: string | React.ReactElement,
    name: string,
    params: any
  ) => {
    return (
      <Box key={name + "Filter"} className={classes.doubleColumn}>
        <Typography variant="body2" className={classes.tableTitle}>
          {title}
        </Typography>
        <Slider
          onChange={(e, value) =>
            Array.isArray(value) &&
            value[0] === params.min &&
            value[1] === params.max
              ? onChange(name, null)
              : onChange(name, value)
          }
          value={
            filterValues[name] ? filterValues[name] : [params.min, params.max]
          }
          valueLabelDisplay="on"
          valueLabelFormat={val => {
            return val.toLocaleString("fr-FR");
          }}
          min={params.min}
          max={params.max}
          step={params.step}
        />
      </Box>
    );
  };

  const getPeriod = (title: any, name: any) => (
    <Box key={name + "Filter"} display="flex" className={classes.doubleColumn}>
      <Box display="flex" flex="1" flexDirection="column">
        <Typography variant="body2" className={classes.tableTitle}>
          {title.from}
        </Typography>
        <KeyboardDatePicker
          value={filterValues[name.from] ? filterValues[name.from] : null}
          placeholder="2019-01-01"
          onChange={params =>
            params ? onChange(name.from, params) : onChange(name.from, null)
          }
          inputVariant="outlined"
          format="YYYY-MM-DD"
          maxDate={filterValues[name.to]}
          invalidDateMessage={null}
        />
      </Box>
      <div className={classes.dateIconContainer}>
        <ArrowRightAltIcon fontSize="large" />
      </div>
      <Box display="flex" flex="1" flexDirection="column">
        <Typography variant="body2" className={classes.tableTitle}>
          {title.to}
        </Typography>
        <KeyboardDatePicker
          value={filterValues[name.to] ? filterValues[name.to] : null}
          placeholder="2019-12-31"
          onChange={params =>
            params ? onChange(name.to, params) : onChange(name.to, null)
          }
          inputVariant="outlined"
          format="YYYY-MM-DD"
          minDate={filterValues[name.from]}
        />
      </Box>
    </Box>
  );

  return (
    <Paper className={classes.root}>
      {/* Filters top section */}
      <Box display="flex" className={classes.topSection}>
        <FiltersTitle />
        <div>
          <Grid container>
            <Grid item>
              <Button
                className={classes.clearButton}
                variant="outlined"
                color="secondary"
                onClick={clearFilter}
                disabled={Object.keys(filterValues).length === 0}
              >
                <CloseIcon fontSize="small" />
                <FormattedMessage id="exciseDutyView.transactionsView.filters.clearAll" />
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.expandFilterButton}
                variant="outlined"
                color="primary"
                onClick={toggleFilters}
              >
                {showMoreFilters ? (
                  <ExpandLessIcon fontSize="small" />
                ) : (
                  <ExpandMoreIcon fontSize="small" />
                )}
                {/* {`${showMoreFilters ? "Less" : "More"} filters`} */}
                {showMoreFilters ? (
                  <FormattedMessage id="exciseDutyView.transactionsView.filters.lessFilters" />
                ) : (
                  <FormattedMessage id="exciseDutyView.transactionsView.filters.moreFilters" />
                )}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Box>

      {/* Filters: default section */}

      {setup.map(
        (row: any, index: number) =>
          (index === 0 || showMoreFilters) && (
            <Box display="flex" key={"rowFilter" + index}>
              {row.map((item: any) => {
                switch (item.type) {
                  case "text":
                    return getTextField(item.name, item.id, item.placeholder);
                  case "select":
                    return getSelect(item.name, item.id, item.params);
                  case "slider":
                    return getSlider(item.name, item.id, item.params);
                  case "period":
                    return getPeriod(item.name, item.id);
                }
              })}
            </Box>
          )
      )}
    </Paper>
  );
};

export default Filters;
