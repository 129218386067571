import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

export default async (params: any, id: any) => {
  const token = getToken();

  let valid = false;
  let data = {};
  let editResult;

  if (token) {
    try {
      let response = await fetch(
        `${BASE_URL}/api/sold_volume_components/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(params),
        }
      );

      editResult = await response.json();
      valid = editResult && editResult.success;
      let soldVol = editResult.sold_volume_components;

      data = soldVol;
      if (soldVol.nafta && soldVol.etanol && soldVol.fame && soldVol.hvo_hro) {
        data = {
          ...soldVol,
          nafta: parseFloat(soldVol.nafta),
          etanol: parseFloat(soldVol.etanol),
          fame: parseFloat(soldVol.fame),
          hvo_hro: parseFloat(soldVol.hvo_hro),
        };
      }
    } catch (except) {
      valid = false; // network error
    }
  }

  if (!valid) {
    if (editResult && editResult.errors) {
      return {
        valid: false,
        message: editResult.errors,
      };
    }
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    data,
  };
};
