import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ButtonRounded from "../../../../components/ButtonRounded";
import RequiredAsterisk from "../../../../components/texts/RequiredAsterisk";
import ModalContainer from "../../../../components/ModalContainer";
// import postReport from "../../../../api/exciseduty/reports/postReport";
import { FormattedMessage } from "react-intl";
import ModalInfo from "../../../../components/modal/ModalInfo";
import useModal from "../../../../components/modal/useModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    btnContainer: {
      marginTop: theme.spacing(3),
    },
    marginBottom: {
      "margin-bottom": theme.spacing(2),
    },
    btnLeft: {
      marginRight: theme.spacing(3),
    },
    noteText: {
      color: theme.palette.error.main,
      fontWeight: 500,
    },
    commentTitle: {
      fontWeight: 500,
    },
    errorMsg: {
      marginTop: theme.spacing(2),
    },
  })
);

interface ModalSaveReportProps {
  open: boolean;
  onClose: () => void;
  data: any;
  updataItems: (value: any) => void;
  updatingError: any;
}

const ModalSaveReport: React.FC<ModalSaveReportProps> = ({
  open,
  onClose,
  data,
  updataItems,
  updatingError,
}) => {
  const [comment, setComment] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [infoModal, setInfoModal] = useState<any>();
  const { openModalId, toggle } = useModal();
  const classes = useStyles();
  const history = useHistory();

  const canSubmit: boolean = comment.length > 3;

  const goToReports = () => {
    history.push(`/excise-duty/reports`);
  };

  const handleSave = async () => {
    const newData = { ...data, comment };
    updataItems(newData);
    if (updatingError === undefined) {
      openModal("info", `Successfully updated report`, "", goToReports);
    } else {
      openModal(
        "info",
        `Failed to delete report`,
        "Network related error",
        closeModal
      );
    }
  };

  const closeModal = () => {
    toggle();
  };

  const openModal = (
    id: string,
    text: string,
    message: string,
    onClose: () => void
  ) => {
    if (id === "info") {
      setInfoModal({ title: text, description: message, onClose });
    }
    toggle(id);
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title="Save Report"
      maxWidth="xs"
    >
      <div className={classes.modalContent}>
        <Typography variant="body1" paragraph>
          <FormattedMessage id="modalSave.warning" />
        </Typography>

        <Typography variant="body1" className={classes.commentTitle}>
          <FormattedMessage id="modalSave.comment" /> <RequiredAsterisk />
        </Typography>
        <TextField
          variant="outlined"
          name="comment"
          placeholder="Write a comment"
          multiline
          fullWidth
          rows={4}
          value={comment}
          onChange={e => setComment(e.target.value)}
        />

        <Grid container className={classes.btnContainer} spacing={2}>
          <Grid item xs>
            <ButtonRounded
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onClose}
            >
              <FormattedMessage id="settingsView.modal.cancel" />
            </ButtonRounded>
          </Grid>
          <Grid item xs>
            <ButtonRounded
              fullWidth
              variant="contained"
              color="primary"
              disabled={!canSubmit}
              onClick={handleSave}
            >
              <FormattedMessage id="settingsView.modal.save" />
            </ButtonRounded>
          </Grid>
        </Grid>

        {isError && (
          <Typography
            align="center"
            variant="body2"
            color="error"
            className={classes.errorMsg}
          >
            <FormattedMessage id="allView.failedToSave" />.
          </Typography>
        )}
        {infoModal && (
          <ModalInfo
            text={infoModal.title}
            message={infoModal.description}
            open={openModalId === "info"}
            onClose={infoModal.onClose}
          />
        )}
      </div>
    </ModalContainer>
  );
};

export default ModalSaveReport;
