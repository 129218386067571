/**
 * Get users list
 * only accessible to admins
 */

import { getToken, isAdmin } from "../../../utils";
import { BASE_URL } from "../../../endpoints";

export const hasRoadData = async () => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/sold_volume_components/check_data`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data) {
          return data;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const getRoadUseData = async () => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/sold_volume_components`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const getRoadUseCreateData = async (params: any) => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/sold_volume_components`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const getRoadUseEditData = async (params: any) => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/sold_volume_components`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const deleteRoadUseData = async (id: any) => {
  const token = getToken();
  const params = {
    id,
  };
  if (token && isAdmin()) {
    try {
      // @ts-ignore
      const response = await fetch(`${BASE_URL}/api/sold_volume_components`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export default getRoadUseData;
