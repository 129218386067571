import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import parseData from "../../../../../../components/texts/parseData";

import SmartTable from "../../../../../../components/SmartTable/index";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      overflowY: "auto",
      "& .MuiOutlinedInput-input": {
        ...theme.typography.caption,
      },
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        ...theme.typography.caption,
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        ...theme.typography.caption,
        fontWeight: 500,
      },
    },
  })
);

const tableHeadings = [
  { name: "Biofuel Type", id: "biofuel_type" },
  { name: "Origin", id: "raw_material_origin" },
  { name: "Biocomponent Type", id: "fuel_type" },
  { name: "Raw Material", id: "raw_material" },
  { name: "Advanced", id: "double_counted_norway" },
  { name: "Ghg Reduction", id: "reduction_in_climate_emission" },
  { name: "Storage Terminal", id: "storage_terminal" },
  {
    name: "Available Quantity (L)",
    alignRight: true,
    id: "available_quantity",
  },
  {
    name: "Allocated Quantity (L)",
    alignRight: true,
    id: "allocated_quantity",
  },
  {
    name: "Allocated Quantity (LE)",
    alignRight: true,
    id: "allocated_eq_quantity",
  },
];

interface Props {
  data: any[];
  activeStep: number;
  allocated: any;
  available_quantities_part1: any;
  available_quantities_part2: any;
  available_quantities_construction: any;
  editAllocatedData: any;
}

const EditableTable: React.FC<Props> = ({
  data,
  activeStep,
  allocated,
  available_quantities_part1,
  available_quantities_part2,
  available_quantities_construction,
  editAllocatedData,
}) => {
  const classes = useStyles();

  const handleOnChange = (e: any, row: any, ridx: number) => {
    editAllocatedData(
      row.id,
      ridx,
      e.target.value,
      isDoubleCount(row),
      isDiesel(row)
    );
  };

  const handleOnBlur = (e: any, row: any, ridx: number) => {
    if (e.target.value === "") {
      editAllocatedData(row.id, ridx, 0, isDoubleCount(row), isDiesel(row));
    }
  };

  const isDoubleCount = (row: any) => {
    return row.double_counted_norway === "Ja";
  };

  const isDiesel = (row: any) => {
    return row.fuel_type === "Biokomponenter for diesel";
  };

  const getAllocated = (row: any): number => {
    let value = 0;

    value = allocated.hasOwnProperty(row.id)
      ? allocated[row.id]
      : row.allocated_quantity;

    return value;
  };

  const parseVal = (value: any) => {
    let parsed = value;
    if (parsed) {
      if (value > -1 && value < 0) { parsed *= -1; }
      return parseData(parsed, 2);
    } else { return parsed; }
  };

  const getData = (data: any[], activeStep: number) => {
    let newData = data.map((row: any, index: number) => {
      if (activeStep < 4) {
        row.available_quantity = (
          available_quantities_construction[row.id]
        );
      }
      else {
        row.available_quantity = (available_quantities_part2[row.id]);
      }

      row.allocated_eq_quantity = (
        getAllocated(row)
          ? isDoubleCount(row)
            ? 2 * getAllocated(row)
            : getAllocated(row)
          : 0
      );
      return row;
    });
    newData = newData.filter(
      (row: any) => !(row.available_quantity <= 0 && getAllocated(row) <= 0)
    );
    return newData;
  };

  return (
    <div className={classes.tableContainer}>
      <SmartTable
        headCells={tableHeadings}
        rows={data.length === 0 ? null : getData(data, activeStep)}
        editableProps={{
          id: "allocated_quantity",
          getValue: getAllocated,
          onChange: handleOnChange,
          onBlur: handleOnBlur,
        }}
        verticalOverflow={true}
        whiteHeader={true}
      />
    </div>
  );
};

export default EditableTable;
