/**
 *  Welcome message & about section
 */

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) => ({
  welcomeContainer: {
    height: "100%",
    position: "relative",
  },
  logo: {
    position: "absolute",
    top: "34px",
  },
  welcomeContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
  },
  welcomeTitle: {
    marginBottom: theme.spacing(8),
    maxWidth: "520px",
  },
  welcomeListItem: {
    display: "flex",
    marginBottom: theme.spacing(2.5),
  },
  pageTitle: {
    lineHeight: "72px",
    [theme.breakpoints.between("sm", "md")]: {
      lineHeight: "55px",
      fontSize: "50px",
    }
  }
}));

const Welcome: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.welcomeContainer}>
      <div className={classes.logo}>
        <Typography variant="h4">
          <FormattedMessage id={"loginView.logo"} />
        </Typography>
      </div>

      <div className={classes.welcomeContent}>
        <div className={classes.welcomeTitle}>
          <Typography variant="h1" className={classes.pageTitle}>
            <FormattedMessage id={"loginView.welcome"} />
          </Typography>
          <Typography variant="h1" className={classes.pageTitle}>
            <FormattedMessage id={"loginView.title"} />
          </Typography>
        </div>
        <div className={classes.welcomeListItem}>
          <CheckCircleOutlineIcon />
          <Typography variant="body1" display="inline">
            &nbsp; <FormattedMessage id={"loginView.subtitle1"} />
          </Typography>
        </div>
        <div className={classes.welcomeListItem}>
          <CheckCircleOutlineIcon />
          <Typography variant="body1" display="inline">
            &nbsp; <FormattedMessage id={"loginView.subtitle2"} />
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
