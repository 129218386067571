import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

export default async () => {
  const token = getToken();

  let valid = false;
  let data = {};
  let constructionDieselVolumeResult;

  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/construction_diesel_volumes`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      constructionDieselVolumeResult = await response.json();
      valid =
        constructionDieselVolumeResult &&
        constructionDieselVolumeResult.success;
      let constVol = constructionDieselVolumeResult.construction_diesel_volume;

      data = constVol;
      if (constVol.fossil && constVol.hvo) {
        data = {
          ...constVol,
          fossil: parseFloat(constVol.fossil),
          hvo: parseFloat(constVol.hvo),
        };
      }
    } catch (except) {
      valid = false; // network error
    }
  }

  if (!valid) {
    if (constructionDieselVolumeResult && constructionDieselVolumeResult.error) {
      return {
        valid: false,
        message: constructionDieselVolumeResult.error,
      };
    }
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    data,
  };
};
