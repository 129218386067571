import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import arrayMutators from "final-form-arrays";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { FormattedMessage } from "react-intl";
import CreatableSelect from "react-select/creatable";
import { checkBkvMapData } from "../../api/settings/bkvMappings/postBkvUnMapData";
import ButtonRounded from "../../components/ButtonRounded";
import ModalInfo from "../../components/modal/ModalInfo";
import useModal from "../../components/modal/useModal";
import ModalContainer from "../../components/ModalContainer";
import parseData from "../../components/texts/parseData";
import KeyboardDatePicker from "../inputs/KeyboardDatePicker";
import ModalMapped from "./ModalMapped";

const tableHeadings = [
  { name: "Batch Id", size: "15%", id: "batch_id" },
  { name: "POSID", size: "15%", id: "certification_id" },
  { name: "POSdate", size: "15%", id: "pos_date" },
  { name: "Trade Id", size: "15%", id: "trade_id" },
  { name: "Shipment (nomination) Sale", size: "15%", id: "shipment_sale_nomination" },
  { name: "Counter Party", size: "15%", id: "counterparty" },
  { name: "Vessel", size: "15%", id: "vessel" },
  { name: "Load Port", size: "15%", id: "load_port" },
  { name: "Discharge Port", size: "15%", id: "storage_terminal" },
  { name: "B/L Date", size: "15%", id: "b_l_date" },
  { name: "Renewable Component", size: "15%", id: "biofuel_type" },
  { name: "Quantity (m3)", size: "15%", id: "volume" },
  { name: "UoM", size: "15%", id: "uom" },
  { name: "Origin of Feedstock", size: "15%", id: "raw_material" },
  { name: "Country of Origin of Feedstock", size: "8%", id: "raw_material_origin" },
  { name: "Certificate", size: "15%", id: "certificate" },
  { name: "Site Start", size: "15%", id: "site_start" },
  { name: "Eec", size: "15%", id: "eec" },
  { name: "El", size: "15%", id: "el" },
  { name: "Ep", size: "15%", id: "ep" },
  { name: "Etd", size: "15%", id: "etd" },
  { name: "Eu", size: "15%", id: "eu" },
  { name: "Esca", size: "15%", id: "esca" },
  { name: "Eccs", size: "15%", id: "eccs" },
  { name: "Eccr", size: "15%", id: "eccr" },
  { name: "Eee", size: "15%", id: "eee" },
  { name: "Etot", size: "15%", id: "etot" },
  { name: "GHG Savings (%)", size: "15%", id: "ghg_savings" },
  { name: "Supplier", size: "15%", id: "supplier" },
  { name: "Customer", size: "15%", id: "customer" },
  { name: "Storage Date", size: "15%", id: "storage_date" },
  { name: "Comment", size: "15%", id: "comment" },
  { name: "RED Version", size: "15%", id: "red_version" },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContent: {
      padding: theme.spacing(2, 3),
    },
    red: {
      color: "red"
    },
    titleBar: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    inputSearch: {
      "& .MuiInput-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: "2px 8px",
      },
    },
    tableEmpltyRow: {
      "& .MuiTableCell-root": {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
      },
    },
    iconDelete: {
      color: theme.palette.error.main,
    },
    linkDownload: {
      color: "inherit",
      paddingTop: theme.spacing(0.5),
    },
    modalContent: {},
    container: {
      padding: theme.spacing(2),
    },
    btnContainer: {
      padding: theme.spacing(2, 0),
      marginBottom: theme.spacing(2),
    },
    btnCancel: {},
    tableContainer: {
      overflowY: "auto",
      "& .MuiOutlinedInput-input": {
        ...theme.typography.caption,
      },
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        ...theme.typography.caption,
      },
      "& .MuiTableCell-head": {
        ...theme.typography.caption,
        fontWeight: 500,
      },
    },
    root: {
      width: "100%",
    },
    content: {
      display: "flex",
    },
    noData: {
      width: "100%",
      height: "300px",
      border: `1px solid ${theme.palette.grey[200]}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    noDataText: {
      color: theme.palette.grey[300],
    },
    sideGradient: {
      top: "0px",
      width: "34px",
      marginLeft: "-24px",
      background:
        "linear-gradient(90deg, rgb(255, 255, 255, 1) 30%, rgb(255, 255, 255, 0))",
      zIndex: 100,
    },
    sideButtons: {
      top: "0px",
      width: "80px",
      boxShadow: "-5px -5px 5px 0 rgba(30, 30, 30, 0.05)",
      color: theme.palette.primary.main,
      backgroundColor: "#fdefd9",
      textAlign: "center",
      zIndex: 1,
    },
    sideButtonHead: { height: "75px", width: "70px" },
    sideButton: { height: "35px", width: "70px" },
    menu: {
      height: "300px",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(1, 2),
      overflowY: "scroll",
    },
    menuTitle: {
      marginBottom: theme.spacing(2),
    },
    menuLabel: { color: theme.palette.primary.main },
    menuLabelSelected: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    tableWrapperSmart: {
      marginLeft: "-28px",
      paddingLeft: "19px",
      marginRight: "-2px",
      width: "100%",
      overflowX: "scroll",
    },
    tableWrapper: {
      width: "100%",
    },
    columnTitle: {
      fontSize: "12px",
      fontWeight: "bold",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    textField: {
      margin: 0,
    },
    textFieldColored: {
      margin: 0,
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
      "& .MuiOutlinedInput-root:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline":
      {
        borderColor: theme.palette.primary.main,
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
    },
    toggleIcon: {
      borderRadius: "100%",
      margin: -5,
      padding: 0,
      marginLeft: 0.5,
      transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    select: {
      width: "150px",
      [theme.breakpoints.down("md")]: {
        width: "100px",
      },
    },
    fixedWidth: {
      width: theme.spacing(22),
    },
    headerCalender: {
      position: "relative",
      display: "inline-block",
      top: "10px",

      "& fieldset": {
        display: "none",
      },
      "& input": {
        display: "none",
      }
    },
    creatableSelect: {
      ...theme.typography.body1,

      "& .react-select__control": {
        padding: 0,
        borderColor: theme.palette.grey[300],
        boxShadow: "none",
        minHeight: "35px",
        "&:hover": {
          borderColor: theme.palette.grey[300],
        },
      },
      "& .react-select__placeholder": {
        color: theme.palette.grey[300],
      },
      "& .react-select__value-container": {
        padding: "0px 8px",
      },
      "& .react-select__single-value": {
        color: theme.palette.primary.main,
      },
      "& .react-select__indicator-separator": {
        backgroundColor: "unset",
      },
      "& .react-select__indicators": {
        color: theme.palette.primary.main,
      },
      "& .react-select__option": {
        "&:active": {
          backgroundColor: "rgba(0, 0, 0, 0.14)",
        },
      },
      "& .react-select__option--is-focused": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "& .react-select__option--is-selected": {
        backgroundColor: "rgba(0, 0, 0, 0.14)",
        color: theme.palette.text.primary,
      },
    },
  })
);

interface ModalDeliveryTableProps {
  open: boolean;
  onClose: () => void;
  formSubmit: () => void;
  title: string;
  data: any;
  year: number;
  m3: boolean;
}

const ModalDeliveryTable: React.FC<any> = ({
  open,
  onClose,
  title,
  formSubmit,
  data,
  year,
  m3,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [isUnmapped, setIsUnmapped] = useState<boolean>(false);
  const [infoModal, setInfoModal] = useState({ title: "", description: "" });
  const [showModalMapped, setShowModalMapped] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [volumeType, setVolumeType] = useState<string>("");
  const [totalVolume, setTotalVolume] = useState(0);
  const [fileData, setFileData] = useState<any[]>([]);
  const [errorData, setErrorData] = useState<any[]>([]);
  const [allCustomerList, setAllCustomerList] = useState<any[]>([]);
  const [allSupplierList, setAllSupplierList] = useState<any[]>([]);
  const [unMappedData, setUnMappedData] = useState<any[]>([]);
  const [unMappedDataCount, setUnMappedDataCount] = useState<number>(0);
  const classes = useStyles();
  const { openModalId, toggle } = useModal();

  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      root: { padding: theme.spacing(1) },
      head: {
        whiteSpace: "break-spaces",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.grey[300]}`,
        fontWeight: 500,
      },
      body: {
        whiteSpace: "nowrap",
        fontSize: 12,
        border: `1px solid ${theme.palette.grey[300]}`,
      },
    })
  )(TableCell);

  const openModal = (id: string, text: string, message: string) => {
    if (id === "info") {
      setInfoModal({ title: text, description: message });
    }
    toggle(id);
  };

  const closeModal = () => {
    toggle();
  };

  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.background.default,
        },
      },
    })
  )(TableRow);

  const handleFileDataSubmit = async (values: any) => {
    formSubmit(values);
  };

  const updateDate = async (value: any) => {
    for (let i = 0; i <
      fileData.length; i += 1) {
      fileData[i].storage_date = moment(new Date(value)).format("YYYY-MM-DD");
    }
  };

  const calculateTotalVolume = (data: any) => {
    let totalVolume = 0;
    let volumeType = "";
    if (data) {
      for (let i = 0; i < data.length; i = i + 1) {
        totalVolume = totalVolume + data[i].volume;
        volumeType = data[i].uom;
      }
    }
    setTotalVolume(totalVolume);
    setVolumeType(volumeType);

  };

  const checkData = async () => {
    const response = await checkBkvMapData(fileData);
    if (response) {
      setFileData(response.data.data);
      setErrorData(response.data.error_data);
      setUnMappedData(response.data.unmapped_data);
      setUnMappedDataCount(response.data.unmapped_data.count);
      setAllSupplierList(response.data.supplier);
      setAllCustomerList(response.data.customer);
    }
  };

  const closeModalMapped = () => {
    checkData();
    setShowModalMapped(false);
  };

  const getRedVersion = () => {
    const redVersioinList: any[] = [];
    redVersioinList.push("RED");
    redVersioinList.push("RED II");

    fileData.forEach(
      (data: any) =>
        !redVersioinList.includes(data.red_version) && redVersioinList.push(data.red_version)
    );
    return redVersioinList;
  };

  const getCustomerList = () => {
    const customerList: any[] = allCustomerList;
    fileData.forEach(
      (data: any) =>
        !customerList.includes(data.customer) && customerList.push(data.customer)
    );
    return customerList;
  };

  const getSupplierList = () => {
    const supplierList: any[] = allSupplierList;
    fileData.forEach(
      (data: any) =>
        !supplierList.includes(data.supplier) && supplierList.push(data.supplier)
    );
    return supplierList;
  };

  const hasErrors = (error: any) => {
    if (error) {
      if (error.bio_templates && Array.isArray(error.bio_templates)) {
        return error.bio_templates.length > 0 ? true : false;
      }
    }
    return false;
  };

  const required = (value: any) => {
    if (value && value !== "") {
      setIsError(false);
      return undefined;
    } else {
      setIsError(true);
      return "* Value Required";
    }
  };

  const handleDateChange = (value: any, form: any) => {
    // const { name, value } = event.target;
    const values = form.getState().values;

    // @ts-ignore
    values.bio_templates.map(obj => {
      if (!obj.hasOwnProperty("allocated_quantity")) {
        obj.storage_date = moment(new Date(value)).format("YYYY-MM-DD");
      }
      return obj;
    });

    for (let i = 0; i <
      values.bio_templates.length; i += 1) {
      form.change(values.bio_templates[i].storage_date, moment(new Date(value)).format("YYYY-MM-DD"));
    }
    setFileData(values.bio_templates);
    form.change("bio_templates", values.bio_templates);

  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const missingColumnCheck = (data: any) => {
    const newData: any = JSON.parse(data);
    const unFillColumn = new Set();
    for (let i = 0; i < newData.length; i++) {
      if (newData[i]) {
        const temp = Object.keys(newData[i]);
        temp.forEach(function(value, key) {
          unFillColumn.add(value);
        });
      }
    }
    const column = Array.from(unFillColumn);
    return "* Please Fill " + column + " Column";
  };

  useEffect(() => {
    setFileData(data.data);
    setErrorData(data.error_data);
    setUnMappedData(data.unmapped_data);
    setAllSupplierList(data.supplier);
    setAllCustomerList(data.customer);
    if (data.unmapped_data && data.unmapped_data.count) {
      setUnMappedDataCount(data.unmapped_data.count);
    }
    calculateTotalVolume(data.data);
  }, [data]);

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={title}
      maxWidth="xl"
    >
      <div className={classes.modalContent}>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            {/* Files Table */}
            <Form
              onSubmit={handleFileDataSubmit}
              mutators={{ ...arrayMutators }}
              initialValues={{
                bio_templates: fileData,
              }}
            >
              {({
                handleSubmit,
                submitting,
                pristine,
                errors,
                form
              }) => (
                <form onSubmit={handleSubmit} onKeyDown={onKeyDown}>
                  <Grid container spacing={2} className={classes.btnContainer}>
                    <Grid item xs={4}>
                      <ButtonRounded
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          setShowModalMapped(true);
                        }}
                        disabled={unMappedDataCount < 1}
                      >
                        Map Data
                      </ButtonRounded>
                    </Grid>
                    <Grid item xs={4}>
                      <ButtonRounded
                        fullWidth
                        variant="contained"
                        color="secondary"
                        type="submit"
                        disabled={unMappedDataCount > 0 || submitting || hasErrors(errors)}
                      >
                        Import Data
                      </ButtonRounded>
                    </Grid>
                  </Grid>

                  <pre className={classes.red}> {errors ? (JSON.stringify(errors.bio_templates) ? missingColumnCheck(JSON.stringify(errors.bio_templates)) : null) : null} </pre>

                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="enhanced table"
                  >
                    <TableHead>
                      <TableRow>
                        {tableHeadings.map((headCell, index) =>
                          <StyledTableCell
                            key={headCell.id + "headCell" + index}
                            style={{
                              width: headCell.size,
                              minWidth: "150px",
                              textAlign: "center"
                            }}
                          >
                            <Typography className={classes.columnTitle}>
                              {headCell.name}
                              {headCell.id === "storage_date" &&
                                <Field name={`storage_date`}>
                                  {({ input, meta }) => (
                                    <div className={classes.headerCalender}>
                                      <KeyboardDatePicker
                                        {...input}
                                        inputVariant="outlined"
                                        format="YYYY-MM-DD"
                                        placeholder={moment().format("YYYY-MM-DD")}
                                        onChange={(value: any) => {
                                          handleDateChange(value, form);
                                          input.onChange(moment(new Date(value)).format("YYYY-MM-DD"));
                                          // setFileData(fileData);
                                        }}
                                        invalidDateMessage={null}
                                      />
                                      {meta.error && meta.touched && (
                                        <span className={classes.red}>{meta.error}</span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              }
                            </Typography>

                          </StyledTableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <FieldArray name="bio_templates" >
                        {({ fields }) =>
                          fields.map((name, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>{fileData[index].batch_id}</TableCell>
                                <TableCell>{fileData[index].certification_id}</TableCell>
                                <TableCell>{moment(fileData[index].pos_date).format("DD-MM-YYYY")}</TableCell>
                                <TableCell>{fileData[index].trade_id}</TableCell>
                                <TableCell>{fileData[index].shipment_sale_nomination}</TableCell>
                                <TableCell>{fileData[index].counterparty}</TableCell>
                                <TableCell>{fileData[index].vessel}</TableCell>
                                <TableCell>{fileData[index].load_port}</TableCell>
                                <TableCell>{fileData[index].storage_terminal}</TableCell>
                                <TableCell>{moment(fileData[index].b_l_date).format("DD-MM-YYYY")}</TableCell>
                                <TableCell>
                                  <span className={
                                    errorData[index].biofuel_type_map ? classes.red : ""
                                  }>
                                    {fileData[index].biofuel_type}
                                  </span>
                                </TableCell>
                                <TableCell>{parseData(fileData[index].volume, 3)}</TableCell>
                                <TableCell>{fileData[index].uom}</TableCell>
                                <TableCell>
                                  <span className={
                                    errorData[index].raw_materials_map ? classes.red : ""
                                  }>
                                    {fileData[index].raw_material}
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span className={
                                    errorData[index].raw_material_origin_map ? classes.red : ""
                                  }>
                                    {fileData[index].raw_material_origin}
                                  </span>
                                </TableCell>
                                <TableCell>{fileData[index].certificate}</TableCell>
                                <TableCell>{fileData[index].site_start}</TableCell>
                                <TableCell>{parseData(fileData[index].eec, 2)}</TableCell>
                                <TableCell>{parseData(fileData[index].el, 2)}</TableCell>
                                <TableCell>{parseData(fileData[index].ep, 2)}</TableCell>
                                <TableCell>{parseData(fileData[index].etd, 2)}</TableCell>
                                <TableCell>{parseData(fileData[index].eu, 2)}</TableCell>
                                <TableCell>{parseData(fileData[index].esca, 2)}</TableCell>
                                <TableCell>{parseData(fileData[index].eccs, 2)}</TableCell>
                                <TableCell>{parseData(fileData[index].eccr, 2)}</TableCell>
                                <TableCell>{parseData(fileData[index].eee, 2)}</TableCell>
                                <TableCell>{parseData(fileData[index].etot, 2)}</TableCell>
                                <TableCell>{parseData(fileData[index].ghg_savings, 2)}</TableCell>
                                <TableCell>
                                  <Field name={`${name}.supplier`}>
                                    {props => (
                                      <CreatableSelect
                                        className={classes.creatableSelect}
                                        classNamePrefix="react-select"
                                        placeholder="Supplier"
                                        components={{
                                          DropdownIndicator: () => <ArrowDropDownIcon />,
                                        }}
                                        options={getSupplierList().map((supplier: any) => ({
                                          value: supplier,
                                          label: supplier,
                                        }))}
                                        value={
                                          props.input.value
                                            ? {
                                              value: props.input.value,
                                              label: props.input.value,
                                            }
                                            : null
                                        }
                                        onChange={(value: any) => {
                                          props.input.onChange(value.label);
                                        }}
                                      />
                                    )}
                                  </Field>
                                </TableCell>
                                <TableCell>
                                  <Field name={`${name}.customer`}>
                                    {props => (
                                      <CreatableSelect
                                        className={classes.creatableSelect}
                                        classNamePrefix="react-select"
                                        placeholder="Customer"
                                        components={{
                                          DropdownIndicator: () => <ArrowDropDownIcon />,
                                        }}
                                        options={getCustomerList().map((customer: any) => ({
                                          value: customer,
                                          label: customer,
                                        }))}
                                        value={
                                          props.input.value
                                            ? {
                                              value: props.input.value,
                                              label: props.input.value,
                                            }
                                            : null
                                        }
                                        onChange={(value: any) => {
                                          props.input.onChange(value.label);
                                        }}
                                      />
                                    )}
                                  </Field>
                                </TableCell>
                                <TableCell>
                                  <Field name={`${name}.storage_date`} validate={required}>
                                    {({ input, meta }) => (
                                      <div>
                                        <KeyboardDatePicker
                                          {...input}
                                          inputVariant="outlined"
                                          format="YYYY-MM-DD"
                                          placeholder={moment().format("YYYY-MM-DD")}
                                          fullWidth
                                          value={input.value ? input.value : null}
                                          onChange={(value: any) => {
                                            input.onChange(moment(new Date(value)).format("YYYY-MM-DD"));
                                          }}
                                        />
                                        {meta.error && meta.touched && (
                                          <span className={classes.red}>{meta.error}</span>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </TableCell>
                                <TableCell>
                                  <Field
                                    name={`${name}.comment`}>
                                    {props => (
                                      <FormattedMessage id="Comment">
                                        {fmsg => (
                                          <TextField
                                            {...props.input}
                                            variant="outlined"
                                            placeholder={fmsg as string}
                                            className={classes.fixedWidth}
                                          />
                                        )}
                                      </FormattedMessage>
                                    )}
                                  </Field>
                                </TableCell>
                                <TableCell>
                                  <Field name={`${name}.red_version`}>
                                    {props => (
                                      <CreatableSelect
                                        className={classes.creatableSelect}
                                        classNamePrefix="react-select"
                                        placeholder="RED Version"
                                        components={{
                                          DropdownIndicator: () => <ArrowDropDownIcon />,
                                        }}
                                        options={getRedVersion().map((red_version: any) => ({
                                          value: red_version,
                                          label: red_version,
                                        }))}
                                        value={
                                          props.input.value
                                            ? {
                                              value: props.input.value,
                                              label: props.input.value,
                                            }
                                            : null
                                        }
                                        onChange={(value: any) => {
                                          props.input.onChange(value.label);
                                        }}
                                      />
                                    )}
                                  </Field>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        }
                      </FieldArray>

                      <TableRow>
                        <TableCell colSpan={10}></TableCell>
                        <StyledTableCell>
                          <Typography className={classes.columnTitle}>
                            {"Total Volume"}
                          </Typography>
                        </StyledTableCell>

                        <StyledTableCell>
                          <Typography className={classes.columnTitle}>
                            {parseData(totalVolume, 3)}
                          </Typography>
                        </StyledTableCell>

                        <StyledTableCell>
                          <Typography className={classes.columnTitle}>
                            {volumeType}
                          </Typography>
                        </StyledTableCell>

                        <TableCell colSpan={20}></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </form>
              )}
            </Form>
          </Grid>
        </Grid>
      </div>
      <ModalMapped
        handleSubmit={closeModalMapped}
        open={showModalMapped}
        title={`Map Data`}
        initVariable={{
          ...unMappedData
        }}
        onClose={closeModalMapped}
      />
      <ModalInfo
        text={infoModal.title}
        message={infoModal.description}
        open={openModalId === "info"}
        onClose={closeModal}
      />
    </ModalContainer>
  );
};

export default ModalDeliveryTable;
