/**
 *
 */
import React, { Fragment } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Tooltip from "../../../../../../components/Tooltip";
import parseData from "../../../../../../components/texts/parseData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    green: {
      position: "relative",
      width: "20px",
      height: "20px",
      borderRadius: "2px",
      background: "linear-gradient(to bottom, #0080008a 0%, green 100%)",
      display: "block",
    },
    red: {
      position: "relative",
      width: "20px",
      height: "20px",
      borderRadius: "2px",
      background: "linear-gradient(to bottom, #ff00007d 0%, red 100%)",
      display: "block",
    },
    yellow: {
      position: "relative",
      width: "20px",
      height: "20px",
      borderRadius: "2px",
      background: "linear-gradient(to bottom, #ffd588 0%, #ffac12 100%)",
      display: "block",
    },
    div: {
      display: "flex",
      alignItems: "centre",
    },
    title: {
      paddingLeft: "8px",
      fontWeight: "bold",
      paddingRight: "20px",
      [theme.breakpoints.down("md")]: {
        paddingRight: "10px",
      },
    },
  })
);

export interface IIndicator {
  status: boolean;
  text: string;
  detailedNumbers: number[];
}

interface Props {
  construction_quantities_fame: any;
  construction_quantities_hvo: any;
  construction_quantities_etanol: any;
  construction_quantities_nafta: any;
}

const IndicatorBox: React.FC<Props> = ({
  construction_quantities_fame,
  construction_quantities_hvo,
  construction_quantities_etanol,
  construction_quantities_nafta,
}) => {
  const classes = useStyles();

  const getStatus = (remaining: any) => {
    if (remaining > 0) { return "Not Complete"; }
    if (remaining < 0) { return "Overallocated"; }
    return "Complete";
  };

  const indicatorBoxList3 =
    construction_quantities_fame && construction_quantities_hvo
      ? [
          {
            status: getStatus(construction_quantities_fame.remaining),
            text: "FAME Requirements",
            detailedNumbers: [
              construction_quantities_fame.allocated,
              construction_quantities_fame.requirement,
            ],
          },
          {
            status: getStatus(construction_quantities_hvo.remaining),
            text: "HVO Requirements",
            detailedNumbers: [
              construction_quantities_hvo.allocated,
              construction_quantities_hvo.requirement,
            ],
          },
          {
            status: getStatus(construction_quantities_etanol.remaining),
            text: "Etanol Requirements",
            detailedNumbers: [
              construction_quantities_etanol.allocated,
              construction_quantities_etanol.requirement,
            ],
          },
          {
            status: getStatus(construction_quantities_nafta.remaining),
            text: "Nafta Requirements",
            detailedNumbers: [
              construction_quantities_nafta.allocated,
              construction_quantities_nafta.requirement,
            ],
          },
        ]
      : [];

  const getText = (numbers: any[]) => {
    const number1 = parseData(numbers[0]);
    const number2 = parseData(numbers[1]);
    return `${number1} of ${number2}`;
  };

  return (
    <div className={classes.div}>
      {Array.isArray(indicatorBoxList3) &&
        indicatorBoxList3.map((item, idx) => (
          <Fragment key={idx}>
            <Tooltip title={getText(item.detailedNumbers)}>
              <span
                className={clsx({
                  [classes.green]: item.status === "Complete",
                  [classes.red]: item.status === "Not Complete",
                  [classes.yellow]: item.status === "Overallocated",
                })}
              ></span>
            </Tooltip>
            <Typography variant="body2" className={classes.title}>
              {item.text}
            </Typography>
          </Fragment>
        ))}
    </div>
  );
};

export default IndicatorBox;
