import { getToken, getTokenData } from "./utils";
import { BASE_URL } from "./endpoints";

export interface IProfileData {
  valid?: boolean;
  userID: number;
  ownershipID: number;
  username: string;
  company: string;
  role: string;
  email: string;
}

export function isProfileData(object: any): object is IProfileData {
  return (
    object.userID !== undefined &&
    object.ownershipID !== undefined &&
    object.username !== undefined &&
    object.company !== undefined &&
    object.role !== undefined &&
    object.email !== undefined
  );
}

export async function profileGetInfo() {
  const token = getToken();

  let data: IProfileData = {
    userID: 0,
    ownershipID: 0,
    username: "",
    company: "",
    role: "",
    email: "",
  };

  let valid = false;
  let profileResult;

  if (token) {
    const tokenData: any = getTokenData();
    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/users/${tokenData.user_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token || "",
          },
        }
      );
      profileResult = await response.json();
      valid =
        profileResult &&
        profileResult.success &&
        profileResult.users &&
        profileResult.users.merchant;

      data = {
        userID: profileResult.users.id,
        ownershipID: profileResult.users.merchant.id,
        username: profileResult.users.user_name,
        company: profileResult.users.merchant.name,
        role: profileResult.users.role,
        email: profileResult.users.merchant.email,
      };
    } catch (except) {
      valid = false; // network error
    }
  } else {
    return null; // No token
  }

  if (!valid) {
    if (profileResult && profileResult.errors) {
      return {
        valid: false,
        message: profileResult.errors,
      };
    }
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    ...data,
  };
}
