/**
 * Upload a file to excise duty reporting
 * accepts both transaction/excise duty report file
 */
import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "../utils";
import { BASE_URL } from "../endpoints";

interface IUploadEDFile {
  file: File;
  year: number;
  month: number;
  type: string; // "transaction" | "report";
}

const uploadEDFile = async ({
  file,
  year,
  month,
  type,
}: IUploadEDFile): Promise<{ success: boolean, msg: String, data: any[] } | null> => {
  const token = getToken();
  if (token) {
    const fd = new FormData();
    fd.append("csv_file", file);
    fd.append("tax_year", String(year));
    fd.append("month", String(month));

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const res = await axios.post(`${BASE_URL}/api/${type}`, fd, config);
      if (res.status === 200 && res.data.success) {
        if (res.data.data && Array.isArray(res.data.data)) {
          return { success: res.data.success, msg: "", data: res.data };
        } else {
          return { success: res.data.success, msg: "Your data field is Empty", data: [] };
        }
      } else {
        return { success: res.data.success, msg: res.data.error, data: res.data };
      }
    } catch {
      return { success: false, msg: "Your Action is failed. Please try Again", data: [] };
    }

  } else {
    return null; // No token
  }

};

export default uploadEDFile;
