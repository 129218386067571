import { ErrorPayload } from "../../../../common/payloads/ErrorActionPayload";
import { Action, handleActions } from "redux-actions";
import fetchConstructionDieselVolumesAPI from "../../../../api/sustainability/constructionDieselVolumes/get";
import createConstructionDieselVolumesAPI from "../../../../api/sustainability/constructionDieselVolumes/create";
import editConstructionDieselVolumesAPI from "../../../../api/sustainability/constructionDieselVolumes/edit";
import deleteConstructionDieselVolumesAPI from "../../../../api/sustainability/constructionDieselVolumes/delete";

// State Interface
export interface ConstructionDieselVolumesState {
  isFetching: boolean;
  data: any;
  error?: any;
}

// Initial state
const initialState = {
  isFetching: false,
  data: {},
  error: undefined,
} as ConstructionDieselVolumesState;

// Actions
const CONSTRUCTION_DIESEL_VOLUMES_REQUEST =
  "sustainability/constructionDieselVolumes/REQUEST";
const CREATE_CONSTRUCTION_DIESEL_VOLUMES_REQUEST =
  "sustainability/constructionDieselVolumes/CREATE_CONSTRUCTION_DIESEL_VOLUMES_REQUEST";
const UPDATE_CONSTRUCTION_DIESEL_VOLUMES_REQUEST =
  "sustainability/constructionDieselVolumes/UPDATE_CONSTRUCTION_DIESEL_VOLUMES_REQUEST";
const DELETE_CONSTRUCTION_DIESEL_VOLUMES_REQUEST =
  "sustainability/constructionDieselVolumes/DELETE_CONSTRUCTION_DIESEL_VOLUMES_REQUEST";
const CONSTRUCTION_DIESEL_VOLUMES_ERROR =
  "sustainability/constructionDieselVolumes/ERROR";
const CONSTRUCTION_DIESEL_VOLUMES_SUCCESS =
  "sustainability/constructionDieselVolumes/SUCCESS";
const CREATE_CONSTRUCTION_DIESEL_VOLUMES_SUCCESS =
  "sustainability/constructionDieselVolumes/CREATE_CONSTRUCTION_DIESEL_VOLUMES_SUCCESS";
const UPDATE_CONSTRUCTION_DIESEL_VOLUMES_SUCCESS =
  "sustainability/constructionDieselVolumes/UPDATE_CONSTRUCTION_DIESEL_VOLUMES_SUCCESS";
const DELETE_CONSTRUCTION_DIESEL_VOLUMES_SUCCESS =
  "sustainability/constructionDieselVolumes/DELETE_CONSTRUCTION_DIESEL_VOLUMES_SUCCESS";

// Action creators
function requestConstructionDieselVolumes() {
  return {
    type: CONSTRUCTION_DIESEL_VOLUMES_REQUEST,
  };
}

function requestCreateConstructionDieselVolumes() {
  return {
    type: CREATE_CONSTRUCTION_DIESEL_VOLUMES_REQUEST,
  };
}

function requestUpdateConstructionDieselVolumes() {
  return {
    type: UPDATE_CONSTRUCTION_DIESEL_VOLUMES_REQUEST,
  };
}

function requestDeleteConstructionDieselVolumes() {
  return {
    type: DELETE_CONSTRUCTION_DIESEL_VOLUMES_REQUEST,
  };
}

function errorConstructionDieselVolumes(error: any) {
  return {
    type: CONSTRUCTION_DIESEL_VOLUMES_ERROR,
    payload: { error },
  };
}

function receiveConstructionDieselVolumes(data: any) {
  return {
    type: CONSTRUCTION_DIESEL_VOLUMES_SUCCESS,
    payload: data,
  };
}

function receiveCreateConstructionDieselVolumes(data: any) {
  return {
    type: CREATE_CONSTRUCTION_DIESEL_VOLUMES_SUCCESS,
    payload: data,
  };
}

function receiveUpdateConstructionDieselVolumes(data: any) {
  return {
    type: UPDATE_CONSTRUCTION_DIESEL_VOLUMES_SUCCESS,
    payload: data,
  };
}

function receiveDeleteConstructionDieselVolumes() {
  return {
    type: DELETE_CONSTRUCTION_DIESEL_VOLUMES_SUCCESS,
  };
}

export function getConstructionDieselVolumes() {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.sustainability.allocation.constructionDieselVolumes) {
      dispatch(requestConstructionDieselVolumes());
      try {
        const response = await fetchConstructionDieselVolumesAPI();
        dispatch(receiveConstructionDieselVolumes(response.data));
      } catch (error) {
        dispatch(errorConstructionDieselVolumes(error));
      }
    }
    return Promise.resolve;
  };
}

export function createConstructionDieselVolumes() {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.sustainability.allocation.constructionDieselVolumes) {
      dispatch(requestCreateConstructionDieselVolumes());
      try {
        const response = await createConstructionDieselVolumesAPI({
          etanol: 0,
          nafta: 0,
          fame: 0,
          hvo_hro: 0,
        });
        dispatch(receiveCreateConstructionDieselVolumes(response.data));
      } catch (error) {
        dispatch(errorConstructionDieselVolumes(error));
      }
    }
    return Promise.resolve;
  };
}

export function updateConstructionDieselVolumes(value: number, type: string) {
  return async (dispatch: any, getState: any) => {
    const constructionDieselVolumes = getState().sustainability.allocation
      .constructionDieselVolumes;
    if (constructionDieselVolumes) {
      dispatch(requestUpdateConstructionDieselVolumes());
      const newConstructionDieselVolumes = {
        ...constructionDieselVolumes.data,
        [type]: value,
      };
      try {
        const response = await editConstructionDieselVolumesAPI(
          newConstructionDieselVolumes,
          constructionDieselVolumes.data.id
        );
        dispatch(receiveUpdateConstructionDieselVolumes(response.data));
      } catch (error) {
        dispatch(errorConstructionDieselVolumes(error));
      }
    }
    return Promise.resolve;
  };
}

export function deleteConstructionDieselVolumes() {
  return async (dispatch: any, getState: any) => {
    const constructionDieselVolumes = getState().sustainability.allocation
      .constructionDieselVolumes;
    if (constructionDieselVolumes) {
      dispatch(requestDeleteConstructionDieselVolumes());
      try {
        const response = await deleteConstructionDieselVolumesAPI(
          constructionDieselVolumes.data.id
        );
        if (response.success) {
          dispatch(receiveDeleteConstructionDieselVolumes());
        }
      } catch (error) {
        dispatch(errorConstructionDieselVolumes(error));
      }
    }
    return Promise.resolve;
  };
}

// Reducer Definition
const ConstructionDieselVolumesReducer = handleActions<ConstructionDieselVolumesState>(
  {
    [CONSTRUCTION_DIESEL_VOLUMES_REQUEST]: handleConstructionDieselVolumesRequest,
    [CONSTRUCTION_DIESEL_VOLUMES_ERROR]: handleConstructionDieselVolumesError,
    [CONSTRUCTION_DIESEL_VOLUMES_SUCCESS]: handleConstructionDieselVolumesSuccess,
    [UPDATE_CONSTRUCTION_DIESEL_VOLUMES_SUCCESS]: handleConstructionDieselVolumesSuccess,
    [CREATE_CONSTRUCTION_DIESEL_VOLUMES_SUCCESS]: handleConstructionDieselVolumesSuccess,
    [DELETE_CONSTRUCTION_DIESEL_VOLUMES_SUCCESS]: handleDeleteConstructionDieselVolumesSuccess,
  } as any,
  initialState
);

// Reducer Description
function handleConstructionDieselVolumesRequest(
  state: ConstructionDieselVolumesState
) {
  return {
    ...state,
    isFetching: true,
    error: undefined as any,
  };
}

function handleConstructionDieselVolumesError(
  state: ConstructionDieselVolumesState,
  action: Action<ErrorPayload>
) {
  return {
    ...state,
    isFetching: false,
    error: action.payload.error,
  };
}

function handleConstructionDieselVolumesSuccess(
  state: ConstructionDieselVolumesState,
  action: Action<any>
) {
  return {
    ...state,
    isFetching: false,
    data: action.payload,
    error: undefined as any,
  };
}

function handleDeleteConstructionDieselVolumesSuccess(
  state: ConstructionDieselVolumesState,
  action: Action<any>
) {
  return {
    ...state,
    isFetching: false,
    data: {},
    error: undefined as any,
  };
}

export default ConstructionDieselVolumesReducer;
