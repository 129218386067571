import React from "react";
import { PDFViewer, Font } from "@react-pdf/renderer";
import CustomerReportV2 from "./CustomerReportV2";
import CustomerReportV2T2 from "./CustomerReportV2/CustomerReportV2T2";
interface Props {
  template2: boolean;
}
const CustomerReportPreview: React.FC<Props> = ({ template2 }) => {
  Font.register({ family: "PreemSans Bold", src: "/fonts/PreemSans-Bold.ttf" });
  Font.register({ family: "PreemSans", src: "/fonts/PreemSans-Regular.ttf" });
  Font.register({
    family: "PreemSans Italic",
    src: "/fonts/PreemSans-Italic.ttf",
  });
  Font.register({
    family: "PreemSans Medium",
    src: "/fonts/PreemSans-Medium.ttf",
  });
  // change the mockup data here
  const data = {
    customer: "Helges Anleggsdrift ASA",
    sign_name: "Ketil Thorsen",
    sign_position: "Daglig leder",
    organization_number: 111777888,
    delivery_period: 2019,
    total_quantity: 100000.0,
    average_ghg_emission_savings: 88.87,
    overall_emission: 287458.0,
    allocations: [
      {
        biotemplate_id: 291,
        biofuel_type: "HVO/HRO",
        raw_material: "Frityrolje -brukt (UCO)",
        double_counted_norway: "Ja",
        raw_material_origin: "Kina",
        reduction_in_climate_emission: 89.06,
        quantity: 81819.0,
      },
      {
        biotemplate_id: 342,
        biofuel_type: "HVO/HRO",
        raw_material: "Frityrolje -brukt (UCO)",
        double_counted_norway: "Ja",
        raw_material_origin: "Japan",
        reduction_in_climate_emission: 88.0,
        quantity: 18181.0,
      },
    ],
    supplied_products: [
      {
        name: "HVO Diesel 100",
        purchased_volume: 100000,
        etanol: 0,
        nafta: 0,
        rme_fame: 0,
        hvo_hro: 100000,
        fossil: 0,
      },
    ],
  };

  return (
    <PDFViewer
      style={{
        width: "100%",
        height: "calc(100vh - 10px)",
      }}
    >
      {template2 ? (
        <CustomerReportV2T2 // template 2
          language="EN"
          data={data}
        />
      ) : (
        <CustomerReportV2 // template 1
          language="NO"
          data={data}
        />
      )}
    </PDFViewer>
  );
};

export default CustomerReportPreview;
