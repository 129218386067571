import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ButtonRounded from "../../../../../../components/ButtonRounded";
import EditableTableContainer from "../../../../../../containers/sustainability/allocation/roadUse/EditableTable";
import startAllocation from "../../../../../../api/sustainability/allocationSteps/startAllocation";
import LoadingView from "./LoadingView";
import MatchingTables from "../../../../../../containers/sustainability/allocation/roadUse/MatchingTables";
import {Typography} from "@material-ui/core";
import { postStepBiotemplatesData, putStepOverViewData } from "../../../../../../api/sustainability/allocation/roadUse/stepData";
import allocationRequirements from "../../../../../../api/sustainability/allocationSteps/allocationRequirements";
import setAllocationRequirement from "../../../../../../api/sustainability/allocationSteps/setAllocationRequirement";
import RoadUseCustomerRequirements from "./RoadUseCustomerRequirements";
import SuccessView from "./SuccessView";
import {changeStep} from "../../../../../../reducers/sustainability/allocation/multiStep";
import { ReactReduxContext, useDispatch } from "react-redux";
import api_setAllocateCustomer from "../../../../../../api/sustainability/allocationSteps/setAllocateCustomer";
import api_allocationConstruction from "../../../../../../api/sustainability/allocationSteps/allocationConstruction";
import {postSetActiveStep} from "../../../../../../api/sustainability/allocation/roadUse/stepData";
import {
    updateBiotemplateStepFive,
    updateBiotemplateStepFour,
    updateBiotemplateStepOne, updateBiotemplateStepThree,
    updateBiotemplateStepTwo
} from "../../../../../../reducers/sustainability/allocation/roaduseAllocation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {},
    btnContainer: {
      marginBottom: theme.spacing(3),
    },
    btn: {
      paddingTop: theme.spacing(0.8),
      paddingBottom: theme.spacing(0.8),
      minWidth: "110px",
      "&:first-child": {
        marginRight: theme.spacing(2),
      },
    },
    btnDark: {
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
    },
    cardContent: {
      maxHeight: "calc(100vh - 250px)",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),
      paddingBottom: 0,
    },
    overviewPanel: {
      backgroundColor: theme.palette.common.white,
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
      marginBottom: theme.spacing(-3),
    },
  })
);

interface RoadUseCardEditableTableProps {
  step: number;
  handleNext: () => void;
  handleBack: () => void;
  updateIsFetching: (value: boolean) => void;
  availableVol: any;
  biotemplates: any[];
  stepOneData: any[];
  stepTwoData: any[];
  stepThreeData: any[];
  stepFourData: any[];
  stepFiveData: any[];
}

interface Biotemplate {
    id: number;
    biofuel_type: string;
    raw_material_origin: string;
    fuel_type: string;
    raw_material: string;
    double_counted_norway: string;
    reduction_in_climate_emission: number;
    storage_terminal: string;
    available_quantity: number;
    allocated_quantity: number;
    allocated_eq_quantity: number;
}

interface Requirement {
    sub_requirements_biocomponents_in_gasoline: any;
    sub_requirement_advanced_biofuels: any;
    general_requirement: any;
    remaining_quantity: any;
}

interface UpdatedDataProps {
    biotemplates?: any[];
    overview?: any[];
    id?: number;
}

const RoadUseCardEditableTable: React.FC<RoadUseCardEditableTableProps> = ({
  step,
  handleBack,
  handleNext,
  updateIsFetching,
  availableVol,
  biotemplates,
  stepOneData,
  stepTwoData,
  stepThreeData,
  stepFourData,
  stepFiveData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [updatedData, setUpdatedData] = useState<any[]>([]);
  const [updatedCustomerData, setUpdatedCustomerData] = useState([]);
  const [requirementData, setRequirementData] = useState<Requirement>({
        sub_requirements_biocomponents_in_gasoline: {},
        sub_requirement_advanced_biofuels: {},
        general_requirement: {},
        remaining_quantity: {}
    });
  const handleTableDataUpdate = (updatedData: UpdatedDataProps, type: string) => {
        if (type === "bio") {
            console.log("Updating biotemplates:", updatedData.biotemplates);
            setUpdatedData(prevData => ({
                ...prevData,
                // @ts-ignore
                biotemplates: updatedData.biotemplates,
            }));
        } else {
            setUpdatedData(prevData => ({
                ...prevData,
                // @ts-ignore
                overview: updatedData.overview,
                id: updatedData.id,
            }));
        }

    };

  const handleCustomerDataUpdate = (updatedCustomerData: any[]) => {
        // @ts-ignore
        setUpdatedCustomerData(updatedCustomerData);
    };

  const handleRequirementDataUpdate = (requirementData: Requirement) => {
        // @ts-ignore
        setRequirementData(requirementData);
    };

  const clickBack = () => {
        handleBack();
    };

  const clickNext = async () => {

    let brk = 0
    if (step < 6) {
      const biotemplateUpdatedData = {
        // @ts-ignore
        biotemplates : updatedData.biotemplates,
      };

      biotemplateUpdatedData.biotemplates.forEach((item: { allocated_quantity: string; available_quantity: number; }) => {
        if(parseInt(item.allocated_quantity) > item.available_quantity ){
          brk += 1
        }
      })
      if(brk){
        alert("One or more allocated batches have available volumes exceeded.")
        return
      }

      updateIsFetching(true);
      if (step == 0){

            }
            if (step == 1){
                dispatch(updateBiotemplateStepOne(biotemplateUpdatedData.biotemplates))
            }
            if (step == 2){
                dispatch(updateBiotemplateStepTwo(biotemplateUpdatedData.biotemplates))
            }
            if (step == 3){
                dispatch(updateBiotemplateStepThree(biotemplateUpdatedData.biotemplates))
            }
            if (step == 4){
                dispatch(updateBiotemplateStepFour(biotemplateUpdatedData.biotemplates))
            }
            if (step == 5){
                dispatch(updateBiotemplateStepFive(biotemplateUpdatedData.biotemplates))
            }
            const setActiveStep = await postSetActiveStep(step)
            const response = await postStepBiotemplatesData(step, biotemplateUpdatedData);
            if (response && response.success) {
                if (step === 5) {
                    // @ts-ignore
                    const reqSetResponse = await setAllocationRequirement(
                        requirementData.sub_requirements_biocomponents_in_gasoline,
                        requirementData.sub_requirement_advanced_biofuels,
                        requirementData.general_requirement,
                        requirementData.remaining_quantity
                    );
                    if (reqSetResponse && reqSetResponse.valid) {
                        handleNext();
                    } else {
                        alert("Some error occurred");
                    }
                } else {
                    // @ts-ignore
                    const overviewData = { ...updatedData.overview };
                    // @ts-ignore
                    overviewData.id = updatedData.id;

                    const putResponse = await putStepOverViewData(step, overviewData);
                    handleNext();
                }
                updateIsFetching(false);
            } else {
                updateIsFetching(false);
                alert("Some error occurred");
            }
        } else if (step === 7) {
            // console.error("updatedCustomerData");
            // console.error(updatedCustomerData);
            updateIsFetching(true);
            const getCustomerReqAllocated = () => {
                // @ts-ignore
                const allocated_sorted = updatedCustomerData.allocated_sorted;
                let allocated: any = {};
                if (allocated_sorted && Object.keys(allocated_sorted).length > 0) {
                    Object.keys(allocated_sorted).map((cust_id, cust_idx) => {
                        Object.keys(allocated_sorted[cust_id]).map((an_id, an_idx) => {
                            allocated = {
                                ...allocated,
                                [an_id]: {
                                    ...allocated_sorted[cust_id][an_id],
                                },
                            };
                        });
                    });
                }
                return allocated;
            };
            const apiAllocated = getCustomerReqAllocated();

            try {
                const response = await api_setAllocateCustomer(apiAllocated);
                if (response.valid) {
                    updateIsFetching(false);
                    handleNext();
                } else {
                    updateIsFetching(false);
                    alert("Some error occured when submitting data.");
                }
            } catch (error) {
                updateIsFetching(false);
                alert("Some error occured when submitting data.");
            }
        } else {
            handleNext();
        }
    };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                  className={classes.btnContainer}
                >
                  <Grid item>
                  </Grid>
                  <Grid item>
                    {step > 0 && (
                      <ButtonRounded
                        variant="contained"
                        className={clsx(classes.btn, classes.btnDark)}
                        onClick={clickBack}

                      >
                        Back
                      </ButtonRounded>
                    )}
                    <ButtonRounded
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                      onClick={clickNext}
                      disabled={step === 8}
                    >
                      Next
                    </ButtonRounded>
                  </Grid>
                </Grid>
                  <Grid
                      container
                      alignItems="center"
                      justify="flex-start"
                  >
                      <Grid item>
                          {step === 1 && (
                              <>
                                  <Typography variant="h4">Allocation of gasoline sub-mandate (4%) for road use volumes</Typography>
                                  <Typography variant="body2" >
                                      In this step you will allocate the sustainability criteria for the required volumes of biocomponents in gasoline to fulfill the gasoline sub-requirement to the road use mandate. If additional gasoline volumes are supplied to the market, this will be allocated in step 3 (advanced requirement) and/or step 4 (all other volumes).
                                  </Typography>
                                  <br /><br />
                              </>
                              )}
                          {step === 2 && (
                              <>
                                  <Typography variant="h4">Allocation of advanced sub-mandate (12%) for road use volumes</Typography>
                                  <Typography variant="body2" >
                                      In this step you will allocate the sustainability criteria for the required minimum volumes of advanced biocomponents (both gasoline and diesel) to fulfill the advanced sub-requirement to the road use mandate. If additional advanced volumes are supplied to the market, this will be allocated in step 4 (all other volumes).
                                  </Typography>
                                  <br /><br />
                              </>
                          )}
                          {step === 3 && (
                              <>
                                  <Typography variant="h4">Allocation of general mandate (17%) for road use volumes</Typography>
                                  <Typography variant="body2" >
                                      In this step you will allocate the sustainability criteria for the required volumes (both gasoline and diesel) to fulfill the general requirement to the road use mandate. If additional volumes are supplied to the market, this will be allocated in step 4 (all other volumes).
                                  </Typography>
                                  <br /><br />
                              </>
                          )}
                          {step === 4 && (
                              <>
                                  <Typography variant="h4">Allocation of volumes required by customers to be above the mandate</Typography>
                                  <Typography variant="body2" >
                                      In this step you will allocate the sustainability criteria for the volumes that customers have requested to be above the mandate levels. If there are additional volumes supplied to the market that is a surplus to the mandate levels, this will be allocated in step 6.
                                  </Typography>
                                  <br /><br />
                              </>
                          )}
                          {step === 5 && (
                              <>
                                  <Typography variant="h4">Allocation of additional volumes above the mandate</Typography>
                                  <Typography variant="body2" >
                                      In this step you will allocate the sustainability criteria for all additional volumes above the mandate levels.
                                  </Typography>
                                  <br /><br />
                              </>
                          )}
                      </Grid>
                  </Grid>
                {step === 6 && (
                    <MatchingTables/>
                )}
                  {step === 7 && (
                      <RoadUseCustomerRequirements
                          onUpdateCustomerData={handleCustomerDataUpdate}
                          availableVol={availableVol}
                      />
                  )}
                  {step === 8 && (
                  <SuccessView text={"Allocation process is successful"} />
                  )}
                  {step !== 6 && step !== 7 && step !== 8 && (
                      <EditableTableContainer
                          updateIsFetching={updateIsFetching}
                          onUpdateData={handleTableDataUpdate}
                          onUpdateRequirement={handleRequirementDataUpdate}
                          data={biotemplates}
                          stepOneData={stepOneData}
                          stepTwoData={stepTwoData}
                          stepThreeData={stepThreeData}
                          stepFourData={stepFourData}
                          stepFiveData={stepFiveData}
                          activeStep={step} />
                  )}
      </CardContent>
    </Card>
  );
};

export default RoadUseCardEditableTable;
