import React, {useEffect, useState} from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "./../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/StepLabel";
import StepIcon from "./../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/StepIcon";
import StepButton from "@material-ui/core/StepButton";
import StepConnector from "./../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/StepConnector";
import CustomerRequirements from "./../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/CustomerRequirements";
import LoadingView from "./../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/LoadingView";
import SuccessView from "./../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/SuccessView";
import MainCardTables from "./../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/RoadUseMainCardTables";
import StatusModeType from "./../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/StatusModeType";
import { State } from "../../../../reducers/reducer";
import { useSelector } from "react-redux";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import {hasRoadData} from "../../../../api/sustainability/allocation/roadUse/data";
import startAllocation from "../../../../api/sustainability/allocationSteps/startAllocation";
import allocationRequirements from "../../../../api/sustainability/allocationSteps/allocationRequirements";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      root: {
          display: "flex",
      },
      textContainer: {
          marginRight: theme.spacing(4),
      },
      textPrimary: {
          color: theme.palette.primary.main,
          marginLeft: theme.spacing(1),
      },
    statusModeType: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    stepper: {
      backgroundColor: "inherit",
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: theme.spacing(4),
      width: "calc(100% + 54px)",
      marginLeft: "-30px",
    },
  })
);

interface Biotemplate {
    id: number;
    biofuel_type: string;
    raw_material_origin: string;
    fuel_type: string;
    raw_material: string;
    double_counted_norway: string;
    reduction_in_climate_emission: number;
    storage_terminal: string;
    available_quantity: number;
    allocated_quantity: number;
    allocated_eq_quantity: number;
}

const RoadUseContainer: React.FC = () => {
  const classes = useStyles();

  const isSimulation: any = useSelector((state: State) => state.sustainability.main.isSimulation);
  const isJoint: any = useSelector((state: State) => state.sustainability.main.isJoint);
  const periodName: any = useSelector((state: State) => state.sustainability.main.activePeriod);
  const [availableVol, setAvailableVol] = useState({});
  const [biotemplates, setBiotemplates] = useState([]);
  const [stepOneBiotemplates, setStepOneBiotemplates] = useState([]);
  const [stepTwoBiotemplates, setStepTwoBiotemplates] = useState([]);
  const [stepThreeBiotemplates, setStepThreeBiotemplates] = useState([]);
  const [stepFourBiotemplates, setStepFourBiotemplates] = useState([]);
  const [stepFiveBiotemplates, setStepFiveBiotemplates] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [steps, setSteps] = useState<{}>({
        0: {
            name: "Joint Fulfillment",
            isDirty: false,
            status: "Not Complete",
            isComplete: false,
            isDisabled: true,
            data: [],
            allocated: {},
        },
        1: {
            name: "Gasoline Sub-Mandate",
            isDirty: false,
            status: "Not Complete",
            isComplete: false,
            isDisabled: false,
            data: [],
            allocated: {},
        },
        2: {
            name: "Advanced Sub-Mandate",
            isDirty: false,
            status: "Not Complete",
            isComplete: false,
            isDisabled: false,
            data: [],
            allocated: {},
        },
        3: {
            name: "General Mandate",
            isDirty: false,
            status: "Not Complete",
            isComplete: false,
            isDisabled: false,
            data: [],
            allocated: {},
        },
        4: {
            name: "Reserved above the mandate",
            isDirty: false,
            status: "Not Complete",
            isComplete: false,
            isDisabled: false,
            data: [],
            allocated: {},
        },
        5: {
            name: "Additional volumes above the mandate",
            isDirty: false,
            status: "Not Complete",
            isComplete: false,
            isDisabled: false,
            data: [],
            allocated: {},
        },
        6: {
            name: "Matching",
            isDirty: false,
            status: "Not Complete",
            isComplete: false,
            isDisabled: false,
            data: [],
            allocated: {},
        },
        7: {
            name: "Customer Requirements",
            isDirty: false,
            status: "Not Complete",
            isComplete: false,
            isDisabled: false,
            data: [],
            allocated: {},
        },
        8: {
            name: "Draft Report",
            isDirty: false,
            isDisabled: false,
            status: "Not Complete",
        },
    });

  const fetchBiotemplateData = async () => {
        setIsFetching(true);
        const response = await startAllocation();
        if (response && response.valid) {
            const reqResponse = await allocationRequirements({});
            if (reqResponse && reqResponse.valid) {
                // @ts-ignore
                setStepOneBiotemplates(reqResponse.gasoline_req.biotemplates);
                // @ts-ignore
                setStepTwoBiotemplates(reqResponse.adv_biofuels_req.biotemplates);
                // @ts-ignore
                setStepThreeBiotemplates(reqResponse.general_req.biotemplates);
                // @ts-ignore
                setStepFourBiotemplates(reqResponse.above_req.biotemplates);
            } else {
                const bensinBiotemplates = response.biotemplates.filter((template: Biotemplate) => template.fuel_type === "Biokomponenter for bensin");
                const advancedBiotemplates = response.biotemplates.filter((template: Biotemplate) => template.double_counted_norway === "Ja");
                setStepOneBiotemplates(bensinBiotemplates);
                // @ts-ignore
                setStepTwoBiotemplates(advancedBiotemplates);
                // @ts-ignore
                setStepThreeBiotemplates(response.biotemplates);
                // @ts-ignore
                setStepFourBiotemplates(response.biotemplates);
            }
            const availableData = {};
            // @ts-ignore
            response.biotemplates.forEach((item: Biotemplate) => {
                // @ts-ignore
                availableData[item.biotemplate_id] = item.available_quantity;
            });

            // @ts-ignore
            setAvailableVol(availableData);

            setBiotemplates(response.biotemplates);
            // @ts-ignore
            setStepFiveBiotemplates(response.biotemplates);
            setIsFetching(false);
        } else {
            setIsFetching(false);
            alert("Some error occured");
        }
    };

  const updateIsFetching = (value: boolean) => {
        setIsFetching(value);
    };

  const checkData = async () => {
        const roadUse = await hasRoadData();
        setActiveStep(roadUse ? parseInt(roadUse.last_active_step) > 0 ? parseInt(roadUse.last_active_step) : 1 : 1);
    };

  useEffect(() => {
        checkData();
        fetchBiotemplateData();
    }, []);

  return (
    <>
        <div className={clsx(classes.root)}>
            <div className={classes.textContainer}>
                <Typography variant="h6">
                    Allocation Mode:
                    <span className={classes.textPrimary}>{isSimulation ? "Simulation" : "Reporting"}</span>
                </Typography>
            </div>
            <div className={classes.textContainer}>
                <Typography variant="h6">
                    Period:
                    <span className={classes.textPrimary}>{periodName ? periodName.year : ""}</span>
                </Typography>
            </div>
            <div className={classes.textContainer}>
                <Typography variant="h6">
                    Fulfillment Type:
                    <span className={classes.textPrimary}>{isJoint ? "Joint" : "Own"}</span>
                </Typography>
            </div>
        </div>
        <Stepper
            alternativeLabel
            nonLinear
            activeStep={activeStep}
            connector={<StepConnector />}
            className={classes.stepper}
        >
            {Object.keys(steps).map((rank, index) => (
                <Step key={index}>
                    <StepButton
                        onClick={() => console.log(activeStep)}
                        disabled={steps[rank].isDisabled}
                    >
                        <StepLabel
                            StepIconComponent={props => (
                                <StepIcon
                                    disabled={steps[rank].isDisabled}
                                    status={steps[rank].isDirty ? steps[rank].status : null}
                                    {...props}
                                ></StepIcon>
                            )}
                        >
                            {steps[rank].name}
                        </StepLabel>
                    </StepButton>
                </Step>
            ))}
        </Stepper>
        {isFetching ? (
            <LoadingView text={"Data is getting prepared"} />
        ) : (
            <>
                <MainCardTables
                    step={activeStep}
                    handleNext={() => {
                        if (activeStep > 0 && !steps[activeStep + 1].isDisabled && activeStep) {
                            setActiveStep(activeStep + 1);
                        }
                    }}
                    handleBack={() => {
                        if (activeStep > 0 && !steps[activeStep - 1].isDisabled) {
                            setActiveStep(activeStep - 1);
                        }}}
                    updateIsFetching={updateIsFetching}
                    availableVol={availableVol}
                    biotemplates={biotemplates}
                    stepOneData={stepOneBiotemplates}
                    stepTwoData={stepTwoBiotemplates}
                    stepThreeData={stepThreeBiotemplates}
                    stepFourData={stepFourBiotemplates}
                    stepFiveData={stepFiveBiotemplates}
                />
            </>
        )}
    </>
  );
};

export default RoadUseContainer;
