import React, {useContext, useEffect, useState} from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import parseData from "../../../../components/texts/parseData";
import OverviewTableContainer from "./OverviewTable";
import OverviewPanel
  from "../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/OverviewPanel";
import {Typography} from "@material-ui/core";
import {getStepData} from "../../../../api/sustainability/allocation/roadUse/stepData";
import { ReactReduxContext, useDispatch, useSelector } from "react-redux";
import SmartTable from "../../../../components/SmartTable";
import PrevTable from "./PrevTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      overflowY: "auto",
      "& .MuiOutlinedInput-input": {
        ...theme.typography.caption,
      },
    },
    overviewPanel: {
      backgroundColor: theme.palette.common.white,
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
      marginBottom: theme.spacing(-3),
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        ...theme.typography.caption,
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        ...theme.typography.caption,
        fontWeight: 500,
      },
    },
  })
);

const tableHeadings = [
  { name: "Storage Date", id: "storage_date"},
  { name: "Biofuel Type", id: "biofuel_type" },
  { name: "Origin", id: "raw_material_origin" },
  { name: "Biocomponent Type", id: "fuel_type" },
  { name: "Raw Material", id: "raw_material" },
  { name: "Advanced", id: "double_counted_norway" },
  { name: "Ghg Reduction", id: "reduction_in_climate_emission" },
  { name: "Storage Terminal", id: "storage_terminal" },
  {
    name: "Available Quantity (L)",
    alignRight: true,
    id: "available_quantity",
  },
  {
    name: "Allocated Quantity (L)",
    alignRight: true,
    id: "allocated_quantity",
  },
  // {
  //   name: "Allocated Quantity (LE)",
  //   alignRight: true,
  //   id: "allocated_eq_quantity",
  // },
];

const prevTableHeadings = [
  { name: "Biofuel Type", id: "biofuel_type" },
  { name: "Origin", id: "origin" },
  { name: "Biocomponent Type", id: "biocomponent_type" },
  { name: "Raw Material", id: "raw_material" },
  { name: "Advanced", id: "advanced" },
  { name: "Ghg Reduction", id: "ghg_reduction" },
  { name: "Storage Terminal", id: "storage_terminal" },
  {
    name: "Available Quantity (L)",
    alignRight: true,
    id: "available_quantity",
  },
  {
    name: "Allocated Quantity (L)",
    alignRight: true,
    id: "allocated_quantity",
  },
  // {
  //   name: "Allocated Quantity (LE)",
  //   alignRight: true,
  //   id: "allocated_eq_quantity",
  // },
];

interface UpdatedDataProps {
  biotemplates?: any[];
  overview?: any[];
  id?: number;
}

interface Props {
  data: any[];
  stepOneData: any[];
  stepTwoData: any[];
  stepThreeData: any[];
  stepFourData: any[];
  stepFiveData: any[];
  activeStep: number;
  onUpdateData: (updatedData: UpdatedDataProps, type: string) => void;
  onUpdateRequirement: (requirementData: Requirement) => void;
  updateIsFetching: (value: boolean) => void;
}

interface Requirement {
  sub_requirements_biocomponents_in_gasoline: any;
  sub_requirement_advanced_biofuels: any;
  general_requirement: any;
  remaining_quantity: any;
}

interface OverViewParamProps {
  etanol: number;
  etanol_advanced: number;
  fame: number;
  fame_advanced: number;
  hvo_hro: number;
  hvo_hro_advanced: number;
  nafta: number;
  nafta_advanced: number;
  biogasoline: number;
  biogasoline_advanced: number;
  general_mandate: number;
  gasoline_sub_mandate: number;
  advanced_sub_mandate: number;
  above_mandate_req_by_cust: number;
  above_mandate_after_fulfill: number;
}

interface OverViewParamProps {
  etanol: number;
  etanol_advanced: number;
  fame: number;
  fame_advanced: number;
  hvo_hro: number;
  hvo_hro_advanced: number;
  nafta: number;
  nafta_advanced: number;
  biogasoline: number;
  biogasoline_advanced: number;
  general_mandate: number;
  gasoline_sub_mandate: number;
  advanced_sub_mandate: number;
  above_mandate_req_by_cust: number;
  above_mandate_after_fulfill: number;
}
interface OverViewReqVolParamProps {
  general_mandate: number;
  general_req_vol: number;
  general_rel_vol: number;
  advanced_sub_mandate: number;
  advanced_sub_req_vol: number;
  advanced_sub_rel_vol: number;
  gasoline_sub_mandate: number;
  gasoline_req_vol: number;
  gasoline_rel_vol: number;
  above_mandate_req_by_cust: number;
  etanol: number;
  etanol_advanced: number;
  nafta: number;
  nafta_advanced: number;
  bio_gasoline: number;
  bio_gasoline_advanced: number;
  fame: number;
  fame_advanced: number;
  hvo_hro: number;
  hvo_hro_advanced: number;
  above_mandate_after_fulfill: number;
}

interface OverViewProps {
  req_vol: OverViewReqVolParamProps;
  joint_fulfill: OverViewParamProps;
  allocated_LE: OverViewParamProps;
  allocated_15: OverViewParamProps;
  surplus_from_offroad: OverViewParamProps;
  remaining_vol: OverViewParamProps;
}

const EditableTable: React.FC<Props> = ({
                                          data,
                                          stepOneData,
                                          stepTwoData,
                                          stepThreeData,
                                          stepFourData,
                                          stepFiveData,
                                          activeStep,
                                          onUpdateData,
                                          onUpdateRequirement,
                                          updateIsFetching,
                                        }) => {
  const classes = useStyles();
  const [overViewData, setOverViewData] = useState<OverViewProps>({
    req_vol: {
      general_mandate: 0,
      general_req_vol: 0,
      general_rel_vol: 0,
      advanced_sub_mandate: 0,
      advanced_sub_req_vol: 0,
      advanced_sub_rel_vol: 0,
      gasoline_sub_mandate: 0,
      gasoline_req_vol: 0,
      gasoline_rel_vol: 0,
      above_mandate_req_by_cust: 0,
      etanol: 0,
      etanol_advanced: 0,
      nafta: 0,
      nafta_advanced: 0,
      bio_gasoline: 0,
      bio_gasoline_advanced: 0,
      fame: 0,
      fame_advanced: 0,
      hvo_hro: 0,
      hvo_hro_advanced: 0,
      above_mandate_after_fulfill: 0,
    },
    joint_fulfill: {
      etanol: 0,
      etanol_advanced: 0,
      fame: 0,
      fame_advanced: 0,
      hvo_hro: 0,
      hvo_hro_advanced: 0,
      nafta: 0,
      nafta_advanced: 0,
      biogasoline: 0,
      biogasoline_advanced: 0,
      general_mandate: 0,
      gasoline_sub_mandate: 0,
      advanced_sub_mandate: 0,
      above_mandate_req_by_cust: 0,
      above_mandate_after_fulfill: 0,
    },
    allocated_LE: {
      etanol: 0,
      etanol_advanced: 0,
      fame: 0,
      fame_advanced: 0,
      hvo_hro: 0,
      hvo_hro_advanced: 0,
      nafta: 0,
      nafta_advanced: 0,
      biogasoline: 0,
      biogasoline_advanced: 0,
      general_mandate: 0,
      gasoline_sub_mandate: 0,
      advanced_sub_mandate: 0,
      above_mandate_req_by_cust: 0,
      above_mandate_after_fulfill: 0,
    },
    allocated_15: {
      etanol: 0,
      etanol_advanced: 0,
      fame: 0,
      fame_advanced: 0,
      hvo_hro: 0,
      hvo_hro_advanced: 0,
      nafta: 0,
      nafta_advanced: 0,
      biogasoline: 0,
      biogasoline_advanced: 0,
      general_mandate: 0,
      gasoline_sub_mandate: 0,
      advanced_sub_mandate: 0,
      above_mandate_req_by_cust: 0,
      above_mandate_after_fulfill: 0,
    },
    surplus_from_offroad: {
      etanol: 0,
      etanol_advanced: 0,
      fame: 0,
      fame_advanced: 0,
      hvo_hro: 0,
      hvo_hro_advanced: 0,
      nafta: 0,
      nafta_advanced: 0,
      biogasoline: 0,
      biogasoline_advanced: 0,
      general_mandate: 0,
      gasoline_sub_mandate: 0,
      advanced_sub_mandate: 0,
      above_mandate_req_by_cust: 0,
      above_mandate_after_fulfill: 0,
    },
    remaining_vol: {
      etanol: 0,
      etanol_advanced: 0,
      fame: 0,
      fame_advanced: 0,
      hvo_hro: 0,
      hvo_hro_advanced: 0,
      nafta: 0,
      nafta_advanced: 0,
      biogasoline: 0,
      biogasoline_advanced: 0,
      general_mandate: 0,
      gasoline_sub_mandate: 0,
      advanced_sub_mandate: 0,
      above_mandate_req_by_cust: 0,
      above_mandate_after_fulfill: 0,
    }
  });

  const [responseOverViewData, setResponseOverViewData] = useState<OverViewProps>({
    req_vol: {
      general_mandate: 0,
      general_req_vol: 0,
      general_rel_vol: 0,
      advanced_sub_mandate: 0,
      advanced_sub_req_vol: 0,
      advanced_sub_rel_vol: 0,
      gasoline_sub_mandate: 0,
      gasoline_req_vol: 0,
      gasoline_rel_vol: 0,
      above_mandate_req_by_cust: 0,
      etanol: 0,
      etanol_advanced: 0,
      nafta: 0,
      nafta_advanced: 0,
      bio_gasoline: 0,
      bio_gasoline_advanced: 0,
      fame: 0,
      fame_advanced: 0,
      hvo_hro: 0,
      hvo_hro_advanced: 0,
      above_mandate_after_fulfill: 0,
    },
    joint_fulfill: {
      etanol: 0,
      etanol_advanced: 0,
      fame: 0,
      fame_advanced: 0,
      hvo_hro: 0,
      hvo_hro_advanced: 0,
      nafta: 0,
      nafta_advanced: 0,
      biogasoline: 0,
      biogasoline_advanced: 0,
      general_mandate: 0,
      gasoline_sub_mandate: 0,
      advanced_sub_mandate: 0,
      above_mandate_req_by_cust: 0,
      above_mandate_after_fulfill: 0,
    },
    allocated_LE: {
      etanol: 0,
      etanol_advanced: 0,
      fame: 0,
      fame_advanced: 0,
      hvo_hro: 0,
      hvo_hro_advanced: 0,
      nafta: 0,
      nafta_advanced: 0,
      biogasoline: 0,
      biogasoline_advanced: 0,
      general_mandate: 0,
      gasoline_sub_mandate: 0,
      advanced_sub_mandate: 0,
      above_mandate_req_by_cust: 0,
      above_mandate_after_fulfill: 0,
    },
    allocated_15: {
      etanol: 0,
      etanol_advanced: 0,
      fame: 0,
      fame_advanced: 0,
      hvo_hro: 0,
      hvo_hro_advanced: 0,
      nafta: 0,
      nafta_advanced: 0,
      biogasoline: 0,
      biogasoline_advanced: 0,
      general_mandate: 0,
      gasoline_sub_mandate: 0,
      advanced_sub_mandate: 0,
      above_mandate_req_by_cust: 0,
      above_mandate_after_fulfill: 0,
    },
    surplus_from_offroad: {
      etanol: 0,
      etanol_advanced: 0,
      fame: 0,
      fame_advanced: 0,
      hvo_hro: 0,
      hvo_hro_advanced: 0,
      nafta: 0,
      nafta_advanced: 0,
      biogasoline: 0,
      biogasoline_advanced: 0,
      general_mandate: 0,
      gasoline_sub_mandate: 0,
      advanced_sub_mandate: 0,
      above_mandate_req_by_cust: 0,
      above_mandate_after_fulfill: 0,
    },
    remaining_vol: {
      etanol: 0,
      etanol_advanced: 0,
      fame: 0,
      fame_advanced: 0,
      hvo_hro: 0,
      hvo_hro_advanced: 0,
      nafta: 0,
      nafta_advanced: 0,
      biogasoline: 0,
      biogasoline_advanced: 0,
      general_mandate: 0,
      gasoline_sub_mandate: 0,
      advanced_sub_mandate: 0,
      above_mandate_req_by_cust: 0,
      above_mandate_after_fulfill: 0,
    }
  });
  const [newData, setNewData] = useState([]);
  const [stepData, setStepData] = useState([]);
  const [stepDataId, setStepDataId] = useState<number|null>(null);
  const [stepTwosData, setStepTwosData] = useState([]);
  const [stepThreesData, setStepThreesData] = useState([]);
  const [stepFoursData, setStepFoursData] = useState([]);
  const [stepFivesData, setStepFivesData] = useState([]);
  const [stepSixsData, setStepSixsData] = useState([]);
  const [biotemplatesData, setBiotemplatesData] = useState([]);
  const dispatch = useDispatch();
  const updateReqVol = (newValues: Partial<OverViewReqVolParamProps>) => {
    setOverViewData(prevData => ({
      ...prevData,
      req_vol: { ...prevData.req_vol, ...newValues },
    }));
  };
  var roaduseAllocatedQuantityByStep = useContext(ReactReduxContext).store.getState().sustainability.roaduseAllocation;
  // console.log("QS")
  // console.log(roaduseAllocatedQuantityByStep)

  const updateJointFulfillVol = (newValues: Partial<OverViewParamProps>) => {
    setOverViewData(prevData => ({
      ...prevData,
      joint_fulfill: { ...prevData.joint_fulfill, ...newValues },
    }));
  };

  const updateAllocatedLEVol = (newValues: Partial<OverViewParamProps>) => {
    setOverViewData(prevData => ({
      ...prevData,
      allocated_LE: { ...prevData.allocated_LE, ...newValues },
    }));
  };

  const updateAllocated15Vol = (newValues: Partial<OverViewParamProps>) => {
    setOverViewData(prevData => ({
      ...prevData,
      allocated_15: { ...prevData.allocated_15, ...newValues },
    }));
  };

  const updateSurplusFromOffroadVol = (newValues: Partial<OverViewParamProps>) => {
    setOverViewData(prevData => ({
      ...prevData,
      surplus_from_offroad: { ...prevData.surplus_from_offroad, ...newValues },
    }));
  };

  const removeUnavailableBiotemplates = (biotemplates: any[]) => {
    // @ts-ignore
    const updatedBiotemplates = biotemplates.filter(item => item.available_quantity >= 1);
    return updatedBiotemplates;
  };

  const getAllocated = (row: any) => row.allocated_quantity;

  const stepOne = roaduseAllocatedQuantityByStep.biotemplatesInAllocationStepOne
  const stepTwo = roaduseAllocatedQuantityByStep.biotemplatesInAllocationStepTwo
  const stepThree = roaduseAllocatedQuantityByStep.biotemplatesInAllocationStepThree
  const stepFour = roaduseAllocatedQuantityByStep.biotemplatesInAllocationStepFour
  const stepFive = roaduseAllocatedQuantityByStep.biotemplatesInAllocationStepFive
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, row: any) => {
    const { value } = event.target;
    const prevData = { ...row };
    const newData = { ...row, allocated_quantity: value };
    // console.log("value")
    // console.log(value)
    // console.log("new data")
    // console.log(newData)
    // console.log('Prev Data')
    // console.log(prevData)
    // Assuming you are updating some state here
    setNewData(prevRows => {
      const updatedRowsCopy = [...prevRows];
      // @ts-ignore
      const rowIndex = updatedRowsCopy.findIndex(r => r.id === row.id);

      if (rowIndex !== -1) {
        // @ts-ignore
        updatedRowsCopy[rowIndex] = newData;
      }
      console.log("updated rows copy")

      return updatedRowsCopy;
    });
  };

  const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>, row: any[]) => {
    handleOnChange(event, row);
  };

  const isDoubleCount = (row: any) => {
    return row.double_counted_norway === "Ja";
  };

  const isDiesel = (row: any) => {
    return row.fuel_type === "Biokomponenter for diesel";
  };

  function isNonEmptyArrayOrObject(value: any): boolean {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (typeof value === "object" && value !== null) {
      return Object.keys(value).length > 0;
    }

    return false;
  }

  const parseVal = (value: any) => {
    let parsed = value;
    if (parsed) {
      if (value > -1 && value < 0) { parsed *= -1; }
      return parseData(parsed, 2);
    } else { return parsed; }
  };

  const getData = (data: any[], activeStep: number) => {
    return data;
  };

  const fetchBiotemplateData = async (activeStep: number, currentStepData: any[]) => {
    const response = await getStepData(activeStep);
    if (response) {
      if ("data" in response) {
        setStepData(response.data);
        if ("id" in response.data) {
          setStepDataId(response.data.id);
        }

        if ("req_vol" in response.data) {
          updateReqVol(response.data.req_vol);
          setResponseOverViewData(prevData => ({
            ...prevData,
            req_vol: { ...prevData.req_vol, ...response.data.req_vol },
          }));
        }
        if ("joint_fulfill" in response.data) {
          updateJointFulfillVol(response.data.joint_fulfill);
          setResponseOverViewData(prevData => ({
            ...prevData,
            joint_fulfill: { ...prevData.joint_fulfill, ...response.data.joint_fulfill },
          }));
        }
        if ("allocated_LE" in response.data) {
          updateAllocatedLEVol(response.data.allocated_LE);
          setResponseOverViewData(prevData => ({
            ...prevData,
            allocated_LE: { ...prevData.allocated_LE, ...response.data.allocated_LE },
          }));
        }
        if ("allocated_15" in response.data) {
          updateAllocated15Vol(response.data.allocated_15);
          setResponseOverViewData(prevData => ({
            ...prevData,
            allocated_15: { ...prevData.allocated_15, ...response.data.allocated_15 },
          }));
        }
        if ("surplus_from_offroad" in response.data) {
          updateSurplusFromOffroadVol(response.data.surplus_from_offroad);
          setResponseOverViewData(prevData => ({
            ...prevData,
            surplus_from_offroad: { ...prevData.surplus_from_offroad, ...response.data.surplus_from_offroad },
          }));
        }

        if ( "table_from_step_two" in response.data && isNonEmptyArrayOrObject(response.data.table_from_step_two)) {
          if (Array.isArray(response.data.table_from_step_two)) {
            setStepTwosData(response.data.table_from_step_two);
          } else {
            setStepTwosData(Object.values(response.data.table_from_step_two));
          }
        }

        if ( "table_from_step_three" in response.data && isNonEmptyArrayOrObject(response.data.table_from_step_three)) {
          if (Array.isArray(response.data.table_from_step_three)) {
            setStepThreesData(response.data.table_from_step_three);
          } else {
            setStepThreesData(Object.values(response.data.table_from_step_three));
          }
        }

        if ( "table_from_step_four" in response.data && isNonEmptyArrayOrObject(response.data.table_from_step_four)) {
          if (Array.isArray(response.data.table_from_step_four)) {
            setStepFoursData(response.data.table_from_step_four);
          } else {
            setStepFoursData(Object.values(response.data.table_from_step_four));
          }
        }

        if ( "table_from_step_five" in response.data && isNonEmptyArrayOrObject(response.data.table_from_step_five)) {
          if (Array.isArray(response.data.table_from_step_five)) {
            setStepFivesData(response.data.table_from_step_five);
          } else {
            setStepFivesData(Object.values(response.data.table_from_step_five));
          }
        }

        if ( "table_from_step_six" in response.data && isNonEmptyArrayOrObject(response.data.table_from_step_six)) {
          if (Array.isArray(response.data.table_from_step_six)) {
            setStepSixsData(response.data.table_from_step_six);
          } else {
            setStepSixsData(Object.values(response.data.table_from_step_six));
          }
        }

        let mappedData;

        if (activeStep === 1 && "table_from_step_two" in response.data) {
          // @ts-ignore
          mappedData = getMappedData(currentStepData, response.data.table_from_step_two);
        } else if (activeStep === 2 && "table_from_step_three" in response.data) {
          // @ts-ignore
          mappedData = getMappedData(currentStepData, response.data.table_from_step_three);
        } else if (activeStep === 3 && "table_from_step_four" in response.data) {
          // @ts-ignore
          mappedData = getMappedData(currentStepData, response.data.table_from_step_four);
        } else if (activeStep === 4 && "table_from_step_five" in response.data) {
          // @ts-ignore
          mappedData = getMappedData(currentStepData, response.data.table_from_step_five);
        } else if (activeStep === 5 && "table_from_step_six" in response.data) {
          // @ts-ignore
          mappedData = getMappedData(currentStepData, response.data.table_from_step_six);
        } else {
          mappedData = currentStepData;
        }
        const calculatedData = await calculateMappedData(activeStep, mappedData);

        // @ts-ignore
        setNewData(calculatedData);
      } else {
        setStepData([]);
      }
    } else {
      alert("Some error occured");
    }
  };

  const calculateMappedData = async (activeStep: number, data: any[]) => {
    let newCalculatedData = data;
    console.log(roaduseAllocatedQuantityByStep)

    // Define the steps and the corresponding allocation data
    const steps = [
      roaduseAllocatedQuantityByStep.biotemplatesInAllocationStepOne,
      roaduseAllocatedQuantityByStep.biotemplatesInAllocationStepTwo,
      roaduseAllocatedQuantityByStep.biotemplatesInAllocationStepThree,
      roaduseAllocatedQuantityByStep.biotemplatesInAllocationStepFour,
      roaduseAllocatedQuantityByStep.biotemplatesInAllocationStepFive
    ];

    // Filter out the current active step
    const filteredSteps = steps.filter((_, index) => index !== activeStep - 1);

    console.log("log log log")
    // Apply the balance operation to each filtered step
    for (const stepData of filteredSteps) {
      if (stepData){
        newCalculatedData = await getBalancedData(newCalculatedData, stepData);
        console.log(stepData)
      }
    }

    // Remove items with available_quantity < 1
    newCalculatedData = newCalculatedData.filter(item => item.available_quantity >= 1);

    console.log("activeStep:", activeStep);
    console.log("newCalculatedData:", newCalculatedData);

    return newCalculatedData;
  };

  const getBalancedData = async (existingData: any[], apiData: any[]) => {
    const updatedData = existingData.map(item => {
      // Find matching entry in the API data
      const matchingApiEntry = Object.values(apiData).find(apiItem =>
          apiItem.id === item.id
      );

      if (matchingApiEntry) {
        const itemCopy = { ...item };

        // Subtract the allocated quantity from the available quantity
        itemCopy.available_quantity -= parseFloat(matchingApiEntry.allocated_quantity);

        return itemCopy;
      }

      return item;
    });

    return updatedData;
  };


  const getMappedData = (existingData: any[], apiData: any[]) => {
    const updatedData = existingData.map(item => {
      // @ts-ignore
      const matchingApiEntry = Object.values(apiData).find(apiItem => apiItem.biotemplate_id === String(item.id)
      );

      if (matchingApiEntry) {
        // Update the allocated_quantity
        // @ts-ignore
        return { ...item, allocated_quantity: matchingApiEntry.allocated_quantity };
      }

      return item;
    });

    return updatedData;
    // return existingData;
  };

  const updateDataTotals = (responseOverViewData: OverViewProps, newData: any[]) => {

    const dataTotals: OverViewProps = JSON.parse(JSON.stringify(responseOverViewData));

    const req_vol_gasoline_req_vol = parseFloat(dataTotals.req_vol.gasoline_req_vol.toString());
    let allocated_LE_gasoline_sub_mandate = activeStep == 1 ? 0 : parseFloat(dataTotals.allocated_LE.gasoline_sub_mandate.toString());
    let allocated_15_gasoline_sub_mandate = activeStep == 1 ? 0 : parseFloat(dataTotals.allocated_15.gasoline_sub_mandate.toString());

    const advanced_req_vol = parseFloat(dataTotals.req_vol.advanced_sub_req_vol.toString());
    let allocated_LE_advanced_sub_mandate = parseFloat(dataTotals.allocated_LE.advanced_sub_mandate.toString());
    let allocated_15_advanced_sub_mandate = parseFloat(dataTotals.allocated_15.advanced_sub_mandate.toString());

    const general_req_vol = parseFloat(dataTotals.req_vol.general_req_vol.toString());
    let allocated_LE_general_sub_mandate = parseFloat(dataTotals.allocated_LE.general_mandate.toString());
    let allocated_15_general_sub_mandate = parseFloat(dataTotals.allocated_15.general_mandate.toString());

    const above_mandate_req_by_cust = parseFloat(dataTotals.req_vol.above_mandate_req_by_cust.toString());
    let allocated_LE_above_mandate_req_by_cust = parseFloat(dataTotals.allocated_LE.above_mandate_req_by_cust.toString());
    let allocated_15_above_mandate_req_by_cust = parseFloat(dataTotals.allocated_15.above_mandate_req_by_cust.toString());

    const above_mandate_after_fulfill_req_vol = parseFloat(dataTotals.req_vol.above_mandate_after_fulfill.toString());
    let allocated_LE_above_mandate_after_fulfill = parseFloat(dataTotals.allocated_LE.above_mandate_after_fulfill.toString());
    let allocated_15_above_mandate_after_fulfill = parseFloat(dataTotals.allocated_15.above_mandate_after_fulfill.toString());

    const etanol = parseFloat(dataTotals.req_vol.etanol.toString());
    let allocated_LE_etanol = parseFloat(dataTotals.allocated_LE.etanol.toString());
    let allocated_15_etanol = parseFloat(dataTotals.allocated_15.etanol.toString());

    const etanol_advanced = parseFloat(dataTotals.req_vol.etanol_advanced.toString());
    let allocated_LE_etanol_advanced = parseFloat(dataTotals.allocated_LE.etanol_advanced.toString());
    let allocated_15_etanol_advanced = parseFloat(dataTotals.allocated_15.etanol_advanced.toString());

    const nafta = parseFloat(dataTotals.req_vol.nafta.toString());
    let allocated_LE_nafta = parseFloat(dataTotals.allocated_LE.nafta.toString());
    let allocated_15_nafta = parseFloat(dataTotals.allocated_15.nafta.toString());

    const nafta_advanced = parseFloat(dataTotals.req_vol.nafta_advanced.toString());
    let allocated_LE_nafta_advanced = parseFloat(dataTotals.allocated_LE.nafta_advanced.toString());
    let allocated_15_nafta_advanced = parseFloat(dataTotals.allocated_15.nafta_advanced.toString());

    const biogasoline = parseFloat(dataTotals.req_vol.bio_gasoline.toString());
    let allocated_LE_biogasoline = parseFloat(dataTotals.allocated_LE.biogasoline.toString());
    let allocated_15_biogasoline = parseFloat(dataTotals.allocated_15.biogasoline.toString());

    const biogasoline_advanced = parseFloat(dataTotals.req_vol.bio_gasoline_advanced.toString());
    let allocated_LE_biogasoline_advanced = parseFloat(dataTotals.allocated_LE.biogasoline_advanced.toString());
    let allocated_15_biogasoline_advanced = parseFloat(dataTotals.allocated_15.biogasoline_advanced.toString());

    const fame = parseFloat(dataTotals.req_vol.fame.toString());
    let allocated_LE_fame = parseFloat(dataTotals.allocated_LE.fame.toString());
    let allocated_15_fame = parseFloat(dataTotals.allocated_15.fame.toString());

    const fame_advanced = parseFloat(dataTotals.req_vol.fame_advanced.toString());
    let allocated_LE_fame_advanced = parseFloat(dataTotals.allocated_LE.fame_advanced.toString());
    let allocated_15_fame_advanced = parseFloat(dataTotals.allocated_15.fame_advanced.toString());

    const hvo_hro = parseFloat(dataTotals.req_vol.hvo_hro.toString());
    let allocated_LE_hvo_hro = parseFloat(dataTotals.allocated_LE.hvo_hro.toString());
    let allocated_15_hvo_hro = parseFloat(dataTotals.allocated_15.hvo_hro.toString());

    const hvo_hro_advanced = parseFloat(dataTotals.req_vol.hvo_hro_advanced.toString());
    let allocated_LE_hvo_hro_advanced = parseFloat(dataTotals.allocated_LE.hvo_hro_advanced.toString());
    let allocated_15_hvo_hro_advanced = parseFloat(dataTotals.allocated_15.hvo_hro_advanced.toString());

    for (const row of newData) {
      if (activeStep == 1) {
        // @ts-ignore
        allocated_LE_gasoline_sub_mandate += parseFloat(row.allocated_quantity);
        // @ts-ignore
        allocated_15_gasoline_sub_mandate += parseFloat(row.allocated_quantity);
      }

      if (activeStep <= 2) {
        // @ts-ignore
        if (row.double_counted_norway == "Ja") {
          // @ts-ignore
          allocated_LE_advanced_sub_mandate += parseFloat(row.allocated_quantity);
          // @ts-ignore
          allocated_15_advanced_sub_mandate += parseFloat(row.allocated_quantity);
        }
        // @ts-ignore
        allocated_LE_general_sub_mandate += parseFloat(row.allocated_quantity);
        // @ts-ignore
        allocated_15_general_sub_mandate += parseFloat(row.allocated_quantity);
      }

      if (activeStep == 3) {
        // @ts-ignore
        if (row.double_counted_norway == "Ja") {
          // @ts-ignore
          allocated_LE_general_sub_mandate += (parseFloat(row.allocated_quantity) * 2 );
        } else {
          // @ts-ignore
          allocated_LE_general_sub_mandate += parseFloat(row.allocated_quantity);
        }
        // @ts-ignore
        allocated_15_general_sub_mandate += parseFloat(row.allocated_quantity);
      }

      if (activeStep == 4) {
        // @ts-ignore
        allocated_LE_above_mandate_req_by_cust += parseFloat(row.allocated_quantity);
        // @ts-ignore
        allocated_15_above_mandate_req_by_cust += parseFloat(row.allocated_quantity);
      }

      if (activeStep == 5) {
        // @ts-ignore
        allocated_LE_above_mandate_after_fulfill += parseFloat(row.allocated_quantity);
        // @ts-ignore
        allocated_15_above_mandate_after_fulfill += parseFloat(row.allocated_quantity);
      }

      // @ts-ignore
      if (row.double_counted_norway == "Nei" && row.biofuel_type == "Etanol") {
        // @ts-ignore
        allocated_LE_etanol += parseFloat(row.allocated_quantity);
        // @ts-ignore
        allocated_15_etanol += parseFloat(row.allocated_quantity);
      }
      // @ts-ignore
      if (row.double_counted_norway == "Ja" && row.biofuel_type == "Etanol") {
        if(activeStep == 3){
          // @ts-ignore
          allocated_LE_etanol_advanced += parseFloat(row.allocated_quantity)*2;
        } else {
          // @ts-ignore
          allocated_LE_etanol_advanced += parseFloat(row.allocated_quantity);
        }

        // @ts-ignore
        allocated_15_etanol_advanced += parseFloat(row.allocated_quantity);
      }
      // @ts-ignore
      if (row.double_counted_norway == "Nei" && row.biofuel_type == "Nafta") {
        // @ts-ignore
        allocated_LE_nafta += parseFloat(row.allocated_quantity);
        // @ts-ignore
        allocated_15_nafta += parseFloat(row.allocated_quantity);
      }
      // @ts-ignore
      if (row.double_counted_norway == "Ja" && row.biofuel_type == "Nafta") {

        if (activeStep == 3){
          // @ts-ignore
          allocated_LE_nafta_advanced += parseFloat(row.allocated_quantity)*2;
        }
        else {
          // @ts-ignore
          allocated_LE_nafta_advanced += parseFloat(row.allocated_quantity);
        }

        // @ts-ignore
        allocated_15_nafta_advanced += parseFloat(row.allocated_quantity);
      }
      // @ts-ignore
      if (row.double_counted_norway == "Nei" && (row.biofuel_type == "Bio gasoline" || row.biofuel_type == "Biogasoline")) {
        // @ts-ignore
        allocated_LE_biogasoline += parseFloat(row.allocated_quantity);
        // @ts-ignore
        allocated_15_biogasoline += parseFloat(row.allocated_quantity);
      }
      // @ts-ignore
      if (row.double_counted_norway == "Ja" && (row.biofuel_type == "Bio gasoline" || row.biofuel_type == "Biogasoline")) {

        if(activeStep == 3){
          // @ts-ignore
          allocated_LE_biogasoline_advanced += parseFloat(row.allocated_quantity)*2;
        } else {
          // @ts-ignore
          allocated_LE_biogasoline_advanced += parseFloat(row.allocated_quantity);
        }

        // @ts-ignore
        allocated_15_biogasoline_advanced += parseFloat(row.allocated_quantity);
      }
      // @ts-ignore
      if (row.double_counted_norway == "Nei" && row.biofuel_type == "HVO/HRO") {
        // @ts-ignore
        allocated_LE_hvo_hro += parseFloat(row.allocated_quantity);
        // @ts-ignore
        allocated_15_hvo_hro += parseFloat(row.allocated_quantity);
      }
      // @ts-ignore
      if (row.double_counted_norway == "Ja" && row.biofuel_type == "HVO/HRO") {

        if(activeStep == 3){
          // @ts-ignore
          allocated_LE_hvo_hro_advanced += parseFloat(row.allocated_quantity)*2;
        } else {
          // @ts-ignore
          allocated_LE_hvo_hro_advanced += parseFloat(row.allocated_quantity);
        }

        // @ts-ignore
        allocated_15_hvo_hro_advanced += parseFloat(row.allocated_quantity);
      }
      // @ts-ignore
      if (row.double_counted_norway == "Nei" && row.biofuel_type == "FAME") {
        // @ts-ignore
        allocated_LE_fame += parseFloat(row.allocated_quantity);
        // @ts-ignore
        allocated_15_fame += parseFloat(row.allocated_quantity);
      }
      // @ts-ignore
      if (row.double_counted_norway == "Ja" && row.biofuel_type == "FAME") {
        if(activeStep == 3){
          // @ts-ignore
          allocated_LE_fame_advanced += parseFloat(row.allocated_quantity)*2;
        } else {
          // @ts-ignore
          allocated_LE_fame_advanced += parseFloat(row.allocated_quantity);
        }
        // @ts-ignore
        allocated_15_fame_advanced += parseFloat(row.allocated_quantity);
      }
    }

    dataTotals.allocated_LE.gasoline_sub_mandate = allocated_LE_gasoline_sub_mandate;
    dataTotals.allocated_15.gasoline_sub_mandate = allocated_15_gasoline_sub_mandate;
    dataTotals.remaining_vol.gasoline_sub_mandate = req_vol_gasoline_req_vol - allocated_15_gasoline_sub_mandate;

    dataTotals.allocated_LE.advanced_sub_mandate = allocated_LE_advanced_sub_mandate;
    dataTotals.allocated_15.advanced_sub_mandate = allocated_15_advanced_sub_mandate;
    dataTotals.remaining_vol.advanced_sub_mandate = advanced_req_vol - allocated_15_advanced_sub_mandate;

    dataTotals.allocated_LE.general_mandate = allocated_LE_general_sub_mandate;
    dataTotals.allocated_15.general_mandate = allocated_15_general_sub_mandate;
    dataTotals.remaining_vol.general_mandate = general_req_vol - allocated_LE_general_sub_mandate;

    dataTotals.allocated_LE.above_mandate_req_by_cust = allocated_LE_above_mandate_req_by_cust;
    dataTotals.allocated_15.above_mandate_req_by_cust = allocated_15_above_mandate_req_by_cust;
    dataTotals.remaining_vol.above_mandate_req_by_cust = above_mandate_req_by_cust - allocated_15_above_mandate_req_by_cust;

    dataTotals.allocated_LE.above_mandate_after_fulfill = allocated_LE_above_mandate_after_fulfill;
    dataTotals.allocated_15.above_mandate_after_fulfill = allocated_15_above_mandate_after_fulfill;
    dataTotals.remaining_vol.above_mandate_after_fulfill = above_mandate_after_fulfill_req_vol - allocated_15_above_mandate_after_fulfill;

    dataTotals.allocated_LE.etanol = allocated_LE_etanol;
    dataTotals.allocated_15.etanol = allocated_15_etanol;
    dataTotals.remaining_vol.etanol = etanol - allocated_15_etanol;

    dataTotals.allocated_LE.etanol_advanced = allocated_LE_etanol_advanced;
    dataTotals.allocated_15.etanol_advanced = allocated_15_etanol_advanced;
    dataTotals.remaining_vol.etanol_advanced = etanol_advanced - allocated_15_etanol_advanced;

    dataTotals.allocated_LE.nafta = allocated_LE_nafta;
    dataTotals.allocated_15.nafta = allocated_15_nafta;
    dataTotals.remaining_vol.nafta = nafta - allocated_15_nafta;

    dataTotals.allocated_LE.nafta_advanced = allocated_LE_nafta_advanced;
    dataTotals.allocated_15.nafta_advanced = allocated_15_nafta_advanced;
    dataTotals.remaining_vol.nafta_advanced = nafta_advanced - allocated_15_nafta_advanced;

    dataTotals.allocated_LE.biogasoline = allocated_LE_biogasoline;
    dataTotals.allocated_15.biogasoline = allocated_15_biogasoline;
    dataTotals.remaining_vol.biogasoline = biogasoline - allocated_15_biogasoline;

    dataTotals.allocated_LE.biogasoline_advanced = allocated_LE_biogasoline_advanced;
    dataTotals.allocated_15.biogasoline_advanced = allocated_15_biogasoline_advanced;
    dataTotals.remaining_vol.biogasoline_advanced = biogasoline_advanced - allocated_15_biogasoline_advanced;

    dataTotals.allocated_LE.hvo_hro = allocated_LE_hvo_hro;
    dataTotals.allocated_15.hvo_hro = allocated_15_hvo_hro;
    dataTotals.remaining_vol.hvo_hro = hvo_hro - allocated_15_hvo_hro;

    dataTotals.allocated_LE.hvo_hro_advanced = allocated_LE_hvo_hro_advanced;
    dataTotals.allocated_15.hvo_hro_advanced = allocated_15_hvo_hro_advanced;
    dataTotals.remaining_vol.hvo_hro_advanced = hvo_hro_advanced - allocated_15_hvo_hro_advanced;

    dataTotals.allocated_LE.fame = allocated_LE_fame;
    dataTotals.allocated_15.fame = allocated_15_fame;
    dataTotals.remaining_vol.fame = fame - allocated_15_fame;

    dataTotals.allocated_LE.fame_advanced = allocated_LE_fame_advanced;
    dataTotals.allocated_15.fame_advanced = allocated_15_fame_advanced;
    dataTotals.remaining_vol.fame_advanced = fame_advanced - allocated_15_fame_advanced;

    return dataTotals;
  };

  useEffect(() => {
    updateIsFetching(true);
    let newDataForStep;

    switch (activeStep) {
      case 1:
        newDataForStep = removeUnavailableBiotemplates(stepOneData);
        break;
      case 2:
        newDataForStep = removeUnavailableBiotemplates(stepTwoData);
        break;
      case 3:
        newDataForStep = removeUnavailableBiotemplates(stepThreeData);
        break;
      case 4:
        newDataForStep = removeUnavailableBiotemplates(stepFourData);
        break;
      case 5:
        newDataForStep = removeUnavailableBiotemplates(stepFiveData);
        break;
      default:
        newDataForStep = removeUnavailableBiotemplates(data);
        break;
    }
    // @ts-ignore
    setNewData(newDataForStep);

    fetchBiotemplateData(activeStep, newDataForStep);

    updateIsFetching(false);
  }, [data, stepOneData, stepTwoData, stepThreeData, stepFourData, stepFiveData, activeStep]);

  useEffect(() => {
    // @ts-ignore
    const newArray = {
      // @ts-ignore
      biotemplates: newData.filter(item => item.allocated_quantity > 0).map(item => ({id: item.id, available_quantity: item.available_quantity, allocated_quantity: item.allocated_quantity}))
    };
    // @ts-ignore
    onUpdateData(newArray, "bio");

    if (activeStep === 5) {
      const params: Requirement = {
        sub_requirements_biocomponents_in_gasoline: {},
        sub_requirement_advanced_biofuels: {},
        general_requirement: {},
        remaining_quantity: {},
      };

      stepTwosData.forEach(item => {
        // @ts-ignore
        params.sub_requirements_biocomponents_in_gasoline[item.biotemplate_id] = item.allocated_quantity;
      });

      stepThreesData.forEach(item => {
        // @ts-ignore
        params.sub_requirement_advanced_biofuels[item.biotemplate_id] = item.allocated_quantity;
      });

      stepFoursData.forEach(item => {
        // @ts-ignore
        params.general_requirement[item.biotemplate_id] = item.allocated_quantity;
      });

      const updatedArray = { biotemplates: [] };

      // Create a map for faster lookup
      const newArrayMap = {};
      newArray.biotemplates.forEach(item => {
        newArrayMap[item.id] = item.allocated_quantity;
      });

      // Update newArray based on stepFivesData
      stepFivesData.forEach(item => {
        // @ts-ignore
        const id = item.biotemplate_id;
        if (newArrayMap[id] === undefined) {
          // If not matching, add a new entry
          // @ts-ignore
          updatedArray.biotemplates.push({ id: Number(id), allocated_quantity: item.allocated_quantity });
        } else {
          // If matching, update the allocated_quantity
          // @ts-ignore
          newArrayMap[id] = String(Number(newArrayMap[id]) + Number(item.allocated_quantity));
        }
      });

      // Add the remaining entries from newArrayMap to updatedArray
      Object.entries(newArrayMap).forEach(([id, allocated_quantity]) => {
        // @ts-ignore
        updatedArray.biotemplates.push({ id: Number(id), allocated_quantity: String(allocated_quantity) });
      });

      updatedArray.biotemplates.forEach(item => {
        // @ts-ignore
        params.remaining_quantity[item.id] = item.allocated_quantity;
      });

      // @ts-ignore
      onUpdateRequirement(params);
    }

  }, [activeStep, newData]);

  useEffect(() => {

    const dataTotals: OverViewProps = updateDataTotals(responseOverViewData, newData);

    const newArray = {
      // @ts-ignore
      overview: dataTotals,
      id: stepDataId
    };

    setOverViewData(dataTotals);
    // @ts-ignore
    onUpdateData(newArray, "overview");
  }, [newData]);

  return (
      <>
        <div className={classes.tableContainer}>
          {activeStep > 1 && (
              <>
                <Typography variant="h4">Overview of allocated volumes with advanced sustainability criteria from step 2 (gasoline sub-mandate) </Typography><br />
                <PrevTable
                    headCells={prevTableHeadings}
                    // @ts-ignore
                    rows={stepTwosData}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br /><br />
              </>
          )}

          {activeStep > 2 && (
              <>
                <Typography variant="h4">Overview of allocated volumes from step 3 (advanced sub-mandate)</Typography><br />
                <PrevTable
                    headCells={prevTableHeadings}
                    // @ts-ignore
                    rows={stepThreesData}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br /><br />
              </>
          )}

          {activeStep > 3 && (
              <>
                <Typography variant="h4">Overview of allocated volumes from step 4 (general mandate)</Typography><br />
                <PrevTable
                    headCells={prevTableHeadings}
                    // @ts-ignore
                    rows={stepFoursData}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br /><br />
              </>
          )}
          {activeStep > 4 && (
              <>
                <Typography variant="h4">Overview of allocated volumes from step 5 (above the mandate required by customers)</Typography><br />
                <PrevTable
                    headCells={prevTableHeadings}
                    // @ts-ignore
                    rows={stepFivesData}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br /><br />
              </>
          )}
          {activeStep > 5 && (
              <>
                <Typography variant="h4">Step 6 Data</Typography><br />
                <PrevTable
                    headCells={prevTableHeadings}
                    // @ts-ignore
                    rows={stepSixsData}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br /><br />
              </>
          )}
          <Typography variant="h4">Allocation Table</Typography><br />
          <SmartTable
            headCells={tableHeadings}
            // rows={data.length === 0 ? null : getData(data, activeStep)}
            // rows={getData(data, activeStep)}
            rows={getData(newData, activeStep)}
            editableProps={{
              id: "allocated_quantity",
              getValue: getAllocated,
              onChange: handleOnChange,
              onBlur: handleOnBlur,
            }}
            verticalOverflow={true}
            whiteHeader={true}
          />
        </div>
        <OverviewPanel className={classes.overviewPanel}>
          <OverviewTableContainer data={overViewData} step={activeStep} />
        </OverviewPanel>
      </>
  );
};

// @ts-ignore
export default EditableTable;
