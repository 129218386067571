export default (data: string | number, decimals?: number) => {
  // if (!data) return "";
  let parsed = data;
  let decimalString = "";
  if (data === null || data === undefined) { return ""; }
  if (Number(parsed) || Number(parsed) === 0) {
    if (decimals) {
      decimalString = ",".concat(
        parsed.toString().split(".")[1]
          ? parsed.toString().split(".")[1].concat("000").substr(0, decimals)
          : "".concat("000").substr(0, decimals)
      );
    } // save decimals
    parsed = parsed.toString().split(".")[0]; // remove decimals
    parsed = parsed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  parsed = parsed.toString().concat(decimalString);
  return parsed;
};
