/**
 * Sustainability Reporting > Storage > outgoing transaction
 */

import { getToken } from "../../../utils";
import { BASE_URL } from "../../../endpoints";

export const getNormalBiotemplates = async (values: any) => {
  const token = getToken();
  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/biotemplates/list_biotemplates?biofuel_type=${values.biofuel_type}&storage_date=${values.storage_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token
  }
};

export const getHblBiotemplates = async (values: any) => {
  const token = getToken();
  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/biotemplates/hbl_list_biotemplates?biofuel_type=${values.biofuel_type}&storage_terminal=${values.storage_terminal}
          &storage_date=${values.storage_date}&volume=${values.volume}&delivery_period=${values.delivery_period}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token
  }
};

export const getVolumeTransferBiotemplate = async (values: any) => {
  const token = getToken();
  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/biotemplates/available_list_biotemplates?biofuel_type=${values.biofuel_type}&storage_terminal_out=${values.storage_terminal_out}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        const data = await response.json();

        return data;

      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token
  }
};
