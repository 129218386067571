import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

export default async () => {
  const token = getToken();

  let valid = false;
  let data = [];
  let transactionResult;

  if (token) {
    try {
      const response = await fetch(`${BASE_URL}/api/register_transactions`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      transactionResult = await response.json();
      valid = transactionResult && transactionResult.success;

      data = transactionResult.register_transactions;
    } catch (except) {
      valid = false; // network error
    }
  } else {
    return null; // No token
  }

  if (!valid) {
    if (transactionResult && transactionResult.error) {
      return {
        valid: false,
        message: transactionResult.error,
      };
    }
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    data,
  };
};
