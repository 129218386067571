import { ErrorPayload } from "../../../common/payloads/ErrorActionPayload";
import { Action, handleActions } from "redux-actions";
import fetchTaxYears from "../../../api/card/taxYears/get";
interface TaxYear {
  id: number;
  name: string;
  year: number;
}
interface TaxYearsState {
  isFetching: boolean;
  data: any[];
  currentYear?: number;
}

// State Interface
export interface MainCardState {
  isSimulation: boolean;
  isJoint: boolean;
  isFirstSplit: boolean;
  taxYears: TaxYearsState;
  splitYear?: [TaxYear, TaxYear];
  activePeriod?: TaxYear;
  error?: any;
}

// Initial state
const initialState = {
  isSimulation: false,
  isJoint: false,
  isFirstSplit: true,
  taxYears: {
    isFetching: false,
    data: [],
    error: undefined,
  } as TaxYearsState,
  error: undefined,
} as MainCardState;

// Actions
const CARD_TOGGLE_SIMULATION = "card/TOGGLE_SIMULATION";
const CARD_TOGGLE_JOINT = "card/TOGGLE_JOINT";
const CARD_TOGGLE_SPLIT_YEAR = "card/TOGGLE_SPLIT_YEAR";
const CARD_TAX_YEARS_REQUEST = "card/taxYears/REQUEST";
const CARD_TAX_YEARS_ERROR = "card/taxYears/ERROR";
const CARD_TAX_YEARS_SUCCESS = "card/taxYears/SUCCESS";
// Action creators
export function toggleSimulation() {
  return {
    type: CARD_TOGGLE_SIMULATION,
  };
}

export function toggleJoint() {
  return {
    type: CARD_TOGGLE_JOINT,
  };
}

export function toggleSplitYear() {
  return {
    type: CARD_TOGGLE_SPLIT_YEAR,
  };
}

function requestTaxYears() {
  return {
    type: CARD_TAX_YEARS_REQUEST,
  };
}

function errorTaxYears(error: any) {
  return {
    type: CARD_TAX_YEARS_ERROR,
    payload: { error },
  };
}

function receiveTaxYears(data: any) {
  return {
    type: CARD_TAX_YEARS_SUCCESS,
    payload: data,
  };
}

export function getTaxYearsCard() {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.card) {
      dispatch(requestTaxYears());
      try {
        const data = await fetchTaxYears();
        dispatch(receiveTaxYears(data));
      } catch (error) {
        dispatch(errorTaxYears(error));
      }
    }
    return Promise.resolve;
  };
}

function getActiveYearInfo(
  tax_years: any,
  current_year: any,
  is_simulation: boolean,
  is_first_split: boolean
) {
  if (!current_year || !tax_years) { return { split: null, active: null }; }
  const activeYear = current_year.year + (is_simulation ? 0 : -1);
  let splitYear = tax_years.filter(
    (tax_year: any) => tax_year.year === activeYear
  );
  const activePeriod =
    !is_first_split && splitYear[1] ? splitYear[1] : splitYear[0];
  if (splitYear.length < 2) { splitYear = null; }
  return { split: splitYear, active: activePeriod };
}

// Reducer Definition
export default handleActions<MainCardState>(
  {
    [CARD_TOGGLE_SIMULATION]: handleToggleSimulation,
    [CARD_TOGGLE_JOINT]: handleToggleJoint,
    [CARD_TOGGLE_SPLIT_YEAR]: handleToggleSplitYear,
    [CARD_TAX_YEARS_REQUEST]: handleTaxYearsRequest,
    [CARD_TAX_YEARS_ERROR]: handleTaxYearsError,
    [CARD_TAX_YEARS_SUCCESS]: handleTaxYearsSuccess,
  } as any,
  initialState
);

// Reducer Description
function handleToggleSimulation(state: MainCardState, action: Action<any>) {
  const activeYearInfo = getActiveYearInfo(
    state.taxYears.data,
    state.taxYears.currentYear,
    !state.isSimulation,
    state.isFirstSplit
  );

  return {
    ...state,
    isSimulation: !state.isSimulation,
    splitYear: activeYearInfo.split,
    activePeriod: activeYearInfo.active,
    error: undefined as any,
  };
}

function handleToggleJoint(state: MainCardState, action: Action<any>) {
  return {
    ...state,
    isJoint: !state.isJoint,
    error: undefined as any,
  };
}

function handleToggleSplitYear(state: MainCardState, action: Action<any>) {
  const activeYearInfo = getActiveYearInfo(
    state.taxYears.data,
    state.taxYears.currentYear,
    state.isSimulation,
    !state.isFirstSplit
  );

  return {
    ...state,
    isFirstSplit: !state.isFirstSplit,
    activePeriod: activeYearInfo.active,
    error: undefined as any,
  };
}

function handleTaxYearsRequest(state: MainCardState) {
  return {
    ...state,
    taxYears: {
      ...state.taxYears,
      ...{ isFetching: true, error: undefined as any },
    },
  };
}

function handleTaxYearsError(
  state: MainCardState,
  action: Action<ErrorPayload>
) {
  return {
    ...state,
    taxYears: {
      ...state.taxYears,
      ...{ isFetching: false, error: action.payload.error },
    },
  };
}

function handleTaxYearsSuccess(state: MainCardState, action: Action<any>) {
  const currentYear = action.payload.tax_years.filter(
    (tax_year: any) => tax_year.id === action.payload.current_tax_year_id
  )[0];
  const activeYearInfo = getActiveYearInfo(
    action.payload.tax_years,
    currentYear,
    state.isSimulation,
    state.isFirstSplit
  );
  console.log(activeYearInfo);

  return {
    ...state,
    taxYears: {
      ...state.taxYears,
      ...{
        isFetching: false,
        data: action.payload.tax_years,
        currentYear,
        error: undefined as any,
      },
    },
    splitYear: activeYearInfo.split,
    activePeriod: activeYearInfo.active,
  };
}
