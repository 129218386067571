import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

const getCustomerPDFReport = async (id: number, data: any) => {
  const token = getToken();

  let valid = false;
  let reportResult;
  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/card_customers/${id}/ton_equivalence_report?customer_name=${encodeURIComponent(
          data.customer_name
        )}&name=${data.name}&position=${data.position}&tax_year=${
          data.tax_year
        }&date=${data.date}&customer_no=${data.customer_nr}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      reportResult = await response.json();
      valid =
        reportResult !== null &&
        reportResult.success &&
        reportResult.customer_reports;
    } catch (except) {
      valid = false;
    }
  }

  if (!valid) {
    if (reportResult && reportResult.errors) {
      return {
        valid: false,
        message: reportResult.errors,
      };
    }
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    data: reportResult.customer_reports,
  };
};

export default getCustomerPDFReport;
