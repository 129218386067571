/**
 * Sustainability > Allocation: MainPage
 */

import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ButtonRounded from "../../../../components/ButtonRounded";
import ToggleButton from "../../../../components/ToggleButton";
import ModalAddEditCollab from "./ModalAddEditCollab";
import ModalDeleteCollab from "./ModalDeleteCollab";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Pagination from "../../../../components/Pagination";
import getAllocationPageData from "../../../../api/sustainability/allocation/collaboration/get";
import parseData from "../../../../components/texts/parseData";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";
import SoldVolumeTextField from "./SoldVolumeTextField";
import ConstructionDieselVolumeTextField from "./ConstructionDieselVolumeTextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    h2: {
      [theme.breakpoints.down("md")]: {
        fontSize: "32px",
      },
    },
    btn: {
      minWidth: "200px",
      "&:not(:first-child)": {
        marginLeft: "20px",
        [theme.breakpoints.down("md")]: {
          marginLeft: "10px",
        },
      },
    },
    toggleCardContainer: {
      marginTop: theme.spacing(3),
    },
    toggleCardContent: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    toggleCardTitle: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    cardCommon: {
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    tableTitle: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    outputTableTopLabel: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    table: {
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: "none",
        paddingTop: 0,
        paddingBottom: 0,
        height: theme.spacing(4),
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontSize: "11px",
        fontWeight: 700,
      },
    },
    iconBtn: {
      padding: 0,
      marginLeft: theme.spacing(2),
    },
    companyName: {
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    paginationContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(3),
    },
    addButton: {
      minWidth: "200px",
    },
    circularProgress: {
      margin: "80px",
      width: "48px",
      height: "48px",
    },
    soldVolumesTotal: {
      "& .MuiTableCell-root": {
        fontWeight: 700,
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        paddingRight: theme.spacing(3.8),
      },
    },
  })
);

const jointTableLabels = [
  <FormattedMessage id="allocationView.mainView.table.company" />,
  <FormattedMessage id="allocationView.mainView.table.organizationNumber" />,
  <FormattedMessage id="allocationView.mainView.diselSingleCounting" />,
  <FormattedMessage id="allocationView.mainView.diselDoubleCounting" />,
  <FormattedMessage id="allocationView.mainView.gasolineSingleCounting" />,
  <FormattedMessage id="allocationView.mainView.gasolineDoubleCounting" />,
  <FormattedMessage id="allocationView.mainView.table.totalLiter" />,
];

interface Props {
  isSimulation: boolean;
  isJoint: boolean;
  isFirstSplit: boolean;
  hasStarted: boolean;
  splitYear: any[];
  activePeriod: any;
  soldVolumes: any;
  constructionDieselVolumes: any;
  toggleSimulation: any;
  toggleJoint: any;
  toggleSplitYear: any;
  startAllocation: any;
  getSoldVolumes: any;
  createSoldVolumes: any;
  deleteSoldVolumes: any;
  getConstructionDieselVolumes: any;
  createConstructionDieselVolumes: any;
  deleteConstructionDieselVolumes: any;
}

const MainView: React.FC<Props> = ({
  isSimulation,
  isJoint,
  isFirstSplit,
  hasStarted,
  splitYear,
  activePeriod,
  soldVolumes,
  constructionDieselVolumes,
  toggleSimulation,
  toggleJoint,
  toggleSplitYear,
  startAllocation,
  getSoldVolumes,
  createSoldVolumes,
  deleteSoldVolumes,
  getConstructionDieselVolumes,
  createConstructionDieselVolumes,
  deleteConstructionDieselVolumes,
}) => {
  const [showAddModal, SetShowAddModal] = useState<boolean>(false);
  const [showEditModal, SetShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, SetShowDeleteModal] = useState<boolean>(false);
  const [paginationOffset, SetPaginationOffset] = useState<number>(0);

  const [pageData, SetPageData] = useState<any>({});

  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();

  const openAddModal = () => SetShowAddModal(true);
  const closeAddModal = () => {
    SetShowAddModal(false);
    fetchPageData();
  };

  const openEditModal = () => {
    SetShowEditModal(true);
  };
  const closeEditModal = () => {
    SetShowEditModal(false);
    fetchPageData();
  };

  const openDeleteModal = () => {
    SetShowDeleteModal(true);
  };
  const closeDeleteModal = () => {
    SetShowDeleteModal(false);
    fetchPageData();
  };

  const handleStartAllocation = () => {
    if (match) {
      startAllocation();
      history.push(`${match.url}/start`);
    }
  };

  const handleContinueAllocation = () => {
    if (match) {
      history.push(`${match.url}/start`);
    }
  };

  const handlePagination = (offset: number) => {
    SetPaginationOffset(offset);
  };

  const fetchPageData = async (params?: any) => {
    SetPageData({});
    SetPaginationOffset(0); // reset pagination
    if (!params) {
      params = {
        allocation: isSimulation ? "Simulation" : "Reporting",
        fulfilment: isJoint ? "collective" : "own",
        taxYearId: activePeriod.id,
      };
    }
    const res = await getAllocationPageData(params);

    if (res && res.success) {
      getSoldVolumes();
      getConstructionDieselVolumes();
      SetPageData(res);
    }
  };

  const isLoading =
    !(typeof pageData === "object" && Object.keys(pageData).length > 0) ||
    soldVolumes.isFetching;

  useEffect(() => {
    fetchPageData();
  }, [isSimulation, isJoint, isFirstSplit]);

  const collabData = pageData.sustainability_quantities;
  const outputData = pageData.output;

  const getSoldVolumesTotal = () => {
    const data = soldVolumes.data;
    const default_values = soldVolumes.default;
    if (Object.keys(default_values).length) {
      return (
        parseFloat(default_values.sold_nafta) +
        parseFloat(default_values.sold_etanol) +
        parseFloat(default_values.sold_hvo_hro) +
        parseFloat(default_values.sold_fame)
      );
    }
    return data.nafta + data.etanol + data.hvo_hro + data.fame;
  };

  return (
    <div className={classes.root}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h2" className={classes.h2}>
              Road Use Allocation
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.toggleCardContainer}>

        {splitYear && (
          <Grid item xs={6}>
            <Card>
              <CardContent className={classes.toggleCardContent}>
                <Typography variant="h5" className={classes.toggleCardTitle}>
                  <FormattedMessage id="allocationView.mainView.allocationPeriod" />
                </Typography>
                <ToggleButton
                  labels={[splitYear[0].name, splitYear[1].name]}
                  defaultLabelIndex={isFirstSplit ? 0 : 1}
                  handleChange={toggleSplitYear}
                />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      {isLoading ? (
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress className={classes.circularProgress} />
        </Box>
      ) : (
        <div>
          <Card className={classes.cardCommon}>
            <CardContent>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                className={classes.tableTitle}
              >
                <Grid item>
                  <Typography variant="h5">
                    <FormattedMessage id="allocationView.mainView.physicalComponentSold" />
                  </Typography>
                  {soldVolumes.gasolineLimitReached && (
                    <Typography variant="body1" color="error">
                      <FormattedMessage id="allocationView.mainView.gasolineLimitSoldVolumes" />
                      {soldVolumes.limit.gasoline_limit}
                    </Typography>
                  )}
                  {soldVolumes.biodieselLimitReached && (
                    <Typography variant="body1" color="error">
                      <FormattedMessage id="allocationView.mainView.biodieselLimitSoldVolumes" />
                      {soldVolumes.limit.biodiesel_limit}
                    </Typography>
                  )}
                </Grid>
                {typeof soldVolumes.data === "object" &&
                Object.keys(soldVolumes.data).length > 0 ? (
                  /* (
                  <Grid item>
                    <ButtonRounded
                      variant="outlined"
                      color="secondary"
                      className={classes.addButton}
                      onClick={deleteSoldVolumes}
                    >
                      <FormattedMessage id="allocationView.mainView.deleteSoldVolumes" />
                    </ButtonRounded>
                  </Grid>
                ) */ <div></div>
                ) : (
                  <Grid item>
                    <ButtonRounded
                      variant="outlined"
                      color="primary"
                      className={classes.addButton}
                      onClick={createSoldVolumes}
                    >
                      <FormattedMessage id="allocationView.mainView.addSoldVolumes" />
                    </ButtonRounded>
                  </Grid>
                )}
              </Grid>

              {typeof soldVolumes.data === "object" &&
                Object.keys(soldVolumes.data).length > 0 && (
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <Table className={classes.table}>
                        <TableBody>
                          <TableRow>
                            <TableCell>{"Etanol"}</TableCell>
                            <TableCell align="right">
                              <SoldVolumeTextField type="etanol" />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{"Nafta"}</TableCell>
                            <TableCell align="right">
                              <SoldVolumeTextField type="nafta" />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{"FAME"}</TableCell>
                            <TableCell align="right">
                              <SoldVolumeTextField type="fame" />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{"HVO/HRO"}</TableCell>
                            <TableCell align="right">
                              <SoldVolumeTextField type="hvo_hro" />
                            </TableCell>
                          </TableRow>
                          <TableRow className={classes.soldVolumesTotal}>
                            <TableCell>{"Grand Total"}</TableCell>
                            <TableCell align="right">
                              {parseData(getSoldVolumesTotal())}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                )}
            </CardContent>
          </Card>

          <ModalAddEditCollab
            open={showAddModal}
            onClose={closeAddModal}
            mode="add"
          />
        </div>
      )}
    </div>
  );
};

export default MainView;
