/**
 * Create / Edit excise duty reports
 */

import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import TextField from "@material-ui/core/TextField";
import SmartSelect from "../../../../components/SmartSelect";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ButtonRounded from "../../../../components/ButtonRounded";
import { ISelectedYearMonth } from "../interfaces";
import { FormattedMessage } from "react-intl";

import ModalInfo from "../../../../components/modal/ModalInfo";
import useModal from "../../../../components/modal/useModal";
import ChangeHistory from "./ChangeHistory";
import ModalSaveReport from "./ModalSaveReport";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    h2: {
      [theme.breakpoints.down("md")]: {
        fontSize: "32px",
      },
    },

    threeButton: {
      margin: "0px",
      display: "flex",
      // maxWidth: "570px",
    },
    btn: {
      width: "180px",
      padding: "13px 0",
      lineHeight: "14px",
      "&:not(:first-child)": {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("md")]: {
        width: "130px",
        padding: "12px 0 9px 0",
        "&:not(:first-child)": {
          marginLeft: "10px",
        },
      },
    },
    changeHistory: {
      marginTop: theme.spacing(4),
    },
    createEditReport: {
      marginTop: theme.spacing(2.5),
      paddingTop: theme.spacing(2),
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    table: {
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: "none",
        // paddingTop: theme.spacing(0),
        // paddingBottom: theme.spacing(0),
        padding: theme.spacing(0, 2),
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontSize: "14px",
        fontWeight: "bold",
        paddingBottom: theme.spacing(2),
      },
    },
    tableEmpltyRow: {
      "& .MuiTableCell-root": {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
      },
    },
    select: {
      minWidth: "120px",
      margin: theme.spacing(1.25, 0),
      // marginRight: theme.spacing(1),
      // [theme.breakpoints.down("md")]: {
      //   width: "100px",
      // },
    },
    greyText: {
      color: theme.palette.action.disabled,
    },
    btnNewLine: {
      marginTop: theme.spacing(2),
      textTransform: "none",
    },
    addNewLine: {
      letterSpacing: "0.0",
      fontWeight: 500,
    },
  })
);

interface CreateEditViewProps {
  mode: "create" | "edit"; // which view to show
  selectedYearMonth: ISelectedYearMonth;
  pagedata?: any;
  deleteAnItem: (period: string) => void;
  deleteError: any;
  updataItems: (value: any) => void;
  updatingError: any;
}

const CreateEditView: React.FC<CreateEditViewProps> = ({
  mode,
  selectedYearMonth,
  pagedata,
  deleteAnItem,
  deleteError,
  updataItems,
  updatingError,
}) => {
  const { openModalId, toggle } = useModal();
  const [manualReports, setManualReports] = useState<any[]>([]);
  const classes = useStyles();
  const history = useHistory();
  const [infoModal, setInfoModal] = useState<any>();
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const { selectedMonthIndex, selectedYear } = selectedYearMonth;

  const openModal = (
    id: string,
    text: string,
    message: string,
    onClose: () => void
  ) => {
    if (id === "info") {
      setInfoModal({ title: text, description: message, onClose });
    }
    toggle(id);
  };

  const closeModal = () => {
    toggle();
  };

  const openSaveModal = () => {
    setShowSaveModal(true);
  };
  const closeSaveModal = () => {
    setShowSaveModal(false);
  };

  const goToReports = () => {
    history.push(`/excise-duty/reports`);
  };

  const isCreateMode = mode === "create";
  const isEditMode = mode === "edit";

  const formattedSelectedMonthNumber =
    selectedMonthIndex + 1 < 10
      ? `0${selectedMonthIndex + 1}`
      : selectedMonthIndex + 1;

  const handleReportSubmit = async (values: any) => {
    const period = `${selectedYear}/${formattedSelectedMonthNumber}`;
    const comment = "COMMENT";

    const newValues = {
      period,
      comment,
      manual_tax_reports: values.manual_tax_reports.map(
        (item: any, index: number) => ({
          period,
          serial: index + 1,
          ...item,
        })
      ),
    };

    setData(newValues);
    openSaveModal();
  };

  const handleDelete = () => {
    deleteAnItem(`${selectedYear}/${formattedSelectedMonthNumber}`);
    if (deleteError === undefined) {
      openModal("info", `Successfully deleted report`, "", goToReports);
    } else {
      openModal(
        "info",
        `Failed to delete report`,
        "Network related error",
        closeModal
      );
    }
  };

  const fetchManualReports = () => {
    setManualReports([]);
    const reports = pagedata.filter(
      (report: any) =>
        report.tax_year === selectedYear &&
        report.month === selectedMonthIndex + 1
    );
    setManualReports(reports);
  };

  useEffect(() => {
    if (isEditMode) {
      fetchManualReports();
    }
  }, []);

  const getSelect = (props: any, type: string) => {
    return (
      <SmartSelect
        {...props.input}
        fullWidth
        displayEmpty
        type={type}
        className={classes.select}
      />
    );
  };

  const initialValues = data
    ? data.manual_tax_reports
    : isCreateMode
    ? [
        {
          tax_type: "",
          tax_group: "",
          additional_code: "",
          quantity: "",
        },
      ]
    : manualReports.map(report => ({
        tax_type: report.tax_type,
        tax_group: report.tax_group,
        additional_code: report.additional_code,
        quantity: report.quantity,
      }));

  // basic validations
  const validateRequired = (value: any) => (value ? undefined : "Required");
  const validateRequiredArray = (value: any) =>
    value && value.length > 0 ? undefined : "Required";

  return (
    <div>
      <Form
        onSubmit={handleReportSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={{
          manual_tax_reports: initialValues,
        }}
      >
        {({
          handleSubmit,
          form: {
            mutators: { push },
          },
          submitting,
          pristine,
          hasValidationErrors,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h2" className={classes.h2}>
                  {isCreateMode && (
                    <FormattedMessage id="exciseDutyView.reportsView.createView.heading" />
                  )}
                  {isEditMode && (
                    <FormattedMessage id="exciseDutyView.reportsView.editView.heading" />
                  )}
                </Typography>
              </Grid>
              <Grid item className={classes.threeButton}>
                {isEditMode && (
                  <ButtonRounded
                    variant="outlined"
                    color="secondary"
                    className={classes.btn}
                    onClick={handleDelete}
                  >
                    <FormattedMessage id="exciseDutyView.reportsView.deleteReport" />
                  </ButtonRounded>
                )}

                <ButtonRounded
                  variant="contained"
                  color="secondary"
                  className={classes.btn}
                  onClick={goToReports}
                >
                  <FormattedMessage id="settingsView.modal.cancel" />
                </ButtonRounded>

                <ButtonRounded
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                  type="submit"
                  disabled={
                    submitting || (pristine && !data) || hasValidationErrors
                  }
                >
                  <FormattedMessage id="settingsView.modal.save" />
                </ButtonRounded>
              </Grid>
            </Grid>
            {isEditMode && (
              <ChangeHistory
                className={classes.changeHistory}
                selectedMonthIndex={selectedMonthIndex}
                selectedYear={selectedYear}
              />
            )}
            <Card className={classes.createEditReport}>
              <CardContent>
                <Typography variant="h5" className={classes.title}>
                  {moment.months("MMMM", selectedMonthIndex)} {selectedYear}
                </Typography>

                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="exciseDutyView.reportsView.mainView.period" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="exciseDutyView.reportsView.mainView.lineNumber" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="exciseDutyView.reportsView.mainView.taxType" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="exciseDutyView.reportsView.mainView.taxGroup" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="exciseDutyView.reportsView.mainView.additionalCode" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="exciseDutyView.reportsView.mainView.quantity" />
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray
                      name="manual_tax_reports"
                      validate={validateRequiredArray}
                    >
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {selectedYear}/{formattedSelectedMonthNumber}
                            </TableCell>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              <Field
                                name={`${name}.tax_type`}
                                validate={validateRequired}
                              >
                                {props => {
                                  return getSelect(props, "tax_type");
                                }}
                              </Field>
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`${name}.tax_group`}
                                validate={validateRequired}
                              >
                                {props => getSelect(props, "tax_group")}
                              </Field>
                            </TableCell>
                            <TableCell>
                              <Field name={`${name}.additional_code`}>
                                {props => (
                                  <TextField
                                    {...props.input}
                                    variant="outlined"
                                    placeholder="E.g. 30"
                                    fullWidth
                                  />
                                )}
                              </Field>
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`${name}.quantity`}
                                validate={validateRequired}
                              >
                                {props => (
                                  <TextField
                                    {...props.input}
                                    variant="outlined"
                                    placeholder="E.g. 100 000,00"
                                    fullWidth
                                  />
                                )}
                              </Field>
                            </TableCell>
                            <TableCell>
                              <IconButton onClick={() => fields.remove(index)}>
                                <DeleteOutlinedIcon
                                  fontSize="small"
                                  color={"error"}
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </FieldArray>
                  </TableBody>
                </Table>

                <Button
                  color="primary"
                  size="small"
                  className={classes.btnNewLine}
                  onClick={() =>
                    push("manual_tax_reports", {
                      tax_type: "",
                      tax_group: "",
                      additional_code: "",
                      quantity: "",
                    })
                  }
                >
                  <AddCircleOutlineIcon />
                  &nbsp;&nbsp;
                  <Typography variant="body2" className={classes.addNewLine}>
                    <FormattedMessage id="exciseDutyView.reportsView.addLine" />
                  </Typography>
                </Button>
              </CardContent>
            </Card>
          </form>
        )}
      </Form>
      {infoModal && (
        <ModalInfo
          text={infoModal.title}
          message={infoModal.description}
          open={openModalId === "info"}
          onClose={infoModal.onClose}
        />
      )}
      {data && (
        <ModalSaveReport
          open={showSaveModal}
          onClose={closeSaveModal}
          data={data}
          updatingError={updatingError}
          updataItems={updataItems}
        />
      )}
    </div>
  );
};

export default CreateEditView;
