import { getToken } from "../../../utils";
import { BASE_URL } from "../../../endpoints";

export default async (values: any) => {
  const token = getToken();

  let valid = false;
  let data = [];
  let biofuelList;

  if (token) {
    try {
      const response = await fetch(`${BASE_URL}/api/reconciliation/reconcile_volume?biofuel_type=${decodeURIComponent(values.biofuel_type)}&storage_terminal=${decodeURIComponent(values.storage_terminal)}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      biofuelList = await response.json();
      valid = biofuelList;

      data = biofuelList.data;
    } catch (except) {
      valid = false; // network error
    }
  } else {
    return null; // No token
  }

  if (!valid) {
    if (biofuelList && biofuelList.error) {
      return {
        valid: false,
        message: biofuelList.error,
      };
    }
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    data,
  };
};
