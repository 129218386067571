import { ErrorPayload } from "../../../common/payloads/ErrorActionPayload";
import { Action, handleActions } from "redux-actions";
import fetchFiles from "../../../api/exciseduty/reports/getAllReports";
import deleteReport from "../../../api/exciseduty/reports/deleteReport";
import updateReport from "../../../api/exciseduty/reports/postReport";
type ReportType = "manual" | "original";
// State Interface
export interface ReportsState {
  isFetching: boolean;
  data: any[];
  error?: any;
  toggleReportType: ReportType;
  isDeleting: boolean;
  deleteError?: any;
  isUpdating: boolean;
  updatingError: any;
}

// Initial state
const initialState = {
  isFetching: false,
  data: [],
  error: undefined,
  toggleReportType: "manual",
  isDeleting: false,
  deleteError: undefined,
  isUpdating: false,
  updatingError: undefined,
} as ReportsState;

// Actions
const REPORTDATA_REQUEST = "exciseDuty/reports/REPORTDATA_REQUEST";
const REPORTDATA_ERROR = "exciseDuty/reports/REPORTDATA_ERROR";
const REPORTDATA_SUCCESS = "exciseDuty/reports/REPORTDATA_SUCCESS";
const REPORT_TYPE = "exciseDuty/reports/REPORT_TYPE";
const REPORTDATA_DELETE_REQUEST =
  "exciseDuty/reports/REPORTDATA_DELETE_REQUEST";
const REPORTDATA_DELETE_SUCCESS =
  "exciseDuty/reports/REPORTDATA_DELETE_SUCCESS";
const REPORTDATA_DELETE_ERROR = "exciseDuty/reports/REPORTDATA_DELETE_ERROR";
const REPORTDATA_UPDATE_REQUEST =
  "exciseDuty/reports/REPORTDATA_UPDATE_REQUEST";
const REPORTDATA_UPDATE_SUCCESS =
  "exciseDuty/reports/REPORTDATA_UPDATE_SUCCESS";
const REPORTDATA_UPDATE_ERROR = "exciseDuty/reports/REPORTDATA_UPDATE_ERROR";
// Action creators
function requestFiles() {
  return {
    type: REPORTDATA_REQUEST,
  };
}

function errorFiles(error: any) {
  return {
    type: REPORTDATA_ERROR,
    payload: { error },
  };
}

function receiveFiles(data: any) {
  return {
    type: REPORTDATA_SUCCESS,
    payload: data,
  };
}
export function updataReportTypethenFetch(toggleReportType: ReportType) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.exciseDuty.reports) {
      dispatch(setReportType(toggleReportType));
      dispatch(getExciseDutyReport(toggleReportType));
    }
    return Promise.resolve;
  };
}
export function getExciseDutyReport(toggleReportType: ReportType) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.exciseDuty.reports) {
      dispatch(requestFiles());
      try {
        const data = await fetchFiles({ toggleReportType });
        dispatch(receiveFiles(data));
      } catch (error) {
        dispatch(errorFiles(error));
      }
    }
    return Promise.resolve;
  };
}
function setReportType(toggleReportType: ReportType) {
  return {
    type: REPORT_TYPE,
    payload: toggleReportType,
  };
}

function deleteItem() {
  return {
    type: REPORTDATA_DELETE_REQUEST,
  };
}
function deleteSuccess() {
  return {
    type: REPORTDATA_DELETE_SUCCESS,
  };
}

function deleteError(error: any) {
  return {
    type: REPORTDATA_DELETE_ERROR,
    payload: { error },
  };
}
export function deleteAnItem(period: string) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.exciseDuty.reports) {
      dispatch(deleteItem());
      try {
        const data = await deleteReport(period);
        if (data && data.success) {
          dispatch(deleteSuccess());
          dispatch(getExciseDutyReport("manual"));
        } else {
          dispatch(deleteError("Error"));
        }
        // getExciseDutyReport("manual");
      } catch (error) {
        dispatch(deleteError(error));
      }
    }
    return Promise.resolve;
  };
}

function updateItem() {
  return {
    type: REPORTDATA_UPDATE_REQUEST,
  };
}
function updateSuccess(data: any, period: any) {
  return {
    type: REPORTDATA_UPDATE_SUCCESS,
    payload: { data, period },
  };
}

function updateError(error: any) {
  return {
    type: REPORTDATA_UPDATE_ERROR,
    payload: { error },
  };
}
export function updataItems(value: any) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.exciseDuty.reports) {
      dispatch(updateItem());
      try {
        const data = await updateReport(value);
        if (data && data.success) {
          dispatch(updateSuccess(data.data, value.period));
        } else {
          dispatch(updateError("Error"));
        }
      } catch (error) {
        dispatch(updateError(error));
      }
    }
    return Promise.resolve;
  };
}
// Reducer Definition
const ReportsReducer = handleActions<ReportsState>(
  {
    [REPORTDATA_REQUEST]: handleReportRequest,
    [REPORTDATA_ERROR]: handleReportError,
    [REPORTDATA_SUCCESS]: handleReportSuccess,
    [REPORT_TYPE]: handleReportType,
    [REPORTDATA_DELETE_REQUEST]: handleDeleteRequest,
    [REPORTDATA_DELETE_SUCCESS]: handleDeteleSuccess,
    [REPORTDATA_DELETE_ERROR]: handleDeleteError,
    [REPORTDATA_UPDATE_REQUEST]: handleUpdateRequest,
    [REPORTDATA_UPDATE_SUCCESS]: handleUpdateSuccess,
    [REPORTDATA_UPDATE_ERROR]: handleUpdateError,
  } as any,
  initialState
);

// Reducer Description
function handleReportRequest(state: ReportsState) {
  return {
    ...state,
    isFetching: true,
    error: undefined as any,
  };
}

function handleReportError(state: ReportsState, action: Action<ErrorPayload>) {
  return {
    ...state,
    isFetching: false,
    error: action.payload.error,
  };
}

function handleReportSuccess(state: ReportsState, action: Action<any>) {
  return {
    ...state,
    isFetching: false,
    data: action.payload,
    error: undefined as any,
  };
}

function handleReportType(state: ReportsState, action: Action<any>) {
  return {
    ...state,
    toggleReportType: action.payload,
  };
}
// handle delete
function handleDeleteRequest(state: ReportsState) {
  return {
    ...state,
    isDeleting: true,
    deleteError: undefined as any,
  };
}
function handleDeleteError(state: ReportsState, action: Action<ErrorPayload>) {
  return {
    ...state,
    isDeleting: false,
    deleteError: action.payload.error,
  };
}
function handleDeteleSuccess(state: ReportsState) {
  return {
    ...state,
    isDeleting: false,
    deleteError: undefined as any,
  };
}
// update
function handleUpdateRequest(state: ReportsState) {
  return {
    ...state,
    isUpdating: true,
    updatingError: undefined as any,
  };
}
function handleUpdateError(state: ReportsState, action: Action<ErrorPayload>) {
  return {
    ...state,
    isUpdating: false,
    updatingError: action.payload.error,
  };
}
function handleUpdateSuccess(state: ReportsState, action: Action<any>) {
  const filteredData = state.data.filter(row => !(row.period === action.payload.period)
  );
  const newData = [...filteredData, ...action.payload.data];
  return {
    ...state,
    isUpdating: false,
    updatingError: undefined as any,
    data: newData,
  };
}
export default ReportsReducer;
