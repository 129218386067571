import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import parseData from "../../../../../../components/texts/parseData";

import SmartTable from "../../../../../../components/SmartTable/index";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      overflowY: "auto",
      "& .MuiOutlinedInput-input": {
        ...theme.typography.caption,
      },
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        ...theme.typography.caption,
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        ...theme.typography.caption,
        fontWeight: 500,
      },
    },
  })
);

const tableHeadings = [
  { name: "Biofuel Type", size: "9%", id: "biofuel_type" },
  { name: "Origin", size: "9%", id: "raw_material_origin" },
  { name: "Biocomponent Type", size: "14%", id: "fuel_type" },
  { name: "Raw Material", size: "11%", id: "raw_material" },
  { name: "Advanced", size: "8%", id: "double_counted_norway" },
  { name: "Ghg Reduction", size: "8%", id: "reduction_in_climate_emission" },
  { name: "Storage Terminal", size: "8%", id: "storage_terminal" },
  {
    name: "Allocated Quantity (tCO2)",
    size: "11%",
    alignRight: true,
    id: "allocated_quantity",
  },
  {
    name: "Volume (L)",
    size: "11%",
    alignRight: true,
    id: "volume",
  },
];

interface Props {
  stepData: any;
  data: any[];
  type: string;
  available_quantities: any;
  allocateUpdate: any;
  customer: { id: number; index: number };
  requirement: { id: number; index: number; type: string };
}

const EditableTableCustomer: React.FC<Props> = ({
  stepData,
  data,
  type,
  available_quantities,
  customer,
  requirement,
  allocateUpdate,
}) => {
  const classes = useStyles();

  const handleOnChange = (e: any, row: any, ridx: number) => {
    allocateUpdate(row.id, ridx, e.target.value, requirement, customer);
  };

  const handleOnBlur = (e: any, row: any, ridx: number) => {
    if (e.target.value === "") {
      allocateUpdate(row.id, ridx, 0, requirement, customer);
    }
  };

  const isDoubleCount = (row: any) => {
    return row.double_counted_norway === "Ja";
  };

  const getAllocated = (row: any): number => {

    return row.allocated_ton_quantity
    // let value = 0;
    // value =
    //   stepData.allocated_sorted[customer.id] &&
    //   stepData.allocated_sorted[customer.id][requirement.id] &&
    //   stepData.allocated_sorted[customer.id][requirement.id][type] &&
    //   stepData.allocated_sorted[customer.id][requirement.id][type][row.id];
    // return value;
  };

  const parseVal = (value: any) => {
    let parsed = value;
    if (parsed) {
      if (value > -1 && value < 0) { parsed *= -1; }
      return parseData(parsed, 2);
    } else { return parsed; }
  };

  const getData = (data: any[]) => {
    data.map((row: any, index: number) => {
      console.log("biofuel type")
      console.log(row.biofuel_type.toLowerCase())
      console.log("step data")
      console.log(stepData.data[customer.index].customer_requirements[
          requirement.index
          ])
      console.log("row details")
      console.log(row)
      const biofuel_type = row.biofuel_type.toLowerCase() == "hvo/hro"?"hvo_hro":row.biofuel_type.toLowerCase()
      data[index].volume = parseVal(
          (() => {
            const volume = parseFloat(
                stepData.data[customer.index].customer_requirements[
                    requirement.index
                    ]["volume_" + biofuel_type]
            );

            const allocated = getAllocated(row);

            const required_emission_reduction = parseFloat(
                stepData.data[customer.index].customer_requirements[
                    requirement.index
                    ]["required_emission_reduction_" + biofuel_type]
            );

            // If the required emission reduction is zero, avoid division by zero and return 0
            if (required_emission_reduction === 0) {
              return 0;
            }

            // Perform the calculation if the required emission reduction is not zero
            return (volume * allocated) / required_emission_reduction;
          })()
      );
    });
    return data;
  };
  return (
    <div className={classes.tableContainer}>
      <SmartTable
        headCells={tableHeadings}
        rows={data && data.length != 0 ? getData(data) : null}
        editableProps={{
          id: "allocated_quantity",
          getValue: getAllocated,
          onChange: handleOnChange,
          onBlur: handleOnBlur,
        }}
        verticalOverflow={true}
      />
    </div>
  );
};

export default EditableTableCustomer;
