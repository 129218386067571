/**
 *  Dashboard: My Profile page
 */

import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import avatarIcon from "../../../assets/icons/avatar.svg";
import ButtonRounded from "../../../components/ButtonRounded";
import ChangeEmailModal from "./ChangeEmailModal";
import ChangePasswordModal from "./ChangePasswordModal";
import {
  profileGetInfo,
  IProfileData,
  isProfileData,
} from "../../../api/profileGetInfo";
import { IFailAPIResponse, isFailAPIResponse } from "../../../api/Interfaces";
import { FormattedMessage } from "react-intl";
import ToggleButton from "../../../components/ToggleButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    toggleLanguage: {
      padding: theme.spacing(3),
    },
    profileCard: {
      width: "507px",
      "& .MuiCardContent-root": {
        padding: "74px 0",
      },
    },
    avatar: {
      width: "120px",
      height: "120px",
      marginBottom: theme.spacing(6.25),
    },
    userDetails: {
      marginTop: theme.spacing(1),
      color: theme.palette.grey[600],
    },
    btnContainer: {
      marginTop: theme.spacing(4),
      maxWidth: "250px",
      "& button:first-child": {
        marginBottom: theme.spacing(2.5),
        "& .MuiButton-label": {
          lineHeight: "14px",
        },
      },
    },
  })
);

interface Props {
  language: string;
  setLanguage: any;
}

const MyProfileView: React.FC<Props> = ({ language, setLanguage }) => {
  const [profileData, setProfileData] = useState<IProfileData | null>(null);
  const [errorMessage, setErrorMessage] = useState<IFailAPIResponse | null>(
    null
  );
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
  const classes = useStyles();

  const getProfileData = async () => {
    const data = await profileGetInfo();
    if (isProfileData(data)) { setProfileData(data); }
    else if (isFailAPIResponse(data)) { setErrorMessage(data); }
  };

  const openEmailModal = () => setShowEmailModal(true);
  const closeEmailModal = () => setShowEmailModal(false);
  const updateEmail = (newEmail: string) => {
    if (profileData !== null) {
      setProfileData({ ...profileData, email: newEmail });
    } else {
      getProfileData();
    }
  };

  const openPasswordModal = () => setShowPasswordModal(true);
  const closePasswordModal = () => setShowPasswordModal(false);

  const isAdmin: boolean =
    profileData && profileData.role
      ? profileData.role.toLocaleLowerCase().indexOf("admin") >= 0
      : false;

  useEffect(() => {
    getProfileData();
  }, []);

  if (errorMessage !== null) {
    return <div>{errorMessage.message}</div>;
  } else if (profileData === null) {
    return <div>{"Loading..."}</div>;
  }

  return (
    <div className={classes.root}>
      <Card className={classes.profileCard}>
        <div className={classes.toggleLanguage}>
          <ToggleButton
            labels={["en", "no"]}
            defaultLabelIndex={language === "en" ? 0 : 1}
            handleChange={setLanguage}
            size="small"
          />
        </div>
        <CardContent>
          <Grid container direction="column" alignItems="center">
            <Avatar className={classes.avatar} src={avatarIcon} />
            <Typography variant="h5" gutterBottom>
              {profileData.username}
            </Typography>
            <Grid
              container
              direction="column"
              alignItems="center"
              className={classes.userDetails}
            >
              <Typography variant="body1" gutterBottom>
                {profileData.company}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {profileData.role}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {profileData.email}
              </Typography>
            </Grid>
            <Grid container direction="column" className={classes.btnContainer}>
              {isAdmin && (
                <ButtonRounded
                  variant="outlined"
                  color="primary"
                  onClick={openEmailModal}
                >
                  {<FormattedMessage id="myProfileView.index.changeEmail" />}
                </ButtonRounded>
              )}
              <ButtonRounded
                variant="outlined"
                color="primary"
                onClick={openPasswordModal}
              >
                {<FormattedMessage id="myProfileView.index.changePassword" />}
              </ButtonRounded>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <ChangeEmailModal
        open={showEmailModal}
        onClose={closeEmailModal}
        updateEmail={updateEmail}
      />
      <ChangePasswordModal
        open={showPasswordModal}
        onClose={closePasswordModal}
      />
    </div>
  );
};

export default MyProfileView;
