import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ButtonRounded from "../../../../components/ButtonRounded";
import ToggleButton from "../../../../components/ToggleButton";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import SimpleTable from "./SimpleTable";
import AllocStatusTable from "./AllocStatusTable";
// import getPageData2 from "../../../../api/sustainability/reports/getPageData";
import getConfirmState from "../../../../api/sustainability/reports/getConfirmState";
import getDownloadReport, {
  Tfiletype,
} from "../../../../api/sustainability/reports/getDownloadReport";
import ModalInfo from "../../../../components/modal/ModalInfo";
import {
  allocationTableData,
  sustainableTableData,
  reportedTableData,
  jointTableData,
  aggregatedTableData,
  summarizedReportTableData,
  calculationTaxTableData,
  availablePoolBiotemplatesData,
  poolStatusDieselData,
  poolStatusGasolineData,
} from "./tableData";
import { FormattedMessage } from "react-intl";
import SmartTable from "../../../../components/SmartTable";
import ModalConfirmDelivery from "./ModalConfirmDelivery";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import SmartSelect from "../../../../components/SmartSelect";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& .MuiOutlinedInput-input": {
          padding: theme.spacing(1),
          backgroundColor: theme.palette.common.white,
          color: theme.palette.primary.main,
          fontSize: "14px",
        },
      },
      titleSection: {
        marginBottom: theme.spacing(3),
      },
      h2: {
        [theme.breakpoints.down("md")]: {
          fontSize: "32px",
        },
      },
      select: {
        width: "150px",
        [theme.breakpoints.down("md")]: {
          width: "100px",
        },
      },
      sectionDownloads: {
        marginBottom: theme.spacing(2),
      },
      section: {
        marginBottom: theme.spacing(3),
        "&:last-child": {
          marginBottom: 0,
        },
      },
      card: {
        height: "100%",
      },
      cardContent: {
        padding: theme.spacing(3.75),
        paddingTop: theme.spacing(4),
        "&:last-child": {
          paddingBottom: theme.spacing(3.75),
        },
      },
      cardTitle: {
        marginBottom: theme.spacing(0.5),
      },
      cardSubTitle: {
        fontSize: theme.typography.h5.fontSize,
        marginBottom: theme.spacing(2.5),
        marginTop: theme.spacing(2),
      },
      btnDownloads: {
        height: "100%",
      },
      circularProgress: {
        margin: "80px",
        width: "48px",
        height: "48px",
      },
    })
);

interface Props {
  activePeriod: any;
  isSimulation: boolean;
  isJoint: boolean;
  toggleSimulation: any;
  data: any;
  getPageData: any;
  isFetching: boolean;
  taxPeriods: any[];
  selectedYear: number;
  setSelectedYear: any;
}

const ReportsView: React.FC<Props> = ({
                                        activePeriod,
                                        isSimulation,
                                        isJoint,
                                        toggleSimulation,
                                        data,
                                        getPageData,
                                        isFetching,
                                        taxPeriods,
                                        selectedYear,
                                        setSelectedYear,
                                      }) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [selectedPart, setSelectedPart] = useState<any>(activePeriod.name);
  const [
    showConfirmDeliveryModal,
    setShowConfirmDeliveryModal,
  ] = useState<boolean>(false);

  const openErrorModal = (msg: string) => {
    setErrorMessage(msg);
    setShowErrorModal(true);
  };
  const closeErrorModal = () => {
    setErrorMessage("");
    setShowErrorModal(false);
  };

  const openConfirmDeliveryModal = () => {
    setShowConfirmDeliveryModal(true);
  };

  const closeConfirmDeliveryModal = () => {
    setShowConfirmDeliveryModal(false);
  };

  const classes = useStyles();

  const handleDownload = async (type: Tfiletype) => {
    const response = await getDownloadReport({
      type,
      mandate_type: '',
      taxYear: selectedYear,
    });
    if (response === null) {
      openErrorModal(
          "Failed to download file: Possible errors: no file for selected year or other errors."
      );
    }

    if (response !== null) {
      // emulate file download by clicking on an <a /> link
      const url = window.URL.createObjectURL(response);
      const fileName =
          type.indexOf("sub") > -1
              ? "sustainability-subreport"
              : "sustainability-report";
      const fileExt = type.indexOf("excel") > -1 ? "xlsx" : "json";

      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}-${selectedYear}.${fileExt}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };

  const handleConfirmationStatus = async () => {
    const res = await getConfirmState({ taxYear: selectedYear });
    if (res === null) {
      openErrorModal(
          "Failed to confirm delivery. Possible errors: correct reporting year is not selected or other errors."
      );
    } else {
      openErrorModal(
          "Delivery Confirmed."
      );
      getPageData(selectedYear);
    }
  };

  // on init
  useEffect(() => {
    setSelectedYear(activePeriod.year);
  }, []);

  useEffect(() => {
    getPageData(selectedYear);
  }, [selectedYear]);

  // sth need to be done here
  const confirmationStatus =
      Array.isArray(data.confirmation_status) &&
      data.confirmation_status.includes(true);
  const collectiveFulfillement = data.collective_fulfillment === true;

  // Extract required table data from API
  const selectedPartIndex =
      data && data.tax_year_seq && data.tax_year_seq.indexOf(selectedPart) != -1
          ? data.tax_year_seq.indexOf(selectedPart)
          : 0;

  const allocationStatusData =
      data &&
      data.requirement_states &&
      data.requirement_states[selectedPartIndex]
          ? allocationTableData.extractAPIData(
              data.requirement_states[selectedPartIndex],
              isJoint
          )
          : [];

  const sustainableBiofuelConstructionData =
      data &&
      data.sustainable_batches &&
      data.sustainable_batches[selectedPartIndex]
          ? sustainableTableData.extractAPIData(
              data.sustainable_batches[selectedPartIndex][0][0]
          )
          : [];
  const sustainableBiofuelRoadTypeData =
      data &&
      data.sustainable_batches &&
      data.sustainable_batches[selectedPartIndex]
          ? sustainableTableData.extractAPIData(
              data.sustainable_batches[selectedPartIndex][0][1]
          )
          : [];
  const reportedVolumesData =
      data && data.reported_volumes && data.reported_volumes[selectedPartIndex]
          ? reportedTableData.extractAPIData(
              data.reported_volumes[selectedPartIndex][0]
          )
          : [];
  // neet to check this one
  const jointFulfillmentData =
      data &&
      data.joint_fulfillments &&
      data.joint_fulfillments[selectedPartIndex]
          ? jointTableData.extractAPIData(
              data.joint_fulfillments[selectedPartIndex]
          )
          : [];

  const availPoolData =
      data &&
      data.available_pool_biotemplates &&
      data.available_pool_biotemplates[selectedPartIndex]
          ? availablePoolBiotemplatesData.extractAPIData(
              data.available_pool_biotemplates[selectedPartIndex][0]
          )
          : [];
  const dieselData =
      data &&
      data.pool_status &&
      poolStatusDieselData.extractAPIData(data.pool_status[selectedPartIndex]);

  const gasolineData =
      data &&
      data.pool_status &&
      poolStatusGasolineData.extractAPIData(data.pool_status[selectedPartIndex]);

  // const checkNull = (data: any) => {
  //   let check = true;
  //   if (data && Array.isArray(data) && data.length > 0) {
  //     console.log("hey" + data);
  //   } else {
  //     check = false;
  //   }

  //   return check;
  // };

  return (
      <div className={classes.root}>
        <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.titleSection}
        >
          <Grid item>
            <Typography variant="h2" className={classes.h2}>
              Draft report: Offroad
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              {!isFetching &&
                  data &&
                  data.tax_year_seq &&
                  data.tax_year_seq.length > 1 && (
                      <Grid item>
                        <Select
                            fullWidth
                            className={classes.select}
                            input={<OutlinedInput labelWidth={0} />}
                            value={selectedPart}
                            onChange={e => setSelectedPart(e.target.value)}
                        >
                          {data.tax_year_seq.map(
                              (partName: string, index: number) => (
                                  <MenuItem value={partName}>
                                    {"Part " +
                                        partName
                                            .replace(selectedYear + "", "")
                                            .match(/\d+/)}
                                  </MenuItem>
                              )
                          )}
                        </Select>
                      </Grid>
                  )}

              <Grid item>
                {/* <SmartSelect
                name="tax_year"
                value={selectedYear}
                onChange={(e: any) => setSelectedYear(e.target.value)}
                style={{ width: "110px" }}
                displayEmpty
                type="tax_years"
              /> */}

                <Select
                    fullWidth
                    className={classes.select}
                    input={<OutlinedInput labelWidth={0} />}
                    value={selectedYear}
                    onChange={e => setSelectedYear(e.target.value)}
                >
                  {taxPeriods
                      ? taxPeriods.map((year: any, index: number) => (
                          <MenuItem
                              value={Object.keys(year)[0]}
                              key={
                                  "Sus.View_Reports_Year_" +
                                  Object.keys(year)[0] +
                                  "-" +
                                  index
                              }
                          >
                            {Object.keys(year)[0]}
                          </MenuItem>
                      ))
                      : null}
                </Select>
              </Grid>

              <Grid item>
                <ToggleButton
                    labels={["Reporting", "Simulation"]}
                    defaultLabelIndex={isSimulation ? 1 : 0}
                    size="small"
                    bgWhite
                    handleChange={toggleSimulation}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.sectionDownloads}>
          <Grid item xs={7}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="h5" className={classes.cardTitle}>
                      Allocation Mode: Reporting
                    </Typography>
                    <Typography variant="h5" className={classes.cardTitle}>
                      Period: 2023
                    </Typography>
                    <Typography variant="h5" className={classes.cardTitle}>
                      Fulfillment type: Own
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={5}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" className={classes.cardTitle}>
                  <p>Report File and Actions</p>
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <ButtonRounded
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {}}
                    >
                      Draft Report (Excel)
                    </ButtonRounded>
                  </Grid>
                  <Grid item xs={4}>
                    <ButtonRounded
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() =>{}}
                    >
                      Tilde report file
                    </ButtonRounded>
                  </Grid>
                  <Grid item xs={4}>
                    <ButtonRounded
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={()=>{}}
                        disabled={false}
                        // className={classes.btnDownloads}
                    >
                      Report overview
                    </ButtonRounded>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {isFetching ? (
            <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
              <CircularProgress className={classes.circularProgress} />
            </Box>
        ) : (
            <div>
              <Card className={classes.section}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h5" className={classes.cardTitle}>
                    Mandate fulfillment status
                  </Typography>
                  {/*<AllocStatusTable*/}
                  {/*  isJoint={isJoint}*/}
                  {/*  columns={allocationTableData.columns}*/}
                  {/*  rows={allocationStatusData}*/}
                  {/*/>*/}
                </CardContent>
              </Card>

              <Card className={classes.section}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h5" className={classes.cardTitle}>
                    Volume summary
                  </Typography>
                  {/*<SimpleTable*/}
                  {/*  columns={sustainableTableData.columns}*/}
                  {/*  rows={sustainableBiofuelConstructionData}*/}
                  {/*/>*/}
                  {/*<Typography variant="body1" className={classes.cardSubTitle}>*/}
                  {/*  <FormattedMessage id="sustainabilityView.reportsView.construction" />*/}
                  {/*</Typography>*/}
                  {/*<SimpleTable*/}
                  {/*  columns={sustainableTableData.columns}*/}
                  {/*  rows={sustainableBiofuelRoadTypeData}*/}
                  {/*/>*/}
                </CardContent>
              </Card>

              <Card className={classes.section}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h5" className={classes.cardTitle}>
                    Allocated batches
                  </Typography>
                  {/*<SimpleTable*/}
                  {/*  columns={reportedTableData.columns}*/}
                  {/*  rows={reportedVolumesData}*/}
                  {/*/>*/}
                </CardContent>
              </Card>
              {/* new table availablePoolBiotemplates */}
              {/* {checkNull(availPoolData) && ( */}

              {/*<Card className={classes.section}>*/}
              {/*  <CardContent className={classes.cardContent}>*/}
              {/*    <Typography variant="h5" className={classes.cardTitle}>*/}
              {/*      <FormattedMessage id="sustainabilityView.reportsView.availablePoolBiotemplates" />*/}
              {/*    </Typography>*/}
              {/*    <SimpleTable*/}
              {/*      columns={availablePoolBiotemplatesData.columns}*/}
              {/*      rows={availPoolData}*/}
              {/*    />*/}
              {/*  </CardContent>*/}
              {/*</Card>*/}

              {/* )} */}

              {/* new pool status table */}
              {/*<Card className={classes.section}>*/}
              {/*  <CardContent className={classes.cardContent}>*/}
              {/*    <Typography variant="h5" className={classes.cardTitle}>*/}
              {/*      <FormattedMessage id="sustainabilityView.reportsView.poolStatus" />*/}
              {/*    </Typography>*/}
              {/*    <Typography variant="body1" className={classes.cardSubTitle}>*/}
              {/*      <FormattedMessage id="sustainabilityView.reportsView.poolStatusSubtitle1" />*/}
              {/*    </Typography>*/}

              {/*    <SimpleTable*/}
              {/*      columns={poolStatusDieselData.columns}*/}
              {/*      rows={dieselData}*/}
              {/*    />*/}
              {/*    <Typography variant="body1" className={classes.cardSubTitle}>*/}
              {/*      <FormattedMessage id="sustainabilityView.reportsView.poolStatusSubtitle2" />*/}
              {/*    </Typography>*/}
              {/*    <SimpleTable*/}
              {/*      columns={poolStatusGasolineData.columns}*/}
              {/*      rows={gasolineData}*/}
              {/*    />*/}
              {/*  </CardContent>*/}
              {/*</Card>*/}
              {isJoint && (
                  <Card className={classes.section}>
                    <CardContent className={classes.cardContent}>
                      <Typography variant="h5" className={classes.cardTitle}>
                        <FormattedMessage id="sustainabilityView.reportsView.jointFulfillment" />
                      </Typography>
                      <SimpleTable
                          columns={jointTableData.columns}
                          rows={jointFulfillmentData}
                      />
                    </CardContent>
                  </Card>
              )}
              {data &&
                  data.annual_calculations &&
                  data.annual_calculations[selectedPartIndex] && (
                      <Card className={classes.section}>
                        <CardContent className={classes.cardContent}>
                          <Typography variant="h5" className={classes.cardTitle}>
                            <FormattedMessage id="sustainabilityView.reportsView.annualReport" />
                          </Typography>

                          <>
                            <Typography
                                variant="body1"
                                className={classes.cardSubTitle}
                            >
                              <FormattedMessage id="sustainabilityView.reportsView.aggregated.tableTitle" />
                            </Typography>

                            <SmartTable
                                whiteHeader
                                verticalOverflow
                                headCells={aggregatedTableData.smartColumns}
                                rows={
                                  Array.isArray(
                                      data.annual_calculations[selectedPartIndex]
                                          .reported_quantities
                                  )
                                      ? data.annual_calculations[selectedPartIndex]
                                          .reported_quantities
                                      : null
                                }
                            />
                          </>

                          <>
                            <Typography
                                variant="body1"
                                className={classes.cardSubTitle}
                            >
                              <FormattedMessage id="sustainabilityView.reportsView.summarizedReport.tableTitle" />
                            </Typography>

                            <SmartTable
                                whiteHeader
                                verticalOverflow
                                headCells={summarizedReportTableData.smartColumns}
                                rows={
                                  Array.isArray(
                                      data.annual_calculations[selectedPartIndex].reports
                                  )
                                      ? data.annual_calculations[selectedPartIndex].reports
                                      : null
                                }
                            />
                          </>

                          <>
                            <Typography
                                variant="body1"
                                className={classes.cardSubTitle}
                            >
                              <FormattedMessage id="sustainabilityView.reportsView.calculationTax.tableTitle" />
                            </Typography>

                            <SmartTable
                                whiteHeader
                                verticalOverflow
                                headCells={calculationTaxTableData.smartColumns}
                                rows={
                                  Array.isArray(
                                      data.annual_calculations[selectedPartIndex]
                                          .tax_credits
                                  )
                                      ? data.annual_calculations[selectedPartIndex]
                                          .tax_credits
                                      : null
                                }
                            />
                          </>
                        </CardContent>
                      </Card>
                  )}
            </div>
        )}
        <ModalInfo
            text={errorMessage}
            open={showErrorModal}
            onClose={closeErrorModal}
        />
        <ModalConfirmDelivery
            open={showConfirmDeliveryModal}
            onClose={closeConfirmDeliveryModal}
            onConfirm={() => {
              closeConfirmDeliveryModal();
              handleConfirmationStatus();
            }}
        />
      </div>
  );
};

export default ReportsView;
