import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import parseData from "../../../../components/texts/parseData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
    },
    card: {
      textAlign: "center",
      padding: theme.spacing(1),
    },
  })
);

const getIncomingValue = (data: any) => {
  let sum = 0;
  Array.isArray(data) &&
    data.map((row: any, index: number) => {
      const strValue = row.quantity
        .toString()
        .replace(",", ".")
        .replace(" ", "");
      const quantity = parseFloat(strValue);
      if (quantity > 0) { sum = sum + quantity; }
    });
  return sum;
};

const getOutgoingValue = (data: any) => {
  let sum = 0;
  Array.isArray(data) &&
    data.map((row: any, index: number) => {
      const strValue = row.quantity
        .toString()
        .replace(",", ".")
        .replace(" ", "");
      const quantity = parseFloat(strValue);
      if (quantity < 0) { sum = sum + quantity; }
    });
  return sum;
};

const getBalanceValue = (data: any) => {
  let sum = 0;
  Array.isArray(data) &&
    data.map((row: any, index: number) => {
      const strValue = row.quantity
        .toString()
        .replace(",", ".")
        .replace(" ", "");
      const quantity = parseFloat(strValue);
      sum = sum + quantity;
    });
  return sum;
};

interface KeyMetricCardProps {
  title: string | React.ReactElement;
  number: string | number;
}

const KeyMetricCard: React.FC<KeyMetricCardProps> = ({ title, number }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Typography variant="body2">{title}</Typography>
      <Typography variant="h6">{number}</Typography>
    </Card>
  );
};

interface IKeyMetricsProps {
  data: any;
}

const KeyMetrics: React.FC<IKeyMetricsProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <KeyMetricCard
            title={"Inngående mengde"}
            number={parseData(getIncomingValue(data))}
          />
        </Grid>
        <Grid item xs={4}>
          <KeyMetricCard
            title={"Utgående mengde"}
            number={parseData(getOutgoingValue(data))}
          />
        </Grid>
        <Grid item xs={4}>
          <KeyMetricCard
            title={"Balanse"}
            number={parseData(getBalanceValue(data))}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default KeyMetrics;
