import { Action, handleActions } from "redux-actions";

export interface ModalsState {
  show_download_pos: boolean;
  show_download_hbl_pos: boolean;
  show_download_report: boolean;
  show_card_download_report: boolean;
}

const initialState = {
  show_download_pos: false,
  show_download_hbl_pos: false,
  show_download_report: false,
  show_card_download_report: false,
} as ModalsState;

const MODALS_SET_SHOW_DOWNLOAD_POS = "modals/SET_SHOW_DOWNLOAD_POS";
const MODALS_SET_SHOW_DOWNLOAD_HBL_POS = "modals/SET_SHOW_DOWNLOAD_HBL_POS";
const MODALS_SET_SHOW_DOWNLOAD_REPORT = "modals/SET_SHOW_DOWNLOAD_REPORT";
const MODALS_SET_SHOW_CARD_DOWNLOAD_REPORT = "modals/SET_SHOW_CARD_DOWNLOAD_REPORT";

export const setShowModalDownloadHBLPOS = (show: boolean) => {
  return {
    type: MODALS_SET_SHOW_DOWNLOAD_HBL_POS,
    payload: {
      show,
    },
  };
};

export const setShowModalDownloadPOS = (show: boolean) => {
  return {
    type: MODALS_SET_SHOW_DOWNLOAD_POS,
    payload: {
      show,
    },
  };
};

export const setShowModalDownloadReport = (show: boolean) => {
  return {
    type: MODALS_SET_SHOW_DOWNLOAD_REPORT,
    payload: {
      show,
    },
  };
};

export const setShowModalCardDownloadReport = (show: boolean) => {
  return {
    type: MODALS_SET_SHOW_CARD_DOWNLOAD_REPORT,
    payload: {
      show,
    },
  };
};

const ModalsReducer = handleActions<ModalsState>(
  {
    [MODALS_SET_SHOW_DOWNLOAD_POS]: handleSetShowModalDownloadPOS,
    [MODALS_SET_SHOW_DOWNLOAD_HBL_POS]: handleSetShowModalDownloadHBLPOS,
    [MODALS_SET_SHOW_DOWNLOAD_REPORT]: handleSetShowModalDownloadReport,
    [MODALS_SET_SHOW_CARD_DOWNLOAD_REPORT]: handleSetShowModalCardDownloadReport,
  } as any,
  initialState
);

function handleSetShowModalDownloadPOS(
  state: ModalsState,
  action: Action<any>
) {
  return {
    ...state,
    show_download_pos: action.payload.show,
  };
}

function handleSetShowModalDownloadHBLPOS(
    state: ModalsState,
    action: Action<any>
) {
  return {
    ...state,
    show_download_hbl_pos: action.payload.show,
  };
}

function handleSetShowModalDownloadReport(
  state: ModalsState,
  action: Action<any>
) {
  return {
    ...state,
    show_download_report: action.payload.show,
  };
}

function handleSetShowModalCardDownloadReport(
  state: ModalsState,
  action: Action<any>
) {
  return {
    ...state,
    show_card_download_report: action.payload.show,
  };
}

export default ModalsReducer;
