import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { TextField } from "final-form-material-ui";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import * as yup from "yup";
import ButtonRounded from "../../../../components/ButtonRounded";
import makeValidate from "../../../../components/form/makeValidate";
import ModalInfo from "../../../../components/modal/ModalInfo";
import useModal from "../../../../components/modal/useModal";
import RequiredAsterisk from "../../../../components/texts/RequiredAsterisk";
import { getAppVariables } from "../../../../reducers/settings/app_variables";
import { IAppVariables } from "../../../../types/ApiInterfaces";
import { getHblBiotemplates } from "../../../../api/sustainability/storage/outgoing-transaction/getBiotemplates";
import getBiofuelsForReconcile from "../../../../api/sustainability/storage/reconciliation/getBiofuelsForReconcile";
import EditableTableReconcile from "./EditableTableReconcile";
import ModalSaveReconciliation from "./ModalSaveReconciliation";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      h2: {
        [theme.breakpoints.down("md")]: {
          fontSize: "32px",
        },
      },
      btn: {
        width: "170px",
        padding: "13px 0",
        lineHeight: "14px",
        "&:not(:first-child)": {
          marginLeft: "20px",
        },
        [theme.breakpoints.down("md")]: {
          width: "130px",
          padding: "12px 0 9px 0",
          "&:not(:first-child)": {
            marginLeft: "10px",
          },
        },
      },
      toggleContainer: {
        marginTop: theme.spacing(3),
      },
      topCard: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
        boxShadow: "-10px 10px 20px 0 rgba(30, 30, 30, 0.05)",
        padding: theme.spacing(2),
      },
      cardContent: {
        "&.MuiCardContent-root:last-child": {
          paddingBottom: theme.spacing(2),
        },
        "& .MuiOutlinedInput-input": {
          padding: theme.spacing(1, 1.5),
        },
      },
      cardTable: {
        padding: theme.spacing(3),
        paddingRight: theme.spacing(2),
      },
      fields: {
        position: "relative",
        "& .MuiFormHelperText-root.Mui-error": {
          position: "absolute",
          bottom: theme.spacing(-1.6),
          whiteSpace: "nowrap",
        },
        "& .MuiFormHelperText-contained": {
          margin: 0,
        },
      },
      creatableSelect: {
        ...theme.typography.body1,

        "& .react-select__control": {
          padding: 0,
          borderColor: theme.palette.grey[300],
          boxShadow: "none",
          minHeight: "35px",
          "&:hover": {
            borderColor: theme.palette.grey[300],
          },
        },
        "& .react-select__placeholder": {
          color: theme.palette.grey[300],
        },
        "& .react-select__value-container": {
          padding: "0px 8px",
        },
        "& .react-select__single-value": {
          color: theme.palette.primary.main,
        },
        "& .react-select__indicator-separator": {
          backgroundColor: "unset",
        },
        "& .react-select__indicators": {
          color: theme.palette.primary.main,
        },
        "& .react-select__option": {
          "&:active": {
            backgroundColor: "rgba(0, 0, 0, 0.14)",
          },
        },
        "& .react-select__option--is-focused": {
          backgroundColor: "rgba(0, 0, 0, 0.08)",
        },
        "& .react-select__option--is-selected": {
          backgroundColor: "rgba(0, 0, 0, 0.14)",
          color: theme.palette.text.primary,
        },
      },
    })
);

interface Props {
  app_variables: IAppVariables;
}

const BiofuelReconciliationVolumeView: React.FC<Props> = ({ app_variables }) => {
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [volume, setVolume] = useState<any>(null);
  const [headData, setHeadData] = useState<any>(null);
  const [tableData, setTableData] = useState<any>(null);
  const [editedTableData, setEditedTableData] = useState<any>(null);
  const [infoMessage, SetInfoMessage] = useState("");
  const [serialNumber, setSerialNumber] = useState<any>(null);

  const { openModalId, toggle } = useModal();
  const { terminal, biofuel, avail_volume, reconcile_date } = useParams();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const openModal = (id: string, text: string) => {
    if (id === "info") { SetInfoMessage(text); }
    toggle(id);
  };

  const closeModal = () => {
    SetInfoMessage("");
    toggle();
  };

  const openSaveModal = () => {
    triggerTableSubmit();

  };
  const closeSaveModal = () => {
    setEditedTableData(null);
    setShowSaveModal(false);
  };

  const triggerTableSubmit = () => {
    if (document) {
      const outgoingTableForm = document.getElementById(
          "storage_outgoing_table_form"
      );
      if (outgoingTableForm) {
        outgoingTableForm.dispatchEvent(
            new Event("submit", { cancelable: true })
        );
      }
    }
  };

  const validationSchema = yup.object({
    volume: yup
        .number()
        .max(99999999999999)
        .typeError("Please enter a number.")
        .required(),
    biofuel_type: yup.string().required(),
    storage_terminal: yup.string().required(),
    delivery_period: yup.string().min(3).required(),
    storage_date: yup.string().required(),
  });

  const handleChooseProducts = async (values: any) => {
    setHeadData({ ...values });
    setVolume(values.volume);
    const searchValues = {
      volume: values.volume,
      biofuel_type: values.biofuel_type,
      storage_terminal: values.storage_terminal,
      delivery_period: values.delivery_period,
      storage_date: moment(values.storage_date).format("YYYY-MM-DD"),
    };
    const res = await getHblBiotemplates(searchValues);
    if (res) {
      setTableData(res.biotemplates);
      setSerialNumber(res.serial_number);
    }
  };

  const handleTableData = (values: any) => {
    if (values && Array.isArray(values.biotemplates)) {
      const filteredValues = values.biotemplates.filter(
          (data: any) => data.allocated_quantity
      );

      if (filteredValues.length > 0) {
        let total = 0;
        for (let i = 0; i <
        filteredValues.length; i += 1) {
          total += parseFloat(filteredValues[i].allocated_quantity);
          filteredValues[i].serial_number = parseInt(serialNumber) + i;
        }
        if (total !== parseFloat(volume)) {
          openModal(
              "info",
              "Allocated quantity must be matched with given volume"
          );
          return false;
        }

        const bioValues = filteredValues.map((data: any) => ({
          id: data.id,
          serialNumber: data.serial_number,
          quantity: data.allocated_quantity,
          pos_date: moment(new Date()).format("YYYY-MM-DD"),
          shipment_sale_nomination: data.shipment_sale_nomination,
          b_l_date: data.b_l_date,
          vessel: data.vessel,
          storage_terminal: data.storage_terminal,
          biofuel_type: data.biofuel_type,
          raw_material: data.raw_material,
          raw_material_origin: data.raw_material_origin,
          certificate: data.certificate,
          site_start: data.site_start,
          eec: data.eec,
          el: data.el,
          ep: data.ep,
          etd: data.etd,
          eu: data.eu,
          esca: data.esca,
          eccs: data.eccs,
          eccr: data.eccr,
          eee: data.eee,
          etot: data.etot,
          reduction_in_climate_emission: data.reduction_in_climate_emission,
        }));

        if (bioValues.length > 0) {
          setEditedTableData(bioValues);
        }
        setShowSaveModal(true);
      } else {
        openModal(
            "info",
            "Please allocated quantity before submit"
        );
      }
    }
  };

  const fetchBiofuelsForReconcile = async (dataValues: any) => {
    const res = await getBiofuelsForReconcile(dataValues);
    if (res && res.valid) {
      setTableData(res.data);
    }
  };

  const decodeData = (data: any) => {
    return decodeURIComponent(data);
  };

  const onFormChange = (formState: any) => {
    const data: any = formState.values;
    const reconcileVolume =  parseFloat(data.volume) - parseFloat(data.physical_volume);
    data.reconcile_volume = reconcileVolume && reconcileVolume >= 0 ? reconcileVolume : 0;
    setVolume(data.reconcile_volume);
    setHeadData(data);
  };

  const haveTableData = tableData !== null;
  useEffect(() => {
    let isMounted = true;
    if (isMounted) { // Only update the state if the component is still mounted
      dispatch(getAppVariables());
    }
    return () => { // Add a cleanup function to cancel any ongoing tasks
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const dataValues = {
      storage_terminal: decodeData(terminal),
      biofuel_type: decodeData(biofuel)
    };
    fetchBiofuelsForReconcile(dataValues);
  }, [terminal, biofuel]);

  useEffect(() => {
    setVolume(null);
    // @ts-ignore
    setHeadData({
      biofuel_type: decodeData(biofuel),
      volume: decodeData(avail_volume),
      physical_volume: null,
      reconcile_volume: null,
      storage_terminal: decodeData(terminal),
      reconcile_date: decodeData(reconcile_date),
      status: "completed",
    });
  }, [terminal, biofuel, avail_volume, reconcile_date]);

  return (
      <div className={classes.root}>
        <Form
            onSubmit={handleChooseProducts}
            validate={values => makeValidate(values, validationSchema)}
            initialValues={
              headData
                  ? headData
                  : {
                    biofuel_type: decodeData(biofuel),
                    volume: decodeData(avail_volume),
                    physical_volume: null,
                    reconcile_volume: null,
                    storage_terminal: decodeData(terminal),
                    reconcile_date: decodeData(reconcile_date),
                    status: "completed",
                  }
            }
        >
          {({ handleSubmit, form }) => {
            // eslint-disable-next-line
            useEffect(() => {
              const data: any = form.getState().values;
              const unsubscribe = form.subscribe(onFormChange, { values: true });
              return unsubscribe;

            }, [form]);

            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography variant="h2" className={classes.h2}>
                      Reconciliation of {decodeData(biofuel)} in {decodeData(terminal)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ButtonRounded
                        variant="contained"
                        color="secondary"
                        className={classes.btn}
                        onClick={() => history.goBack()}
                    >
                      cancel
                    </ButtonRounded>
                    <ButtonRounded
                        variant="contained"
                        color="primary"
                        className={classes.btn}
                        onClick={openSaveModal}
                        disabled={!canSubmit}
                    >
                      save
                    </ButtonRounded>
                  </Grid>
                </Grid>

                <div className={classes.topCard}>
                  <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      className={classes.cardContent}
                  >
                    <Grid item xs>
                      <Typography variant="body2">
                        Biofuel Type
                      </Typography>
                      <Field
                          component={TextField}
                          name="biofuel_type"
                          variant="outlined"
                          fullWidth
                          className={classes.fields}
                          disabled={true}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="body2">
                        Available Volume
                      </Typography>
                      <Field
                          component={TextField}
                          name="volume"
                          variant="outlined"
                          placeholder="0,00"
                          fullWidth
                          className={classes.fields}
                          disabled={true}
                      />
                    </Grid>

                    <Grid item xs>
                      <Typography variant="body2">
                        Physical Volume <RequiredAsterisk />
                      </Typography>
                      <Field
                          component={TextField}
                          name="physical_volume"
                          variant="outlined"
                          placeholder="0,00"
                          fullWidth
                          className={classes.fields}
                      />
                    </Grid>

                    <Grid item xs>
                      <Typography variant="body2">
                        Reconciliation Volume <RequiredAsterisk />
                      </Typography>
                      <Field
                          component={TextField}
                          name="reconcile_volume"
                          variant="outlined"
                          placeholder="0,00"
                          fullWidth
                          className={classes.fields}
                          disabled={true}
                      />
                    </Grid>

                    <Grid item sm>
                      <Typography variant="body2">
                        Latest Reconciliation
                      </Typography>
                      <Field
                          component={TextField}
                          name="reconcile_date"
                          variant="outlined"
                          fullWidth
                          className={classes.fields}
                          disabled={true}
                      />
                    </Grid>
                  </Grid>
                </div>

                <Card>
                  <CardContent className={classes.cardTable}>
                    <EditableTableReconcile
                        data={tableData ? tableData : null}
                        volume={volume}
                        onSubmit={handleTableData}
                        canSubmit={canSubmit}
                        setCanSubmit={setCanSubmit}
                    />
                  </CardContent>
                </Card>
              </form>
          );}}
        </Form>
        {headData && (
            <ModalSaveReconciliation
                data={editedTableData}
                generalData={headData}
                open={showSaveModal}
                onClose={closeSaveModal}
            />
        )}
        <ModalInfo
            text={infoMessage}
            open={openModalId === "info"}
            onClose={closeModal}
        />
      </div>
  );
};

export default BiofuelReconciliationVolumeView;
