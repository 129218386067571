/**
 * Sustainability > Allocation: MainPage
 */

import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ButtonRounded from "../../../../components/ButtonRounded";
import ToggleButton from "../../../../components/ToggleButton";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import handlechangeSimulation from "../../../../api/card/handleSimulation/index";
import parseData from "../../../../components/texts/parseData";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";
import { number } from "yup";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    h2: {
      [theme.breakpoints.down("md")]: {
        fontSize: "32px",
      },
    },
    btn: {
      minWidth: "200px",
      "&:not(:first-child)": {
        marginLeft: "20px",
        [theme.breakpoints.down("md")]: {
          marginLeft: "10px",
        },
      },
    },
    toggleCardContainer: {
      marginTop: theme.spacing(3),
    },
    toggleCardContent: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    toggleCardTitle: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    cardCommon: {
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    tableTitle: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    outputTableTopLabel: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    table: {
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: "none",
        paddingTop: 0,
        paddingBottom: 0,
        height: theme.spacing(4),
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontSize: "11px",
        fontWeight: 700,
      },
    },
    iconBtn: {
      padding: 0,
      marginLeft: theme.spacing(2),
    },
    companyName: {
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    paginationContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(3),
    },
    addButton: {
      minWidth: "200px",
    },
    circularProgress: {
      margin: "80px",
      width: "48px",
      height: "48px",
    },
    soldVolumesTotal: {
      "& .MuiTableCell-root": {
        fontWeight: 700,
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        // paddingRight: theme.spacing(3.8),
      },
    },
  })
);

interface Props {
  isSimulation: boolean;
  isJoint: boolean;
  isFirstSplit: boolean;
  hasStarted: boolean;
  splitYear: any[];
  activePeriod: any;
  soldVolumes: any;
  constructionDieselVolumes: any;
  toggleSimulation: any;
  toggleJoint: any;
  toggleSplitYear: any;
  startAllocation: any;
  getSoldVolumes: any;
  createSoldVolumes: any;
  deleteSoldVolumes: any;
  getConstructionDieselVolumes: any;
  createConstructionDieselVolumes: any;
  deleteConstructionDieselVolumes: any;
  cardVolumePageData: any;
  getcardVolumes: any;
  cardVolumePageSum: any;
}

const MainView: React.FC<Props> = ({
  isSimulation,
  isJoint,
  isFirstSplit,
  hasStarted,
  splitYear,
  activePeriod,
  soldVolumes,
  constructionDieselVolumes,
  toggleSimulation,
  toggleJoint,
  toggleSplitYear,
  startAllocation,
  getSoldVolumes,
  createSoldVolumes,
  deleteSoldVolumes,
  getConstructionDieselVolumes,
  createConstructionDieselVolumes,
  deleteConstructionDieselVolumes,
  cardVolumePageData,
  getcardVolumes,
  cardVolumePageSum,
}) => {
  const [pageData, SetPageData] = useState<any>({});
  const [sum, SetSum] = useState<any>(0);
  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();

  const handleStartAllocation = () => {
    if (match) {
      startAllocation();
      history.push(`${match.url}/start`);
    }
  };

  const handleContinueAllocation = () => {
    if (match) {
      history.push(`${match.url}/start`);
    }
  };

  const fetchPageData = async () => {
    const ex = await getcardVolumes();
    // getCardVolumesTotal();
  };
  const isLoading =
    !(typeof cardVolumePageData === "object" && Object.keys(cardVolumePageData).length > 0) ||
    soldVolumes.isFetching;

  useEffect(() => {
    fetchPageData();
    handleSimulationchangeAPIcall();
  }, [isSimulation]);

  const handleSimulationchangeAPIcall = async () => {
    const params = {
      allocation: isSimulation ? "Simulation" : "Production",
      taxYearId: activePeriod.id,
    };
    const data = await handlechangeSimulation(params);
  };
  // const getCardVolumesTotal = () => {
  //   let Sum = 0;
  //   if (typeof cardVolumePageData.volume_fame === "number"){
  //      Sum =  cardVolumePageData.volume_fame +
  //   cardVolumePageData.volume_hvo_hro +
  //   cardVolumePageData.volume_nafta +
  //   cardVolumePageData.volume_etanol+
  //   cardVolumePageData.volume_fossil;
  //   } else if (typeof cardVolumePageData.volume_fame === "string"){
  //    Sum =  parseFloat(cardVolumePageData.volume_fame) +
  //   parseFloat(cardVolumePageData.volume_hvo_hro) +
  //   parseFloat(cardVolumePageData.volume_nafta) +
  //   parseFloat(cardVolumePageData.volume_etanol)+
  //   parseFloat(cardVolumePageData.volume_fossil);
  //   } else{
  //     console.log("The input field is not a number or a string, something is wrong");
  //   }
  //   console.log("type is: " + typeof(cardVolumePageData.volume_fame)+" and value is: "+cardVolumePageData.volume_fame);
  //   console.log("it it called 2 "+Sum);
  //   console.log(parseFloat(0));
  //   SetSum(Sum);

  // };

  const addThousandOperators = (Text: any) => {
    const value = Math.round(parseFloat(Text));
    // console.log(value.toLocaleString())
    return value.toLocaleString();
  };

  const handletoggleSimulation = () => {
    toggleSimulation();
    fetchPageData();
  };

  return (
    <div className={classes.root}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h2" className={classes.h2}>
            <FormattedMessage id="cardView.allocationView.mainView.title" />
          </Typography>
        </Grid>
        <Grid item>
          <ButtonRounded
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={handleContinueAllocation}
            disabled={!hasStarted}
          >
            <FormattedMessage id="allocationView.mainView.continue" />
          </ButtonRounded>

          <ButtonRounded
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={handleStartAllocation}
            disabled={!hasStarted}
          >
            <FormattedMessage id="allocationView.mainView.restart" />
          </ButtonRounded>

          <ButtonRounded
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={handleStartAllocation}
            disabled={hasStarted}
          >
            <FormattedMessage id="allocationView.mainView.start" />
          </ButtonRounded>
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.toggleCardContainer}>
        <Grid item xs={12}>
          <Card>
            <Grid item xs={4}>
              <CardContent className={classes.toggleCardContent}>
                <Typography variant="h5" className={classes.toggleCardTitle}>
                  <FormattedMessage id="cardView.allocationView.mainView.allocationMode" />
                </Typography>
                <ToggleButton
                  labels={["Production", "Simulation"]}
                  defaultLabelIndex={isSimulation ? 1 : 0}
                  handleChange={handletoggleSimulation}
                />
              </CardContent>
            </Grid>
          </Card>
        </Grid>

        {splitYear && (
          <Grid item xs={6}>
            <Card>
              <CardContent className={classes.toggleCardContent}>
                <Typography variant="h5" className={classes.toggleCardTitle}>
                  <FormattedMessage id="allocationView.mainView.allocationPeriod" />
                </Typography>
                <ToggleButton
                  labels={[splitYear[0].name, splitYear[1].name]}
                  defaultLabelIndex={isFirstSplit ? 0 : 1}
                  handleChange={toggleSplitYear}
                />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      {isLoading ? (
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress className={classes.circularProgress} />
        </Box>
      ) : (
        <div>
          <Card className={classes.cardCommon}>
            <CardContent>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                className={classes.tableTitle}
              >
                <Grid item>
                  <Typography variant="h5">
                    <FormattedMessage id="cardView.allocationView.mainView.physicalComponentSold" />
                  </Typography>
                </Grid>{" "}
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow>
                        <TableCell>{"FAME"}</TableCell>
                        <TableCell align="right">{addThousandOperators(cardVolumePageData.volume_fame)} liter</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{"HVO/HRO"}</TableCell>
                        <TableCell align="right">{addThousandOperators(cardVolumePageData.volume_hvo_hro)} liter</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{"Etanol"}</TableCell>
                        <TableCell align="right">{addThousandOperators(cardVolumePageData.volume_etanol)} liter</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{"Nafta"}</TableCell>
                        <TableCell align="right">{addThousandOperators(cardVolumePageData.volume_nafta)} liter</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{"Fossil"}</TableCell>
                        <TableCell align="right">{addThousandOperators(cardVolumePageData.volume_fossil)} liter</TableCell>
                      </TableRow>

                      <TableRow className={classes.soldVolumesTotal}>
                        <TableCell>{"Grand Total"}</TableCell>
                        <TableCell align="right">
                         {addThousandOperators(cardVolumePageSum)} liter
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      )}
    </div>
  );
};

export default MainView;
