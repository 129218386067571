import { Action, handleActions } from "redux-actions";
import { ErrorPayload } from "../../common/payloads/ErrorActionPayload";
import { IAppVariables } from "../../types/ApiInterfaces";
import fetchAppVariables from "../../api/settings/appVariables/get";

// State Interface
export interface AppVariablesState {
  isFetching: boolean;
  data: IAppVariables;
  error?: any;
}

// Initial state
const initialState = {
  isFetching: false,
  data: {} as IAppVariables,
  error: undefined,
} as AppVariablesState;

// Actions
const APP_VARIABLES_REQUEST = "app_variables/REQUEST";
const APP_VARIABLES_ERROR = "app_variables/ERROR";
const APP_VARIABLES_SUCCESS = "app_variables/SUCCESS";
const APP_VARIABLES_REMOVE = "app_variables/REMOVE";
const APP_VARIABLES_EDIT = "app_variables/EDIT";

// Action creators
function requestAppVariables() {
  return {
    type: APP_VARIABLES_REQUEST,
  };
}

function errorAppVariables(error: any) {
  return {
    type: APP_VARIABLES_ERROR,
    payload: { error },
  };
}

function receiveAppVariables(data: any) {
  let appVariables = {};
  const addAppVariable = (id: string, name: string) =>
    (appVariables = {
      ...appVariables,
      [id]: { id, name, values: data[id] ? data[id] : [] },
    });

  addAppVariable("tax_years", "Tax Years");
  addAppVariable("advanced_type", "Advanced");
  addAppVariable("storage_terminals", "Storage Terminals");
  addAppVariable("sales_products", "Sales Product");
  addAppVariable("fuel_types", "Fuel Type");
  addAppVariable("sold_components_fuel_types", "Sold Components Fuel Type");
  addAppVariable("usage_area", "Usage Area");
  addAppVariable("double_counting", "Advanced");
  addAppVariable("owners", "Owner");
  addAppVariable("transaction_customers", "Transaction Customers");
  addAppVariable("serial_numbers", "Serial Number");
  addAppVariable("tax_type", "Tax Type");
  addAppVariable("tax_group", "Tax Group");
  addAppVariable("biocomponent_type", "Biocomponent Type");
  addAppVariable("red_version", "Red Version");
  addAppVariable("biofuel_type", "Biofuel Type");
  addAppVariable("flag_variable", "Flag Variable");
  addAppVariable("biofuel_type_usage", "Biofuel Type Usage");
  addAppVariable("raw_material", "Raw Material");
  addAppVariable("origin", "Origin");
  addAppVariable("documentation_mass_balance", "Documentation Mass Balance");
  addAppVariable("documentation_area_criteria", "Documentation Area Criteria");
  addAppVariable("documentation_ghg_reduction", "Documentation Ghg Reduction");
  addAppVariable("disable_biofuel_tye", "Disable Biofuel Type");
  addAppVariable("transaction_type", "Transaction Type");
  addAppVariable("res_customer", "Res Customer");
  addAppVariable("res_customer_hbl", "HBL Res Customer");
  addAppVariable("customer", "Customer");
  addAppVariable("quarters", "Quarters");
  addAppVariable("months", "Months");
  addAppVariable("bio_sales_products", "Bio Sales Products");
  addAppVariable("ghg_rates", "Ghg Rates");
  addAppVariable("dischange_ports", "Discharge Ports");
  addAppVariable("raw_material_types", "Raw Material Type");
  addAppVariable("active_status", "Active Status");

  return {
    type: APP_VARIABLES_SUCCESS,
    payload: appVariables,
  };
}

function removeAppVariables(id: string) {
  return {
    type: APP_VARIABLES_REMOVE,
    payload: id,
  };
}

function editedAppVariables(data: object) {
  return {
    type: APP_VARIABLES_EDIT,
    payload: data,
  };
}

export function getAppVariables() {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.settings.app_variables) {
      dispatch(requestAppVariables());
      try {
        const response = await fetchAppVariables();
        if (response.valid) { dispatch(receiveAppVariables(response.data)); }
      } catch (error) {
        dispatch(errorAppVariables(error));
      }
    }
    return Promise.resolve;
  };
}

export function editAppVariables(appVariable: object) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.settings.app_variables.data) {
      const response = { success: false, error: null };
      try {
        dispatch(editedAppVariables(appVariable));
        response.success = true;
      } catch (error) {
        dispatch(errorAppVariables(error));
        response.error = error;
      }
      return response;
    }
  };
}

// Reducer Definition
export default handleActions<AppVariablesState>(
  {
    [APP_VARIABLES_REQUEST]: handleRequest,
    [APP_VARIABLES_ERROR]: handleError,
    [APP_VARIABLES_SUCCESS]: handleSuccess,
    [APP_VARIABLES_REMOVE]: handleRemove,
    [APP_VARIABLES_EDIT]: handleEdit,
  } as any,
  initialState
);

// Reducer Description
function handleRequest(state: AppVariablesState) {
  return {
    ...state,
    isFetching: true,
    error: undefined as any,
  };
}

function handleError(state: AppVariablesState, action: Action<ErrorPayload>) {
  return {
    ...state,
    isFetching: false,
    error: action.payload.error,
  };
}

function handleSuccess(
  state: AppVariablesState,
  action: Action<IAppVariables>
) {
  return {
    ...state,
    isFetching: false,
    data: action.payload,
    error: undefined as any,
  };
}

function handleRemove(state: AppVariablesState, action: Action<string>) {
  const { [action.payload]: value, ...newData } = state.data;
  return {
    ...state,
    isFetching: false,
    data: { ...newData },
    error: undefined as any,
  };
}

function handleEdit(state: AppVariablesState, action: Action<IAppVariables>) {
  return {
    ...state,
    isFetching: false,
    data: { ...state.data, [action.payload.id]: action.payload },
    error: undefined as any,
  };
}
