/**
 * create/update a manual report
 */

import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

const postReport = async (values: any) => {
  const token = getToken();
  let valid = false;
  let result;
  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/manual_tax_reports/update_or_create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(values),
        }
      );

      result = await response.json();
      valid = result && result.success && result.manual_tax_reports;
    } catch (except) {
      valid = false;
    }
  }
  if (!valid) {
    if (result && result.error) {
      return {
        success: false,
        message: result.error,
      };
    }
    return {
      success: false,
      message: "Something unexpected happened",
    };
  }

  return {
    success: true,
    data: result.manual_tax_reports,
  };
};

export default postReport;
