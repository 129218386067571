import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

export default async (params: any) => {
  const token = getToken();

  let valid = false;
  let data = {};
  let createResult;

  if (token) {
    try {
      let response = await fetch(
        `${BASE_URL}/api/construction_diesel_volumes`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(params),
        }
      );

      createResult = await response.json();
      valid = createResult && createResult.success;
      let constVol = createResult;

      data = constVol;
      if (constVol.fossil && constVol.hvo) {
        data = {
          ...constVol,
          fossil: parseFloat(constVol.fossil),
          hvo: parseFloat(constVol.hvo),
        };
      }
    } catch (except) {
      valid = false; // network error
    }
  }

  if (!valid) {
    if (createResult && createResult.errors) {
      return {
        valid: false,
        message: createResult.errors,
      };
    }
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    data,
  };
};
