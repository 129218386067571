import { getToken, getTokenData } from "./utils";
import { BASE_URL } from "./endpoints";

const changePassword = async (
  current_password: string,
  new_password: string
) => {
  const token = getToken();
  const tokenData = getTokenData();

  let valid = false;
  let changePasswordResult;

  if (token && tokenData) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/users/${tokenData.user_id}/change_password`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            current_password,
            password: new_password,
          }),
        }
      );

      changePasswordResult = await response.json();
      valid = changePasswordResult && changePasswordResult.success;
    } catch (except) {
      valid = false; // network error
    }

    if (!valid) {
      if (changePasswordResult && changePasswordResult.error) {
        return {
          valid: false,
          message: changePasswordResult.error,
        };
      }
      return {
        valid: false,
        message: "Something unexpected happened",
      };
    }

    return {
      valid: true,
    };
  }
};

export default changePassword;
