import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select, { SelectProps } from "@material-ui/core/Select";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import clsx from "clsx";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { State } from "../reducers/reducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      color: theme.palette.primary.main,
    },
    greyText: {
      color: theme.palette.action.disabled,
    },
    box: {
      padding: "0px",
      display: "flex",
      flexWrap: "wrap",
      gap: 0.5,
    },
    menuItem: {
      padding: "0px 2px",
    },
  })
);

interface Props {
  name: string;
  value: any;
  type: string;
  onChange?: (e: any) => void;
  fullWidth?: boolean;
  displayEmpty?: boolean;
  className?: any;
  filterValue?: any;
}

interface StateProps {
  data: any;
  dispatch?: any; // fix warning
  emptyMenuItem?: React.ReactNode;
}

const mapStateToProps = (state: State, ownProps: Props) => ({
  data: state.settings.app_variables.data[ownProps.type],
});

const SmartMultiSelect = ({
  data,
  name,
  value,
  onChange,
  className,
  dispatch,
  emptyMenuItem,
  filterValue,
  type,
  ...rest
}: SelectProps & StateProps & Props) => {
  const classes = useStyles();

  // if props filterValue array is not empty, filter strings
  let filteredData = data.values;
  if (filterValue && Array.isArray(filterValue) && filterValue.length > 0) {
    filteredData = data.values.filter(
      (value: any) => !filterValue.includes(value)
    );
  }
  let selectedValues = Array.isArray(value) ? value : value.split(",");
  if (selectedValues.includes("")) {
    selectedValues = selectedValues.filter((e: any) => e);
  }

  return (
    <Select
      multiple
      {...rest}
      displayEmpty
      name={name}
      value={selectedValues}
      onChange={onChange}
      input={<OutlinedInput labelWidth={0} />}
      className={clsx(classes.select, className, {
        [classes.greyText]: !selectedValues.length,
      })}
      renderValue={
        selectedValues.length != 0 ?
          (selectedValues: any) => (
            <Box className={classes.box}>
              {selectedValues.join(", ")}
            </Box>
          )
          : () => <FormattedMessage id="dropdown.placeholder.select" />
      }
    >
      <MenuItem value="" disabled>
        {emptyMenuItem || (
          <FormattedMessage id="dropdown.placeholder.select" />
        )}
      </MenuItem>
      {
        filteredData.map((value: any, index: number) => (
          <MenuItem key={index} value={value} style={{ justifyContent: "space-between" }}>
            {value}
            {selectedValues.includes(value) ? <CheckIcon color="primary" /> : null}
          </MenuItem>
        ))
      }
    </Select >
  );
};

export default connect(mapStateToProps)(SmartMultiSelect);
